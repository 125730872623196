import { useEffect, useState } from 'react';
import { Modal, Card, Table, ProgressBar, Overlay } from 'react-bootstrap';
import { patchProject } from '../../../core/_requests';
import { showError } from '../../../../../utils/funcs';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useProject } from '../../../core/ProjectContext';
import CustomSelect from '../../../../../components/CustomSelect';
import { DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../store';
import Password from 'antd/es/input/Password';
import "./Styles.css";
import TreeView, { flattenTree } from 'react-accessible-treeview';
import { FaSquare, FaCheckSquare, FaMinusSquare } from 'react-icons/fa';
import { IoMdArrowDropright } from 'react-icons/io';
import cx from 'classnames';
import { transformToFolder } from '../../comments/request';
import { DataNode } from 'antd/es/tree';
import { getWbs } from '../../../../wbs/_requests';
import { addWbsToStore } from '../../../../wbs/treedata';
import { fetchDataById } from '../../../../wbs/treeVersionData';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { mockWidgetOrder } from '../../settings/WidgetOrder/_models';
import { widgetOrderNames } from '../../settings/ShareWidgetOrder/_models';

type Props = {
  onHide: () => void;
  sendEnableToast: any;
};

export default function WbsShareModal({
  onHide,
  sendEnableToast,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [toggleShare, setToggleShare] = useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState(dayjs().add(1, 'month'));
  const [copyButtonText, setCopyButtonText] = useState<string>('Copy Link');
  const [togglePassword, setTogglePassword] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const { project, setProject } = useProject();
  const [checkedWbs, setCheckedWbs] = useState<string[]>(project?.checked_wbs ? project?.checked_wbs.map(wbs => wbs.$oid) : []);
  const [selectedWbs, setSelectedWbs] = useState<string>(project?.share_wbs ? project?.share_wbs.$oid : project?.default_wbs._id.$oid);
  const [isCancel, setIsCancel] = useState(false);
  const dispatch = useDispatch();
  const [overlayVisible, setOverlayVisible] = useState<boolean>(true);
  const [processedNodes, setProcessedNodes] = useState(0);
  const [totalNodes, setTotalNodes] = useState(0);
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const [treedata, setTreedata] = useState<any>()
  const [progress, setProgress] = useState(0);
  const [showWbsModal, setShowWbsModal] = useState(false)
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const order = project?.share_widget_order && project.share_widget_order.length
    ? project.share_widget_order
    : mockWidgetOrder // Determine the order of widgets

  const dispatchh: AppDispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };


  function manageCancel() {
    onHide();
    setIsCancel(true);
  }

  const copyShareLink = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const shareLink = window.location.origin + '/projectshare/' + project?._id?.$oid;
    navigator.clipboard.writeText(shareLink).then(() => {
      setCopyButtonText('Copied!');
      setTimeout(() => {
        setCopyButtonText('Copy Link');
      }, 1500);
    });
  };

  useEffect(() => {

    if (project && project.shareable !== undefined) {
      setToggleShare(project.shareable);
    }

    if (project && project.expiration_date) {
      setExpirationDate(dayjs(project.expiration_date.$date));

    }

  }, [project]);

  useEffect(() => {
    const loadAllWbs = async (wbsId: string): Promise<DataNode> => {
      let knownWbsItem = knownWbs[wbsId];
      if (!knownWbsItem) {
        const response = await getWbs(wbsId);
        dispatch(addWbsToStore(response));
        knownWbsItem = response;
      }

      const childrenData: DataNode[] = await Promise.all(
        knownWbsItem.subwbs.map(async (subWbs) => {
          return await loadAllWbs(subWbs.$oid);
        })
      );

      return {
        key: knownWbsItem._id.$oid,
        children: childrenData,
      };
    };

    const initializeTree = async () => {

      const rootNode = await loadAllWbs(selectedWbs);
      setTreeData([rootNode]);
    };
    setCheckedWbs([]);
    setOverlayVisible(true);
    setProcessedNodes(0)
    setProgress(0)
    setTotalNodes(0)
    initializeTree();



  }, [selectedWbs]);




  useEffect(() => {
    const traverseTree = async (node: DataNode) => {
      const vers = knownWbs[node?.key];
      setTotalNodes(prev => prev + 1)
      if (vers.subwbs.length === 0 && vers.data) {
        await dispatchh(fetchDataById(vers.data.$oid));
      }
      setProcessedNodes(prev => prev + 1);
      if (node.children && node.children.length > 0) {
        await Promise.all(node.children.map((childNode: DataNode) => traverseTree(childNode)));
      }
    };


    if (treeData && treeData.length > 0) {
      traverseTree(treeData[0]);
    }
  }, [treeData]);



  useEffect(() => {
    if (totalNodes > 0) {
      setProgress((processedNodes / totalNodes) * 100);

      // Hide overlay when progress reaches 100%
      if ((processedNodes / totalNodes) * 100 >= 100) {
        setOverlayVisible(false);
        setShowWbsModal(true);
      }
    }
  }, [processedNodes, totalNodes]);





  //load tree data after loading the data of wbs
  //if overlay = true -> data is still loading (progress bar) else loading data is completed
  useEffect(() => {
    if (!overlayVisible) {
      const folder = transformToFolder(knownWbs[selectedWbs], knownWbs);
      setTreedata(flattenTree(folder))
      if (selectedWbs === project?.share_wbs?.$oid) {
        if (project && project?.checked_wbs) {
          setCheckedWbs(project.checked_wbs.map(wbs => wbs.$oid));
        }
      } else {
        setCheckedWbs([]);
      }
    }
  }, [overlayVisible, selectedWbs]);



  const formik = useFormik({
    initialValues: {
      widgetOrder: order,
      date: expirationDate,
      password: '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      let selected_wbs = knownWbs[selectedWbs];
      if (!selected_wbs) {
        selected_wbs = await getWbs(selectedWbs);
        dispatch(addWbsToStore(selected_wbs));
      }
      const version_id = selected_wbs?.data?.$oid
      patchProject(
        {
          share_protection: togglePassword,
          share_password: togglePassword ? values.password : '',
          shareable: toggleShare,
          share_widget_order: values.widgetOrder,
          expiration_date: values.date,
          share_version: version_id,
          share_wbs: selectedWbs,
          checked_wbs: checkedWbs,
        },
        project?._id.$oid || ''
      )
        .then((res) => {
          if (toggleShare && !isCancel) {
            sendEnableToast(toggleShare);
            const shareLink = window.location.origin + '/projectshare/' + project?._id?.$oid;
            navigator.clipboard.writeText(shareLink);
          }
          setProject((prev) =>
            prev ? {
              ...prev,
              share_wbs: res?.share_wbs,
              shareable: toggleShare,
              expiration_date: res?.expiration_date,
              share_widget_order: res?.share_widget_order,
              share_protection: res?.share_protection,
              checked_wbs: res?.checked_wbs,
            } : prev
          );
          onHide();
        })
        .catch(showError)
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const handleNodeSelect = (node: any) => {
    const selectedIds = node.treeState?.selectedIds || [];
    let idsArray: string[] = Array.from(selectedIds);
  
    // Function to find parent nodes recursively
    const findParentNodes = (currentNode: string): string[] => {
      const parents: string[] = [];
      let parentNode = knownWbs[currentNode]?.parent_wbs?.$oid; // Assuming each node has a parentId
  
      while (parentNode) {
        parents.push(parentNode);
        parentNode = knownWbs[parentNode]?.parent_wbs?.$oid;
      }
      return parents;
    };
  
    // Function to find child nodes recursively
    const findChildNodes = (currentNode: string): string[] => {
      const children: string[] = [];
      const nodeValue = knownWbs[currentNode];
  
      // Ensure nodeValue exists and subwbs is an array before attempting to iterate
      if (nodeValue?.subwbs && Array.isArray(nodeValue.subwbs)) {
        nodeValue.subwbs.forEach((child: any) => {
          const childId = child.$oid;
          children.push(childId);
          children.push(...findChildNodes(childId)); // Recurse for each child
        });
      }
  
      return children;
    };
  
    // Loop through the selected nodes and get their parents
    idsArray.forEach((nodeId) => {
      const parentNodes = findParentNodes(nodeId);
      idsArray = [...new Set([...idsArray, ...parentNodes])]; // Add parents and remove duplicates
    });
  
    // Check for deselected nodes manually if `deselectedIds` is not available
    const previousCheckedWbs = checkedWbs || [];
    const deselectedIds = previousCheckedWbs.filter((id) => !idsArray.includes(id));
  
    // Handle deselection: remove deselected node and all its children
    deselectedIds.forEach((deselectedId: string) => {
      const childNodes = findChildNodes(deselectedId);
      idsArray = idsArray.filter((id) => !childNodes.includes(id) && id !== deselectedId); // Remove deselected node and its children
    });
  
    // Update the checked nodes state
    setCheckedWbs(idsArray);
  };
  

  return (
    <>     {overlayVisible && (
      <div
        className="progress-overlay"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(128, 128, 128, 0.7)',
          zIndex: 1050,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pointerEvents: 'none',
        }}
      >
        <ProgressBar now={progress} style={{ width: '50%' }} />
      </div>
    )}
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Card className='mt-2'>
            <Card.Header>
              <div></div>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='form-check form-switch'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='shareToggle'
                    checked={toggleShare}
                    onChange={() => setToggleShare(!toggleShare)}
                  />
                  <label className='form-check-label' htmlFor='shareToggle'>
                    Share
                  </label>
                </div>
                <button
                  className='ms-3 btn btn-primary btn-sm border p-1'
                  onClick={copyShareLink}

                  style={{
                    opacity: copyButtonText === 'Copy Link' ? '' : '75%',
                    width: '80px',
                  }}
                >
                  {copyButtonText}
                </button>
              </div>
            </Card.Header>
            <Card.Body>
              <div className='mt-3'>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span>Select Version</span>
                  </label>
                  {project?.wbs_list && project.wbs_list.length > 0 && (
                    <div>
                      <CustomSelect
                        name='perioda'
                        options={project.wbs_list.map((item: any, index: number) => ({
                          label: 'Version ' + index,
                          value: item.$oid,
                        }))}
                        value={project.wbs_list.map((item: any, index: number) => ({
                          label: 'Version ' + index,
                          value: item.$oid,
                        })).find((item: any) => selectedWbs === item.value)}
                        onChange={(e: any) => {
                          setOverlayVisible(true);
                          setCheckedWbs([])
                          setSelectedWbs(e.value);
                        }}
                        padding={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
          {(treedata && !overlayVisible) && (
            <TreeView
              data={treedata}
              aria-label='Single select'
              multiSelect={true}
              propagateSelectUpwards={false}
              propagateSelect={false}
              propagateCollapse={false}
              togglableSelect={true}
              selectedIds={Array.from(new Set(checkedWbs))}
              defaultDisabledIds={[selectedWbs]}
              nodeAction='check'
              onSelect={(node) => handleNodeSelect(node)}
              nodeRenderer={({
                element,
                isBranch,
                isExpanded,
                isSelected,
                isHalfSelected,
                isDisabled,
                getNodeProps,
                level,
                handleSelect,
                handleExpand,
              }) => (
                <div
                  {...getNodeProps({ onClick: handleExpand })}
                  style={{
                    marginLeft: 30 * (level - 1),
                    opacity: isDisabled ? 0.3 : 1,
                  }}
                >
                  {isBranch && <ArrowIcon isOpen={isExpanded} />}
                  <CheckBoxIcon
                    onClick={(e: any) => {
                      handleSelect(e);
                      e.stopPropagation();
                    }}
                    variant={isHalfSelected ? 'some' : isSelected ? 'all' : 'none'}
                  />
                  {isBranch ?
                    <KTSVG className='svg-icon-primary svg-icon-2 me-2'
                      path='/media/icons/duotune/graphs/git04.svg' /> :
                    <KTSVG
                      path='/media/icons/duotune/files/fil003.svg'
                      className='svg-icon-primary svg-icon-2 me-2'
                    />}
                  <span className='name h5'>{element.name}</span>
                </div>
              )}
            />
          )}


          <Card className='mt-2'>
            <Card.Body>
              <div className='mt-3'>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Expiration Date</span>
                  </label>
                  <DatePicker
                    className={clsx(
                      'form-control',
                      { 'is-invalid': formik.touched.date && formik.errors.date },
                      { 'is-valid': formik.touched.date && !formik.errors.date }
                    )}
                    {...formik.getFieldProps('date')}
                    suffixIcon={null}
                    format='YYYY-MM-DD'
                    value={formik.values.date ? dayjs(formik.values.date) : undefined}
                    onChange={(date, dateString) => {
                      formik.setFieldValue('date', date?.toDate())
                    }}
                    allowClear={false}
                  />
                  {formik.touched.date && formik.errors.date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{String(formik.errors.date)}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>

          <Card className='mt-2'>
            <Card.Body>
              <div className='mt-3'>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2 justify-content-between'>
                    <span className=''>Activate Protection </span>
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='shareToggle'
                        checked={togglePassword}
                        onChange={() => setTogglePassword(!togglePassword)}
                      />
                      <label className='form-check-label' htmlFor='shareToggle'></label>
                    </div>
                  </label>
                  {togglePassword ? (
                    <div className="input-group">
                      <input
                        pattern='.{5,}'
                        required
                        title='5 characters required'
                        name="password"
                        type={passwordVisible ? 'text' : 'password'}
                        className='form-control'
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={togglePasswordVisibility}
                        >
                          <i className={passwordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <input
                      name="password"
                      type="password"
                      className='form-control'
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>

          <Card className='mt-2'>
            <Card.Header>
              <Card.Title>Select Overview</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className='d-flex flex-column gap-2'>
                {formik.values.widgetOrder.map((item) => {
                  const handleMoveUp = () => {
                    const index = formik.values.widgetOrder.findIndex((i) => i.widget === item.widget)
                    if (index === 0) return
                    let temp = formik.values.widgetOrder[index - 1]
                    const tempArray = formik.values.widgetOrder
                    tempArray[index - 1] = item
                    tempArray[index] = temp
                    formik.setFieldValue('widgetOrder', tempArray)
                  }

                  const handleMoveDown = () => {
                    const index = formik.values.widgetOrder.findIndex((i) => i.widget === item.widget)
                    if (index === formik.values.widgetOrder.length - 1) return
                    let temp = formik.values.widgetOrder[index + 1]
                    const tempArray = formik.values.widgetOrder
                    tempArray[index + 1] = item
                    tempArray[index] = temp
                    formik.setFieldValue('widgetOrder', tempArray)
                  }

                  const handleToggleShow = () => {
                    const newArray = formik.values.widgetOrder.map((i) =>
                      i.widget === item.widget ? { ...i, show: !i.show } : i
                    )
                    formik.setFieldValue('widgetOrder', newArray)
                  }
                  return (
                    <div key={item.widget} className='d-flex align-items-center py-1 px-2 rounded bg-hover-light-primary'>
                      {!!widgetOrderNames[item.widget]?.icon && (
                        <KTSVG path={widgetOrderNames[item.widget].icon} className='me-3 svg-icon-1' />
                      )}
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={item.show}
                        id='flexCheckDefault'
                        onChange={handleToggleShow}
                      />
                      <button
                        disabled={formik.values.widgetOrder[0].widget === item.widget}
                        id='up_folder'
                        type='button'
                        style={{ transition: 'all 0.2s ease-in-out' }}
                        className='btn btn-icon btn-sm'
                        onClick={handleMoveUp}
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr003.svg'
                          className='svg-icon-muted text-hover-primary svg-icon-3'
                        />
                      </button>
                      <button
                        disabled={formik.values.widgetOrder.at(-1)?.widget === item.widget}
                        id='down_folder'
                        type='button'
                        style={{ transition: 'all 0.2s ease-in-out' }}
                        className='btn btn-icon btn-sm'
                        onClick={handleMoveDown}
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr004.svg'
                          className='svg-icon-muted text-hover-primary svg-icon-3'
                        />
                      </button>
                      <span className='fs-5 fw-bold'>{widgetOrderNames[item.widget]?.name}</span>
                    </div>
                  )
                }
                )}
              </div>

            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <button disabled={loading} onClick={manageCancel} className='btn btn-light-primary'>
            Cancel
          </button>
          <button disabled={loading} type='submit' className='btn btn-primary'>
            {loading ? (
              <span className='indicator-progress' style={{ display: 'block' }}>
                <span className='spinner-border spinner-border-sm align-middle'></span>
              </span>
            ) : (
              'Submit'
            )}
          </button>
        </Modal.Footer>
      </form>
    </>

  );
}


const ArrowIcon = ({ isOpen, className }: { isOpen: boolean; className?: string }) => {
  const baseClass = 'arrow';
  const classes = cx(baseClass, { [`${baseClass}--closed`]: !isOpen }, { [`${baseClass}--open`]: isOpen }, className);
  return <IoMdArrowDropright className={classes} />;
};

interface CheckBoxIconProps {
  variant: 'all' | 'none' | 'some';
  [key: string]: any;
}

const CheckBoxIcon: React.FC<CheckBoxIconProps> = ({ variant, isDisabled, ...rest }) => {
  switch (variant) {
    case 'all':
      return <FaCheckSquare {...rest} />;
    case 'none':
      return <FaSquare {...rest} />;
    case 'some':
      return <FaMinusSquare {...rest} />;
    default:
      return null;
  }
};
