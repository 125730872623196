import {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {formatNumber} from '../../../../utils/formatter'
import {getPeriodicFromCumulative} from '../../../projects/components/data/prepareDataTable'
import {CustomCurve} from '../../core/_models'
import {ChartUnit} from '../../simulationSinglePage'
import {ChartCustomCurve} from './_models'

export const RECORD_TO_IGNORE = 'IGNORE_THIS_RECORD_6DBE8ADAD7F9'

export function getChartOptions(
  height: number,
  chartUnit: ChartUnit,
  data: any[],
  data2?: any[],
  labels?: any[],
  custom_curve?: any,
  highlightIdx?: number
): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-info')
  return {
    series: [
      {
        type: 'column',
        name: 'Percentage of periodic progress',
        data: data2 || [],
      },
      {
        type: 'line',
        name: 'Percentage of cumulative progress',
        data: data || [],
      },
      ...custom_curve
        ?.filter((curve: ChartCustomCurve) => curve.name !== RECORD_TO_IGNORE && curve.show)
        ?.map((curve: CustomCurve) => ({
          type: 'line',
          name: curve.name,
          data: curve.values.slice(0, data2?.length || curve.values.length),
          color: curve.color,
        })),
    ],
    chart: {
      animations: {
        enabled: false,
      },
      fontFamily: 'inherit',
      height: 400,
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,

          customIcons: [],
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      y: {
        formatter: (val: any) => {
          if (!val) return '- ' + chartUnit.suffix
          return formatNumber(val * chartUnit.ratio, 1, '.') + ' ' + chartUnit.suffix
        },
      },
    },
    legend: {
      show: false,
      fontSize: '15px',
      position: 'top',
      offsetY: 15,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'straight',
      show: true,
      width: 3,
    },
    xaxis: {
      categories: labels || [],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      decimalsInFloat: 2,
    },
    yaxis: [
      {
        labels: {
          formatter: (value: number) =>
            formatNumber(value * chartUnit.ratio, 1, '.') + ' ' + chartUnit.suffix,
          style: {
            colors: labelColor,
          },
        },
        opposite: true,
        title: {
          text: 'Periodic',
        },
      },
      {
        title: {
          text: 'Cumulative',
        },
        max: 100,
        labels: {
          formatter: (value: number) =>
            formatNumber(value * chartUnit.ratio, 1, '.') + ' ' + chartUnit.suffix,
          style: {
            colors: labelColor,
          },
        },
      },
      ...custom_curve?.map((curve: CustomCurve) => ({
        max: 100,
        labels: {
          show: false,
        },
      })),
    ],
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    colors: [
      ({value, seriesIndex, dataPointIndex, w}: any) => {
        if (!data2 || !highlightIdx) return '#14D193'
        if (dataPointIndex === highlightIdx) return '#FFB800'
        return '#14D193'
      },
      '#0B69FF',
    ],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}

export function getNotStartedResourceChartOptions(
  height: number,
  chartUnit: ChartUnit,
  data1: any[],
  data2?: any[],
  labels?: any[],
  custom_curve?: any,
  highlightIdx?: number
): ApexOptions {
  const primaryColor = getCSSVariableValue('--kt-primary')
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-info')
  const averageFTE = (data2?.reduce((a, b) => +a + +b, 0) * chartUnit.ratio) / (data2?.length ?? 1)
  return {
    series: [
      {
        data: data2 || [],
        name: 'FTE',
        type: 'column',
      },
      {
        data: Array(data2?.length).fill(averageFTE),
        name: 'Average FTE',
        type: 'line',
      },
      ...custom_curve
        ?.filter((curve: ChartCustomCurve) => curve.name !== RECORD_TO_IGNORE && curve.show)
        ?.map((curve: CustomCurve) => ({
          type: 'column',
          name: curve.name,
          data: getPeriodicFromCumulative(
            curve.values.slice(0, data2?.length || curve.values.length)
          ),
          color: curve.color,
        })),
      ...custom_curve
        ?.filter((curve: ChartCustomCurve) => curve.name !== RECORD_TO_IGNORE && curve.showAverage)
        ?.map((curve: CustomCurve) => {
          const average =
            (getPeriodicFromCumulative(
              curve.values.slice(0, data2?.length || curve.values.length)
            ).reduce((a, b) => a + b, 0) *
              chartUnit.ratio) /
            curve.values.length
          return {
            type: 'line',
            name: curve.name + ' Average',
            data: curve.values.map(() => average),
            color: curve.color,
          }
        }),
    ],
    chart: {
      animations: {
        enabled: false,
      },
      fontFamily: 'inherit',
      height: 400,
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,

          customIcons: [],
        },
      },
    },
    colors: [primaryColor, primaryColor],
    tooltip: {
      enabled: true,
      shared: false,
      y: {
        formatter: (val: any) => {
          if (!val) return '- ' + chartUnit.suffix
          return formatNumber(val * chartUnit.ratio, 1, '.') + ' ' + chartUnit.suffix
        },
      },
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
    legend: {
      show: false,
      fontSize: '15px',
      position: 'top',
      offsetY: 15,
    },
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => {
        if (
          opts?.ctx?.opts?.series[opts.seriesIndex]?.name?.includes(' Average') &&
          opts.dataPointIndex === 0
        )
          return (val as number).toFixed(2)
        if (!val) return ''
        if ([1].includes(opts.seriesIndex))
          if (opts.dataPointIndex === 0) return (val as number).toFixed(2)

        return ''
      },
    },
    fill: {
      type: 'solid',
      opacity: 0.5,
    },
    stroke: {
      curve: 'straight',
      show: true,
      width: 3,
    },
    xaxis: {
      categories: labels || [],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      decimalsInFloat: 2,
    },
    yaxis: [
      {
        labels: {
          formatter: (value: number) =>
            formatNumber(value * chartUnit.ratio, 1, '.') + ' ' + chartUnit.suffix,
          style: {
            colors: labelColor,
          },
        },
        title: {
          text: 'Periodic',
        },
      },
    ],
  }
}

export function getStartedResourceChartOptions(
  height: number,
  chartUnit: ChartUnit,
  data: any[],
  data2?: any[],
  labels?: any[],
  custom_curve?: any,
  highlightIdx?: number
): ApexOptions {
  const primaryColor = getCSSVariableValue('--kt-primary')
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-info')

  const realFTE = data2?.map((item, index) => (index <= (highlightIdx ?? 0) ? +item : null)) || []
  const forecastFTE =
    data2?.map((item, index) => (index > (highlightIdx ?? 0) ? +item : null)) || []

  const averageReal =
    (realFTE.reduce((prev, current) => (prev || 0) + (current || 0), 0) ?? 0) /
    ((highlightIdx || 0) + 1)
  const averageForecast =
    (forecastFTE.reduce((a, b) => (a || 0) + (b || 0), 0) ?? 0) /
    ((data2?.length || 0) - (highlightIdx || 0) + 1)

  const averageRealChart = realFTE.map((item) => (item ? averageReal : null))
  const averageForecastChart = forecastFTE.map((item) => (item ? averageForecast : null))

  return {
    series: [
      {
        data: realFTE,
        name: 'Real FTE',
        type: 'column',
      },
      {
        data: averageRealChart,
        name: 'Average Real FTE',
        type: 'line',
      },
      {
        data: forecastFTE,
        name: 'Forecast FTE',
        type: 'column',
      },
      {
        data: averageForecastChart,
        name: 'Average Forecast FTE',
        type: 'line',
      },
      ...custom_curve
        ?.filter((curve: ChartCustomCurve) => curve.name !== RECORD_TO_IGNORE && curve.show)
        ?.map((curve: CustomCurve) => ({
          type: 'column',
          name: curve.name,
          data: getPeriodicFromCumulative(
            curve.values.slice(0, data2?.length || curve.values.length)
          ),
          color: curve.color,
        })),
      ...custom_curve
        ?.filter((curve: ChartCustomCurve) => curve.name !== RECORD_TO_IGNORE && curve.showAverage)
        ?.map((curve: CustomCurve) => {
          const average =
            getPeriodicFromCumulative(curve.values.slice(0, data2?.length || curve.values.length))
              .map((item) => item * chartUnit.ratio)
              .reduce((a, b) => a + b, 0) / curve.values.length
          return {
            type: 'line',
            name: curve.name + ' Average',
            data: curve.values.map(() => average),
            color: curve.color,
          }
        }),
    ],

    chart: {
      animations: {
        enabled: false,
      },
      fontFamily: 'inherit',
      height: 400,
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,

          customIcons: [],
        },
      },
    },
    colors: [primaryColor, primaryColor, baseColor, baseColor],
    tooltip: {
      enabled: true,
      shared: false,
      y: {
        formatter: (val: any) => {
          if (!val) return '- ' + chartUnit.suffix
          return formatNumber(val * chartUnit.ratio, 1, '.') + ' ' + chartUnit.suffix
        },
      },
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
    legend: {
      show: false,
      fontSize: '15px',
      position: 'top',
      offsetY: 15,
    },
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => {
        if (!val) return ''
        if (
          opts?.ctx?.opts?.series[opts.seriesIndex]?.name?.includes(' Average') &&
          opts.dataPointIndex === 0
        )
          return (val as number).toFixed(2)
        if ([1].includes(opts.seriesIndex))
          if (opts.dataPointIndex === 0) return ((val as number) * chartUnit.ratio).toFixed(2)
        if ([3].includes(opts.seriesIndex)) {
          if (opts.dataPointIndex === (highlightIdx || 0) + 1) {
            return ((val as number) * chartUnit.ratio).toFixed(2)
          }
        }

        return ''
      },
    },
    fill: {
      type: ['solid', 'solid', 'pattern', 'solid'],
      pattern: {style: 'slantedLines'},
      opacity: 0.5,
    },
    stroke: {
      curve: 'straight',
      dashArray: [0, 0, 0, 5],
      show: true,
      width: 3,
    },
    xaxis: {
      categories: labels || [],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      decimalsInFloat: 2,
    },
    yaxis: [
      {
        labels: {
          formatter: (value: number) =>
            formatNumber(value * chartUnit.ratio, 1, '.') + ' ' + chartUnit.suffix,
          style: {
            colors: labelColor,
          },
        },
        title: {
          text: 'Periodic',
        },
      },
    ],
  }
}
