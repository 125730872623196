import {useEffect, useState} from 'react'
import CreateComment from './CreateComment'
import CommentTable from './CommentTable'
import SearchBar from '../../../../components/SearchBar/SearchBar'
import {useLocation} from 'react-router-dom'
import {useProject} from '../../core/ProjectContext'
import EmptyValuesCard from '../../../../components/emptyValuesCard'
import MultiOptionFilter from '../../../../lib/filters/MultiOptionFilter'
import ExportCustomData from '../../../../lib/export/ExportCustomData'
import {format} from 'date-fns'
import {DateUnit} from '../pagination/header/ProjectDetailsHeader'
import {getFNSDateFormat, getFormattedDate} from '../../../../helpers/DateFormatter'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { getCommentbywbs } from './request'
import { Comment } from './models'

type Props = {
  showActions?: boolean
  isShared?: boolean;
}

export default function Comments({showActions, isShared}: Props) {
  const {originalVersion, project} = useProject()
  const added=useSelector((state:RootState)=>state.added.value)
  const [action, setAction] = useState<any>('')
  const [commenterFilter, setCommenterFilter] = useState<string[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const {state} = useLocation()
  const [comments,setComments]=useState<Comment[]>()
  const currentwbs=useSelector((state:RootState)=>state.currentwbs.value)
  useEffect(() => {
    if ((state as any)?.action) setAction((state as any).action)
    window.history.replaceState({}, document.title)
  }, [state])
  useEffect(()=>{
    const getdata=async()=>{
      const data=await getCommentbywbs(currentwbs.toString())
      setComments(data)
    }
    getdata()
  },[added])
  const getAllCommenters = (): string[] => {
    const commentersArray = originalVersion?.comments.map((comment) => comment.commenter)
    const uniqueCommenters = [...new Set(commentersArray)]
    return uniqueCommenters
  }

  const getExportData = () =>
    originalVersion?.comments.map((item) => ({
      'N°': item?.subject,
      // 'Date Comment': format(
      //   new Date(item.comment_date),
      //   getFNSDateFormat(originalVersion.date_format)
      // ),
      Comment: item.comment,
      Commenter: item.commenter,
      // 'Concerned Data Date': getFormattedDate(
      //   new Date(item?.data_date || 0),
      //   originalVersion?.period_count?.type as DateUnit,
      //   originalVersion?.date_format
      // ),
    }))

  return (
    <>
        {(comments?.length  || !isShared) &&(

    <>
      <CreateComment onClose={() => setShowModal(false)} show={showModal} />
      <h2>Comments</h2>
      <div className='d-flex flex-wrap flex-stack pb-7'>
        <div className='d-flex flex-wrap align-items-center my-1 gap-3'>
          <SearchBar
            keyword={searchKeyword}
            setKeyword={setSearchKeyword}
            placeholder='Search Comment N°'
          />
          {action && (
            <div className='badge badge-primary d-flex gap-2'>
              {action?.subject}
              <button className='btn p-0' onClick={() => setAction('')}>
                <i className='bi bi-x text-white p-1 fs-3'></i>
              </button>
            </div>
          )}
        </div>
        <div className='d-flex flex-wrap my-1 gap-2'>
          <ExportCustomData data={getExportData()} name={`comments_${project?.name}`} />
          <MultiOptionFilter
            title='Commenter'
            filter={commenterFilter}
            setFilter={setCommenterFilter}
            filters={getAllCommenters().map((commenter) => ({label: commenter, value: commenter}))}
          />
          {!isShared && (
          <div className='d-flex my-0'>
            <a
              href='#'
              className='btn btn-sm btn-primary me-3'
              onClick={() => {
                setShowModal(true)
              }}
            >
              Add Comment
            </a>
          </div> )}
        </div>
      </div>
      <div className='tab-content'>
        {!comments? (
          <div className='justify-content-center'>
            <p className="placeholder-glow">
              <span className="placeholder col-12"></span>
            </p>
            <p className="placeholder-glow">
              <span className="placeholder col-12"></span>
            </p>
            <p className="placeholder-glow">
              <span className="placeholder col-12"></span>
            </p>
          </div>
        ) : comments.length  ? (
          <CommentTable
            commenterFilter={commenterFilter}
            action={action}
            keyword={searchKeyword}
            showActions={showActions}
            comments={comments}
          />
        ) : (
          <EmptyValuesCard
            title='No Comments'
            text='Add Comment'
            buttonText='Add Comment'
            setShowCreateAppModal={setShowModal}
          />
        )}

      </div>
    </>)}
    </>
  )
}
