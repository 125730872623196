/* eslint-disable jsx-a11y/anchor-is-valid */
import { DatePicker } from 'antd'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect, useState } from 'react'

import { Dropdown, OverlayTrigger, Popover } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { components } from 'react-select'
import { KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import CustomSelect from '../../../../../components/CustomSelect'
import { getFormattedDate } from '../../../../../helpers/DateFormatter'
import { IBudget, getFormattedBudget, sweetAlert } from '../../../../../utils/funcs'
import { useProject } from '../../../core/ProjectContext'
import { CustomToggle } from '../../../core/ProjectHelpers'
import { useProjectDetailsById } from '../../../core/_queries'
import {
  createVersion,
  deleteProject,
  getProjectImageLink,
  getVersionById,
  patchProject,
  patchVersion,
} from '../../../core/_requests'
import { isMilestoneValid } from '../../overview/milestones/_helpers'
import MoveItem from './MoveProjectModal'
import SendProjectModal from './SendProjectModal'
import { checkCompleteData } from '../../../core/ProjectHelpers'
import ShareProjectModal from './ShareProjectModal'
import { useAuth } from '../../../../auth'
import { getDiffrentBettwenDate } from '../../../../../utils/data-transformarion/date-utils'
import ShowWbsModal from './ShowWbsModal'
import { createWbs, deleteWbs, getWbs, patchWbs } from '../../../../wbs/_requests'
import WbsNameModal from './WbsNameModal'
import { useSelector, useDispatch } from 'react-redux';
import { open, isntheader } from '../../../../wbs/treeStates'
import { AppDispatch, RootState } from '../../../../../store'
import { fetchDataById, setVersionData } from '../../../../wbs/treeVersionData'
import type { DataNode } from 'antd/es/tree'
import { addWbsToStore, updateCollectedLeafData, updateIsTreeLoaded, updateIsWpChanged, updateWbsCalculatedData, updateWpsCalculatedData } from '../../../../wbs/treedata'

import { combineVersionsV2, combineVersionsWithoutStep1 } from '../../../../wbs/components/TreeView/_helpers'

import { incrementLoaded, incrementTotal, updateLabel, updateLoaded, updateShow, updateTotal } from '../../../../wbs/treeLoader'
import TreeFolderItem from '../../../../wbs/components/TreeView/TreeFolderItem'
import { getCustomCurve } from '../../overview/Project'
import { check } from 'prettier'




export type DateUnit = 'monthly' | 'yearly' | 'daily'
const convertDateUnite = (delayUnit: string) => {
  switch (delayUnit) {
    case 'monthly':
      return 'Months'
    case 'daily':
      return 'Days'
    case 'yearly':
      return 'Years'
    default:
      break
  }
}
interface TreeNodeProps {
  folder: {
    _id: { $oid: string }
    name: string
    parent_wbs?: { $oid: string }
    data: { $oid: string }
    subwbs: any
  }
}

// Add shareVersionId and setShareVersionId to display the shared version on
// the button dropdown and track the shareVersionId state
const ProjectDetailsHeader: FC<any> = ({ versionId, setVersionId }) => {


  const {
    setDataDate,
    setWbsDataDateFirstStepAsync,
    setWbsDataDateSecondStepAsync,
    setOriginalVersion,
    setVersion,
    setLoading: setProjectLoading,
    originalVersion: data,
    project: projectData,
    displayVersion: newVersion,
    setProject,
  } = useProject()
  const [selectedDataDate, setSelectedDataDate] = useState<Date>(
    new Date(data?.data_date?.$date || 0)
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [isActionPending, setIsActionPending] = useState<boolean>(false);
  const [showWbsNameModal, setShowWbsNameModal] = useState<boolean>(false)
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const { refetch } = useProjectDetailsById(projectData?._id.$oid || '', () => { }, false)
  const [datePicker, setDatePicker] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [show, setShow] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showWbsModal, setShowWbsModal] = useState(false)
  const [showMove, setShowMove] = useState<boolean>(false)
  const [latestOpenWbs, setLatestOpenWbs] = useState<any>()
  const dispatch = useDispatch();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const projectversiondata = useSelector((state: RootState) => state.versiondata.data)
  const collectedLeafData = useSelector((state: RootState) => state.treedata.collectedLeafData);
  const isWpChanged = useSelector((state: RootState) => state.treedata.isWpChanged);
  const wpChangedId = useSelector((state: RootState) => state.treedata.wpChangedId);
  const isChecked = useSelector((state: RootState) => state.treeStates.value);
  const isLoaded = useSelector((state: RootState) => state.treeloader.value);

  const loaderLabel = useSelector((state: RootState) => state.treeLoader.label);
  const loaderLoaded = useSelector((state: RootState) => state.treeLoader.loaded);
  const loaderTotal = useSelector((state: RootState) => state.treeLoader.total);

  const [showDataDatePaywall, setShowDataDatePaywall] = useState<boolean>(false)

  const [showOverlay, setShowOverlay] = useState(
    false ||
    !newVersion?.milestones?.every((item) =>
      isMilestoneValid(item, new Date(newVersion.data_date.$date))
    )
  )


  useEffect(() => {
    setShowWbsModal(isChecked)

  }, [isChecked])

  useEffect(() => {
    setLoading(isLoaded)
  }, [isLoaded])

  useEffect(() => {
    setShowOverlay(
      !newVersion?.milestones.every((item) =>
        isMilestoneValid(item, new Date(newVersion.data_date.$date))
      )
    )


    if (!projectData?.default_wbs) {
      handleCreateWbs(projectData?.name + ' WBS 0');
    }

  }, [newVersion])

  useEffect(() => {
    setSelectedDataDate(new Date(data?.data_date?.$date || 0))
  }, [data?._id?.$oid, data?.data_date?.$date])

  useEffect(() => {
    setSelectedDataDate(new Date(newVersion?.data_date?.$date || 0))
  }, [newVersion?.data_date?.$date])




  useEffect(() => {
    const loadAllWbs = async (wbsId: string): Promise<DataNode> => {
      let knownWbsItem = knownWbs[wbsId];
      if (!knownWbsItem) {
        const response = await getWbs(wbsId);
        dispatch(addWbsToStore(response));
        knownWbsItem = response;
      }

      const childrenData: DataNode[] = await Promise.all(
        knownWbsItem.subwbs.map(async (subWbs) => {
          return await loadAllWbs(subWbs.$oid);
        })
      );

      return {
        key: knownWbsItem._id.$oid,
        children: childrenData,
      };
    };

    const initializeTree = async () => {
      if (projectData?.current_wbs) {
        const rootNode = await loadAllWbs(projectData?.current_wbs);
        setTreeData([rootNode]);
      }
    };

    initializeTree();
  }, [projectData?.current_wbs]);




  useEffect(() => {
    const traverseTree = async (node: DataNode) => {
      const vers = knownWbs[node?.key];
      if (vers.subwbs.length === 0 && vers?.data) {
        dispatch(incrementTotal());
        let data = projectversiondata[vers.data.$oid];
        if (!data) {
          data = await getVersionById(vers.data.$oid);
          data = { ...data, name: vers.name };
          dispatch(setVersionData({ id: vers.data.$oid, data: data }));
        }
        dispatch(incrementLoaded());
      }
      if (node.children && node.children.length > 0) {
        await Promise.all(node.children.map((childNode: DataNode) => traverseTree(childNode)));
      }
    };

    if (treeData && treeData.length > 0) {
      traverseTree(treeData[0]);
    }
  }, [treeData]);

  useEffect(() => {
    dispatch(updateShow(true));
    dispatch(updateLabel('Loading WBS'));
    dispatch(updateLoaded(0));
    dispatch(updateTotal(0));

  }, [projectData?.current_wbs])

  useEffect(() => {
    const last_opened_wbs = knownWbs[projectData?.current_wbs]?.last_opened_wbs?.$oid ? knownWbs[projectData?.current_wbs]?.last_opened_wbs?.$oid : projectData?.current_wbs;
    if (last_opened_wbs)
      setLatestOpenWbs(knownWbs[last_opened_wbs]);
  }, [projectData?.current_wbs, knownWbs])

  useEffect(() => {
    if (loaderTotal > 0) {


      // Hide overlay when progress reaches 100%
      if (((loaderLoaded / loaderTotal) * 100 >= 100) && loaderLabel === 'Loading WBS') {
        dispatch(updateShow(false));
        setShowWbsModal(true);
        dispatch(updateLoaded(0));
        dispatch(updateTotal(0));
      }
    }
  }, [loaderLoaded, loaderTotal]);

  const handleAddUser = () => {
    setShow(true)
  }

  // this useEffect is used to update the wbs data whenever a WP is updated
  useEffect(() => {
    const calculateCombinedVersionForNodes = async () => {
      const parentsWbs: string[] = [];
      const findParent = (node: DataNode, childKey: string): boolean => {
        if (node.children) {
          for (const child of node.children) {
            if (child.key === childKey || findParent(child, childKey)) {
              parentsWbs.push(node.key as string);
              return true;
            }
          }
        }
        return false;
      };

      findParent(treeData[0], wpChangedId);

      console.log("parentsWbs ==>", parentsWbs.map((p) => 'id :' + p + ' \n name :' + knownWbs[p].name + '\n -------------------'));

      dispatch(updateLoaded(0));
      dispatch(updateTotal(parentsWbs.length));
      dispatch(updateShow(parentsWbs.length !== 0));
      dispatch(updateLabel('Updating and Saving WBS data...'));

      for (const [index, node] of parentsWbs.entries()) {
        dispatch(updateLabel('Updating and Saving WBS data ' + (index + 1) + ' of ' + parentsWbs.length));

        const leafData = await collectLeafData(node);
        if (leafData.length > 0) {


          const combinedVersion = await combineVersionsWithoutStep1(knownWbs[node].name, leafData, setWbsDataDateSecondStepAsync);

          if (projectData?._id) {
            let version
            if (knownWbs[node]?.data?.$oid) {
              version = await patchVersion({
                start_date: new Date(combinedVersion.start_date.$date),
                end_date: new Date(combinedVersion.end_date.$date),
                data_date: new Date(combinedVersion.data_date.$date),
                custom_curve: combinedVersion?.custom_curve,
                reserve: combinedVersion?.reserve,
                delay_tolerance: combinedVersion?.delay_tolerance,
                budget_at_completion: combinedVersion?.budget_at_completion,
                total_hours: combinedVersion?.total_hours,
                achieved_percentage: combinedVersion?.achieved_percentage,
                rebase_line: combinedVersion?.rebase_line,
                output: combinedVersion?.output,
              }, knownWbs[node]?.data.$oid);

            } else {
              const { _id, associated_calendar, project, user, updated_at, created_at, ...rest } = combinedVersion;
              version = await createVersion(
                {
                  project_version: {
                    ...rest,
                    start_date: new Date(rest.start_date.$date),
                    end_date: new Date(rest.end_date.$date),
                    data_date: new Date(rest.data_date.$date),
                    forecast_settings: {
                      ...rest.forecast_settings,
                      custom_end_date: rest.forecast_settings?.custom_end_date
                        ? new Date(rest.forecast_settings.custom_end_date.$date)
                        : null,
                    },
                  },
                  timestamp_period: 1
                },
                projectData?._id.$oid
              );
              const patchedWbs = await patchWbs(node, { data: version._id.$oid })
              dispatch(addWbsToStore(patchedWbs));
            }
            if (version) {
              dispatch(setVersionData({ id: version._id.$oid, data: version }));
              if (data?._id.$oid === version._id.$oid) {
                dispatch(updateCollectedLeafData(leafData));
                setOriginalVersion(version);
                setVersion(version);
              }
            }
          }
        }
        dispatch(incrementLoaded());
      }

      dispatch(updateShow(false));
    };



    const runAsyncTasks = async () => {

      if (treeData.length > 0) {
        await calculateCombinedVersionForNodes();
      }
      dispatch(updateIsWpChanged(false));
    };

    if (isWpChanged) {
      runAsyncTasks();
    }
    console.log("isWpChanged ==>", isWpChanged);

  }, [isWpChanged, wpChangedId]);



  const collectLeafData = async (node: string): Promise<any[]> => {
    const leafData: any[] = []
    const traverse = async (currentNode: string) => {
      const nodeValue = knownWbs[currentNode]

      if (!nodeValue?.subwbs || nodeValue?.subwbs?.length === 0) {
        if (nodeValue?.data) {
          let versionData = projectversiondata[nodeValue.data.$oid]
          if (!versionData) {
            let fetchedData = await getVersionById(nodeValue.data.$oid)
            fetchedData = { ...fetchedData, name: nodeValue.name }
            dispatch(setVersionData({ id: nodeValue.data.$oid, data: fetchedData }))
            versionData = fetchedData
          }
          leafData.push(versionData)
        }
      } else {
        for (const child of nodeValue.subwbs) {

          const childWbsData = knownWbs[child.$oid] || (await getWbs(child?.$oid))
          if (!knownWbs[child?.$oid]) {
            dispatch(addWbsToStore(childWbsData))
          }

          await traverse(childWbsData._id.$oid)
        }
      }

    }
    await traverse(node as string)
    return leafData
  }

  //delete project
  const handleDelete = (projectId: string | undefined) => {
    if (!projectId) return
    setLoading(true)
    deleteProject(projectId)
      .then((res) => refetch())
      .catch((err) =>
        sweetAlert({
          title: 'Error',
          text: err.message || 'Something went wrong!',
          icon: 'error',
        })
      )
      .finally(() => setLoading(false))
  }

  const handleSetShowShareModal = () => {
    setShowShareModal(true)
  }

  const handleUpgradeClick = () => {
    navigate('/profile/billing');
  };

  const showConfirm = () => {
    sweetAlert({
      title: 'Are you sure?',
      text: 'You will not be able to recover this project!',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      icon: 'warning',
      preConfirm: () => handleDelete(projectData?._id.$oid),
      allowOutsideClick: () => !loading,
    }).then((res) => {
      if (res.isConfirmed) {
        sweetAlert({
          title: 'Deleted!',
          text: 'Project has been deleted.',
          icon: 'success',
        }).then(() => navigate('/projects'))
      }
    })
  }

  const handleSetDefault = (versionId: string) => {
    if (projectData) {
      patchProject(
        {
          default_wbs: versionId,
        },
        projectData?._id.$oid
      ).then((res) => {
        setProject((prev) =>
          prev && res ? { ...prev, default_wbs: res?.default_wbs } : undefined
        )
        sweetAlert({
          title: 'Success',
          text: 'Version Created Successfully',
          icon: 'success',
        }).then(() => {
          setIsActionPending(false);
        });
      }
      );

    }
  }

  let realStartDate = new Date(data?.start_date?.$date ?? 0);

  if (data && data.custom_curve) {
    let realStartIndex = -1;

    ['cumulativeActualCost', 'cumulativeEarnedValue'].some((curveName) => {
      const curve = data.custom_curve?.find((curve: any) => curve.name === curveName);
      if (curve) {
        const index = curve.values.findIndex((value: number) => value !== 0);
        if (index !== -1) {
          realStartIndex = index;
          return true;
        }
      }
      return false;
    });

    if (realStartIndex !== -1) {
      switch (data.period_count.type) {
        case 'daily':
          realStartDate = new Date(data.start_date.$date + realStartIndex * (24 * 60 * 60 * 1000));
          break;
        case 'weekly':
          realStartDate = new Date(data.start_date.$date + realStartIndex * (7 * 24 * 60 * 60 * 1000));
          break;
        case 'monthly':
          realStartDate = new Date(dayjs(data.start_date.$date).add(realStartIndex, 'month').toDate());
          break;
        default:
          realStartDate = new Date(dayjs(data.start_date.$date).add(realStartIndex, 'month').toDate()); // Fallback to monthly
      }
    }
  }


  const getLastOpenWbsTree = async (): Promise<any[]> => {
    const allKeys: string[] = [];

    const fetchWbsRecursively = async (wbsId: string): Promise<DataNode> => {
      let knownWbsItem = knownWbs[wbsId];
      if (!knownWbsItem) {
        const response = await getWbs(wbsId);
        dispatch(addWbsToStore(response));
        knownWbsItem = response;
      }

      allKeys.push(knownWbsItem._id.$oid);

      const childrenData: DataNode[] = await Promise.all(
        knownWbsItem.subwbs.map(async (subWbs) => {
          return await fetchWbsRecursively(subWbs.$oid);
        })
      );

      return {
        key: knownWbsItem._id.$oid,
        title: <TreeFolderItem folder={knownWbsItem} />,
        children: childrenData,
      };
    };

    const last_opened_wbs = knownWbs[projectData?.current_wbs]?.last_opened_wbs?.$oid ? knownWbs[projectData?.current_wbs]?.last_opened_wbs?.$oid : projectData?.current_wbs

    if (last_opened_wbs) {
      const rootNode = await fetchWbsRecursively(last_opened_wbs);

      return [rootNode];

    }
    return []
  };

  const calculateCombinedVersionForEachWbsInLastOpenWbs = async (data_date: Date): Promise<any[]> => {

    const wbsList: string[] = [];
    const wbsListData: any[] = [];

    const lastOpenWbsTree = await getLastOpenWbsTree()

    const findParent = (node: DataNode): void => {
      if (node.children) {
        for (const child of node.children) {
          if (child.children && child.children.length > 0) {
            wbsList.push(child.key as string);
          }
          findParent(child);
        }
      }
    };

    findParent(lastOpenWbsTree[0]);

    dispatch(updateLoaded(0));
    dispatch(updateTotal(wbsList.length));
    dispatch(updateShow(wbsList.length !== 0));
    dispatch(updateLabel('Calculating Dashboard Data...'));

    for (const [index, node] of wbsList.entries()) {
      dispatch(updateLabel('Calculating Dashboard Data ' + (index + 1) + ' of ' + wbsList.length));

      const leafData = await collectLeafData(node);
      if (leafData.length > 0) {
        let combinedVersion = await combineVersionsV2(knownWbs[node].name, leafData, true, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync, data_date);
        combinedVersion = { ...combinedVersion, _id: { $oid: knownWbs[node]?.data?.$oid } };
        wbsListData.push(combinedVersion);
      }
      dispatch(incrementLoaded());
    }

    dispatch(updateShow(false));
    return wbsListData;
  };



  const handleshowwbs = () => {
    setShowWbsModal(true)
    dispatch(open())
  }

  const handleDataDate = () => {
    setDatePicker((prev) => !prev)
  }

  const checkCompleteDataV2 = async (data_date: Date, id: string) => {
    let originalVersion = projectversiondata[id]
    if (!originalVersion) {
      originalVersion = await getVersionById(id)
    }
    const dateDiff = getDiffrentBettwenDate(
      data_date.getTime(),
      originalVersion?.start_date.$date || 0,
      originalVersion?.period_count.type || ''
    )
    return !(
      dateDiff > getCustomCurve('cumulativeEarnedValue', originalVersion).length - 1 ||
      dateDiff > getCustomCurve('cumulativeActualCost', originalVersion).length - 1
    )
  }

  const checkDataDate = async (data_list: any[], date: Date) => {
    const checkResults = await Promise.all(
      data_list.map(async (data) => await checkCompleteDataV2(date, data._id.$oid))
    );

    const isDataComplete = checkResults.every(result => result);

    dispatch(updateShow(false));

    if (!isDataComplete) {
      sweetAlert({
        title: 'Data Missing',
        icon: 'info',
        html: '<p>Data for this date is missing<br> Fill in the missing data to see the forecast</p>',
      }).then(() => navigate(`/project/${projectData?._id.$oid}/wbs/tree`));
      return false;
    }

    return true;
  };



  const handleDataDateChange = async (date: dayjs.Dayjs) => {

    setDatePicker(false);
    dispatch(updateLabel('Calculating WBS Data'));
    dispatch(updateTotal(2));
    dispatch(updateLoaded(0));

    // Wait for a short moment to ensure the date picker has closed completely
    await new Promise((resolve) => setTimeout(resolve, 300));
    dispatch(updateShow(true));

    const last_opened_wbs = knownWbs[projectData?.current_wbs]?.last_opened_wbs?.$oid ? knownWbs[projectData?.current_wbs]?.last_opened_wbs?.$oid : projectData?.current_wbs;
    if (last_opened_wbs) {
      const leafData = await collectLeafData(last_opened_wbs);
      const isDataComplete = await checkDataDate(leafData, date.toDate())
      if (isDataComplete) {
        setSelectedDataDate(date.toDate())
        let combinedVersion = await combineVersionsV2(
          data?.name ? data?.name : 'WBS',
          leafData,
          true,
          setWbsDataDateFirstStepAsync,
          setWbsDataDateSecondStepAsync,
          date.toDate(),
          (wpsData) => dispatch(updateWpsCalculatedData(wpsData))
        );

        const wbsListData = await calculateCombinedVersionForEachWbsInLastOpenWbs(date.toDate())


        wbsListData.push({ ...combinedVersion, _id: { $oid: knownWbs[last_opened_wbs]?.data.$oid }, name: data?.name })
        dispatch(updateWbsCalculatedData(wbsListData));

        if (combinedVersion) {
          combinedVersion = {
            ...combinedVersion,
            _id: data?._id,
            name: data?.name
          };
        } else {
          combinedVersion = { ...data }
        }
        console.log("combinedVersion ==>")
        console.log(combinedVersion)
        setVersion(combinedVersion)
        setOriginalVersion(combinedVersion)
      }
    }
    dispatch(updateShow(false));
  }

  const handleCreateWbs = async (name: string) => {
    if (!projectData || !projectData._id) return;
    setIsActionPending(true);

    const wbsParams = {
      name,
      parent_project: projectData._id.$oid
    };

    try {
      const newWbs = await createWbs(wbsParams);
      const updatedWbsList = [...(projectData.wbs_list || []), newWbs._id];

      const new_current_wbs = newWbs._id.$oid
      const new_default_wbs = projectData.wbs_list.length === 0 ? newWbs._id.$oid : projectData?.default_wbs._id.$oid

      await createWbs({
        name: 'WP 0',
        parent_wbs: newWbs._id.$oid,
        parent_project: projectData._id.$oid,
      });

      patchProject(
        {
          wbs_list: updatedWbsList.map(w => w.$oid),
          default_wbs: new_default_wbs,
        },
        projectData._id.$oid
      ).then((res) =>
        setProject((prev) =>
          prev && res ? { ...prev, default_wbs: res?.default_wbs, wbs_list: res.wbs_list, current_wbs: new_current_wbs } : undefined
        )
      );

      sweetAlert({
        title: 'Success',
        text: 'Version Created Successfully',
        icon: 'success',
      }).then(() => {
        setIsActionPending(false);
      });

    } catch (error: any) {
      sweetAlert({
        title: 'Error',
        text: error.response?.data?.message || 'Something went wrong!',
        icon: 'error',
      });
      console.log(`Failed to Add WBS`)
      console.log(error)
      setIsActionPending(false);
    }
  };



  const handleDeleteWbs = async (wbsId: string) => {
    if (!projectData || !projectData._id) return;
    setIsActionPending(true);

    const isDefaultWbsDeleted = projectData.default_wbs._id.$oid === wbsId;

    // Check if the WBS to be deleted is the default WBS
    if (isDefaultWbsDeleted) {
      sweetAlert({
        title: 'Warning',
        text: 'You cannot delete the default WBS!',
        icon: 'warning',
      });
      setIsActionPending(false);
      return;
    }

    sweetAlert({
      title: 'Are you sure?',
      text: 'Do you really want to delete this Version? This process cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const updatedWbsList = projectData.wbs_list.filter((wbs: any) => wbs.$oid !== wbsId);
          const isCurrentWbsDeleted = projectData.current_wbs === wbsId;
          const newCurrentWbs = isCurrentWbsDeleted && updatedWbsList.length > 0 ? updatedWbsList[0].$oid : projectData.current_wbs;
          const newDefaultWbs = isDefaultWbsDeleted && updatedWbsList.length > 0 ? updatedWbsList[0].$oid : projectData.default_wbs._id.$oid;

          //Send new_wbs_list as an array of strings that contain the new wbs list
          const new_wbs_list = updatedWbsList.map((w: any) => w.$oid)
          const new_default_wbs = updatedWbsList.length === 0 ? undefined : newDefaultWbs
          const new_current_wbs = updatedWbsList.length === 0 ? undefined : newCurrentWbs


          patchProject(
            {
              wbs_list: new_wbs_list,
              default_wbs: new_default_wbs,
            },
            projectData._id.$oid
          ).then((res) =>
            setProject((prev) =>
              prev && res ? { ...prev, default_wbs: res?.default_wbs, wbs_list: res.wbs_list, current_wbs: new_current_wbs } : undefined
            )
          );

          console.log(`Project patched successfully with updated WBS list and current WBS.`);

          console.log(`Deleting WBS with ID: ${wbsId}`);
          await deleteWbs(wbsId);
          console.log(`WBS with ID: ${wbsId} deleted successfully.`);


          sweetAlert({
            title: 'Deleted!',
            text: 'The Version has been deleted.',
            icon: 'success',
          }).then(() => {
            setIsActionPending(false);
          });
        } catch (error) {
          sweetAlert({
            title: 'Error',
            text: (error as Error).message || 'Something went wrong!',
            icon: 'error',
          });
          setIsActionPending(false);
        }
      } else {
        setIsActionPending(false);
      }
    });
  };


  const dataDateIndex =
    getDiffrentBettwenDate(
      newVersion?.data_date.$date || 0,
      newVersion?.start_date.$date || 0,
      newVersion?.period_count.type || 'month'
    ) || 0


  const CustomWbsOption = (props: any) => {
    return (
      <div className='d-flex align-items-center justify-content-between'>
        <components.Option {...props} />
        <div className='mx-3 d-flex gap-2'>
          <button
            onClick={() => handleSetDefault(props.data.value)}
            className='btn btn-flushed p-0'
          >
            <KTSVG
              className={`svg-icon-${props.data.value === projectData?.default_wbs._id?.$oid
                ? 'warning'
                : 'secondary'
                } fs-2x hover-warning`}
              path='/media/icons/duotune/general/gen003.svg'
            />
          </button>
          <button
            onClick={() => handleDeleteWbs(props.data.value)}
            className='btn btn-flushed p-0'
          >
            <KTSVG
              className='svg-icon-danger fs-2x'
              path='/media/icons/duotune/general/gen034.svg'
            />
          </button>
        </div>
      </div>
    )
  }

  const popover = (
    <Popover>
      <Popover.Header>Attention Required</Popover.Header>
      <Popover.Body>
        <p>Some Milestones require an update</p>
        <div className='d-flex justify-content-between'>
          <Link
            className='btn btn-primary btn-sm'
            to={`/project/${data?.project.$oid}/milestones`}
            onClick={() => setShowOverlay(false)}
          >
            Go
          </Link>
          <button onClick={() => setShowOverlay(false)} className='btn btn-secondary btn-sm'>
            Ignore
          </button>
        </div>
      </Popover.Body>
    </Popover>
  )
  const checkDataForNav = (path: any) => {
    if (!checkCompleteData(
      new Date(
        projectversiondata[data?._id.$oid] ?
          projectversiondata[data?._id.$oid]?.data_date.$date
          : data?.data_date.$date
          || 0),
      projectversiondata[data?._id.$oid] ? projectversiondata[data?._id.$oid] : data)
    ) {
      sweetAlert({
        title: 'Data Missing',

        icon: 'info',
        html: '<p>Data for this date is missing<br> Fill in the missing data to see the forecast',
        confirmButtonText: 'Close',
        showCancelButton: true,
      }).then((res) => {
        if (!res.isConfirmed) return
        //get back to the old date data and put path to navigate to
        setSelectedDataDate(new Date(data?.data_date?.$date || 0))
        setDataDate(new Date(data?.data_date?.$date || 0), false)
        navigate(path)
      })
    }
  }

  const getLastSegment = (path: string) => {
    const segments = path.split('/');
    return segments[segments.length - 1];
  };



  return (
    <>

      <div className='card mb-5 mt-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4'>
                <img
                  src={
                    projectData?.image
                      ? getProjectImageLink(projectData?._id?.$oid || '')
                      : toAbsoluteUrl('/media/logos/default-small.svg')
                  }
                  onError={(e) =>
                  ((e.target as HTMLImageElement).src = toAbsoluteUrl(
                    '/media/logos/default-small.svg'
                  ))
                  }
                  alt='profile'
                  className='mw-100px mw-lg-150px mh-100px mh-lg-150px rounded'
                  style={{ height: '150px', width: '150px' }}
                />
              </div>
            </div>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-nowrap mb-2'>
                <div className='d-flex flex-column' style={{ maxWidth: '50%' }}>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {projectData?.name}
                    </a>
                    <a className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3 cursor-pointer'>
                      {projectData?.status}
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 flex-grow-1'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2 text-wrap'
                      style={{ wordBreak: 'break-word' }}  // Add this style
                    >
                      {projectData?.description}
                    </a>
                  </div>

                </div>

                <div className='d-flex align-items-center gap-3 mt-3 mt-lg-0 flex-shrink-0'>
                  {projectData?.wbs_list && projectData.wbs_list.length > 0 && (
                    <div>
                      <CustomSelect
                        name='perioda'
                        options={projectData.wbs_list.map((item: any, index: number) => ({
                          label: 'Version ' + index,
                          value: item.$oid,
                        }))}
                        value={projectData.wbs_list.map((item: any, index: number) => ({
                          label: 'Version ' + index,
                          value: item.$oid,
                        })).find((item: any) => projectData.current_wbs === item.value)}
                        onChange={(e: any) => {
                          setProject((prev) => (prev ? { ...prev, current_wbs: e.value } : prev))
                        }}
                        padding={true}
                        components={{ Option: CustomWbsOption }}
                      />
                    </div>
                  )}
                  <button type='button' className='btn btn-light' onClick={() => handleshowwbs()}>
                    {projectData ? getLastSegment(projectData.name) : 'Show WBS Tree'}
                  </button>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => setShowWbsNameModal(true)}
                        disabled={isActionPending}
                      >
                        Save New Version
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleAddUser()}>Send Project</Dropdown.Item>
                      <Dropdown.Item onClick={showConfirm}>Delete Project</Dropdown.Item>
                      <Dropdown.Item onClick={() => setShowMove(true)}>Move Project</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleSetShowShareModal()}>Share Project</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <WbsNameModal
                show={showWbsNameModal}
                onHide={() => setShowWbsNameModal(false)}
                onSave={(name) => handleCreateWbs(name)}
              />
              <SendProjectModal
                show={show}
                handleClose={() => setShow(false)}
                projectId={data?.project.$oid || ''}
              />
              <MoveItem
                onHide={() => setShowMove(false)}
                project={projectData}
                refetch={() => { }}
                show={showMove}
                onSuccess={(project) => setProject(project)}
              />

              <ShareProjectModal
                onHide={() => setShowShareModal(false)}
                refetch={() => { }}
                show={showShareModal}
                onSuccess={(project) => setProject(project)}
              />

              <ShowWbsModal
                onHide={() => { dispatch(isntheader()); setShowWbsModal(false) }}
                show={showWbsModal}
              />

              <div className='row'>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-primary mt-5"
                      role="status"
                      style={{ width: '3rem', height: '3rem' }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className=''>
                    <div className='d-flex flex-wrap'>
                      <div className='d-flex flex-column pe-8'>
                        <div className='d-flex flex-wrap'>
                          <DatePicker
                            cellRender={(current: Dayjs, info: any) => {
                              const startDate = dayjs(
                                projectversiondata[data?._id.$oid] ?
                                  projectversiondata[data?._id.$oid]?.start_date.$date
                                  : data?.start_date.$date
                              );

                              const dataDate = dayjs(
                                projectversiondata[data?._id.$oid] ?
                                  projectversiondata[data?._id.$oid]?.data_date.$date
                                  : data?.data_date.$date);

                              if (current.isBefore(startDate)) {
                                return <div className="not-started">{data?.period_count.type === 'monthly' ? 'NS' : 'Not Started'}</div>;
                              }

                              return (
                                <div
                                  className={clsx(
                                    current.isBefore(dataDate) || current.isSame(dataDate) ? 'highlighted' : ''
                                  )}
                                >
                                  {info.originNode}
                                </div>
                              );
                            }}
                            className='invisible w-0 h-0 position-absolute p-0'
                            picker={data?.period_count?.type === 'daily' ? 'date' : 'month'}
                            value={dayjs(selectedDataDate)}
                            onOpenChange={(open) => setDatePicker(open)}
                            open={datePicker}
                            onChange={async (date) => {
                              if (date) {
                                dispatch(updateShow(true));
                                await handleDataDateChange(date)
                              }
                              setDatePicker(false)
                            }}
                          />
                          <div
                            onClick={() => handleDataDate()}
                            className='border border-dashed border-primary border-300 rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer'
                          >
                            <div className='d-flex align-items-center'>
                              <div className='fs-2 fw-bolder text-primary'>
                                {new Date(
                                  projectversiondata[data?._id.$oid] ?
                                    projectversiondata[data?._id.$oid]?.start_date.$date
                                    : data?.start_date.$date
                                    || 0).getTime() <=
                                  selectedDataDate.getTime()
                                  ? getFormattedDate(
                                    selectedDataDate,
                                    data?.period_count?.type as DateUnit,
                                    data?.date_format
                                  )
                                  : 'Not Started'}
                              </div>
                            </div>
                            <div className='fw-bold fs-6 text-gray-400'>Data Date</div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-column'>
                        <div className='d-flex flex-wrap'>
                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='d-flex align-items-center'>
                              <div className='fs-2 fw-bolder'>
                                {dataDateIndex >= 0
                                  ? (dataDateIndex || 0) +
                                  1 +
                                  ' ' +
                                  (convertDateUnite(newVersion?.delay_tolerance?.type || 'monthly') ||
                                    '')
                                  : 'Not started'}
                              </div>
                            </div>

                            <div className='fw-bold fs-6 text-gray-400 text-nowrap'>Actual Duration</div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-column'>
                        <div className='d-flex flex-wrap'>
                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='d-flex align-items-center'>
                              <div className='fs-2 fw-bolder'>
                                {getFormattedDate(
                                  new Date(data?.start_date?.$date || 0),
                                  data?.period_count?.type as DateUnit,
                                  data?.date_format
                                )}
                              </div>
                            </div>

                            <div className='fw-bold fs-6 text-gray-400'>Start Date</div>
                          </div>
                        </div>
                      </div>

                      <div className='d-flex flex-column'>
                        <div className='d-flex flex-wrap'>
                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='d-flex align-items-center'>
                              <div className='fs-2 fw-bolder'>
                                {data && getFormattedDate(realStartDate || new Date(data?.start_date?.$date || 0), data?.period_count?.type as DateUnit, data?.date_format)}
                              </div>
                            </div>
                            <div className='fw-bold fs-6 text-gray-400'>Real Start Date</div>
                          </div>
                        </div>
                      </div>


                      <div className='d-flex flex-column'>
                        <div className='d-flex flex-wrap'>
                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='d-flex align-items-center'>
                              <div className='fs-2 fw-bolder'>
                                {(getDiffrentBettwenDate(
                                  data?.end_date.$date || 0,
                                  data?.start_date.$date || 0,
                                  data?.delay_tolerance?.type || 'monthly'
                                ) || 0) + 1}{' '}
                                {convertDateUnite(data?.delay_tolerance?.type || 'monthly')}
                              </div>
                            </div>

                            <div className='fw-bold fs-6 text-gray-400 text-nowrap'>Planned Duration</div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-column'>
                        <div className='d-flex flex-wrap'>
                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='d-flex align-items-center'>
                              <div className='fs-2 fw-bolder'>
                                {getFormattedDate(
                                  new Date(data?.end_date?.$date || 0),
                                  data?.period_count?.type as DateUnit,
                                  data?.date_format
                                )}
                              </div>
                            </div>

                            <div className='fw-bold fs-6 text-gray-400'>Finish Date</div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-column'>
                        <div className='d-flex flex-wrap'>
                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='d-flex align-items-center'>
                              <div className='fs-2 fw-bolder'>
                                {(data?.delay_tolerance?.count || '0') +
                                  ' ' +
                                  (convertDateUnite(newVersion?.delay_tolerance?.type || 'monthly') ||
                                    '')}
                              </div>
                            </div>

                            <div className='fw-bold fs-6 text-gray-400 text-nowrap'>Delay Tolerance</div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-column'>
                        <div className='d-flex flex-wrap'>
                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='d-flex align-items-center'>
                              <div className='fs-2 fw-bolder text-nowrap'>
                                {getFormattedBudget(
                                  {
                                    ...data?.budget_at_completion,
                                    floatFormater: data?.float_formatter,
                                  } as IBudget,
                                  true
                                )}
                              </div>
                            </div>

                            <div className='fw-bold fs-6 text-gray-400 text-nowrap'>
                              Budget at completion
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-column'>
                        <div className='d-flex flex-wrap'>
                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='d-flex align-items-center'>
                              <div className='fs-2 fw-bolder text-nowrap'>
                                {getFormattedBudget(
                                  {
                                    ...data?.budget_at_completion,
                                    amount: data?.reserve?.amount,
                                    floatFormater: data?.float_formatter,
                                  } as IBudget,
                                  true
                                )}
                              </div>
                            </div>

                            <div className='fw-bold fs-6 text-gray-400'>Reserve</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>)}
              </div>
            </div>
          </div>

          {/* ! TODO: UNCOMMENT THIS */}
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/project/${data?.project.$oid}/overview` && 'active')
                  }
                  to={(checkCompleteData(new Date(newVersion?.data_date.$date || 0), data)) ? `/project/${data?.project.$oid}/overview` : '#'}
                  state={data?.output}
                  onClick={() => checkDataForNav(`/project/${data?.project.$oid}/overview`)}
                >
                  Overview
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/project/${data?.project.$oid}/data` && 'active')
                  }
                  to={`/project/${data?.project.$oid}/data`}
                  state={data?.output}
                >
                  Data
                  {!(checkCompleteData(new Date(newVersion?.data_date.$date || 0), data)) &&
                    <span className='ms-2 bullet bullet-dot bg-primary h-6px w-6px animation-blink' />

                  }
                </Link>
              </li>
              <li className='nav-item'>
                {(latestOpenWbs && latestOpenWbs.subwbs && latestOpenWbs.subwbs.length === 0) ?
                  (
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/project/${data?.project.$oid}/rebase_line` && 'active')
                      }
                      to={(checkCompleteData(new Date(newVersion?.data_date.$date || 0), data)) ? `/project/${data?.project.$oid}/rebase_line` : '#'}
                      state={data?.output}
                      onClick={() => checkDataForNav(`/project/${data?.project.$oid}/rebase_line`)}
                    >
                      Rebase Line
                    </Link>
                  ) : (
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/project/${data?.project.$oid}/rebase_line` && 'active')
                      }
                      to={(checkCompleteData(new Date(newVersion?.data_date.$date || 0), data)) ? `/project/${data?.project.$oid}/rebase_line` : '#'}
                      state={data?.output}
                      onClick={() => checkDataForNav(`/project/${data?.project.$oid}/rebase_line`)}
                    >
                      Rebase Line History
                    </Link>
                  )
                }


              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/project/${data?.project.$oid}/budget` && 'active')
                  }
                  to={(checkCompleteData(new Date(newVersion?.data_date.$date || 0), data)) ? `/project/${data?.project.$oid}/budget` : '#'}
                  onClick={() => checkDataForNav(`/project/${data?.project.$oid}/budget`)}
                >
                  Budget
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/project/${data?.project.$oid}/comments` && 'active')
                  }
                  to={(checkCompleteData(new Date(newVersion?.data_date.$date || 0), data)) ? `/project/${data?.project.$oid}/comments` : '#'}
                  state={data}
                  onClick={() => checkDataForNav(`/project/${data?.project.$oid}/comments`)}
                >
                  Comments
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/project/${data?.project.$oid}/action-plan` && 'active')
                  }
                  to={(checkCompleteData(new Date(newVersion?.data_date.$date || 0), data)) ? `/project/${data?.project.$oid}/action-plan` : '#'}
                  state={data}
                  onClick={() => checkDataForNav(`/project/${data?.project.$oid}/action-plan`)}
                >
                  Action Plan
                </Link>
              </li>
              <OverlayTrigger show={showOverlay} overlay={popover}>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === `/project/${data?.project.$oid}/milestones` &&
                        'active')
                    }
                    to={(checkCompleteData(new Date(newVersion?.data_date.$date || 0), data)) ? `/project/${data?.project.$oid}/milestones` : '#'}
                    onClick={() => checkDataForNav(`/project/${data?.project.$oid}/milestones`)}
                  >
                    Milestones
                    {!newVersion?.milestones.every((item) =>
                      isMilestoneValid(item, new Date(newVersion.data_date.$date))
                    ) &&
                      !showOverlay && (
                        <span className='ms-2 bullet bullet-dot bg-primary h-6px w-6px animation-blink' />
                      )}
                  </Link>
                </li>
              </OverlayTrigger>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/project/${data?.project.$oid}/export_data` && 'active')
                  }
                  to={(checkCompleteData(new Date(newVersion?.data_date.$date || 0), data)) ? `/project/${data?.project.$oid}/export_data` : '#'}
                  state={data?.output}
                  onClick={() => checkDataForNav(`/project/${data?.project.$oid}/export_data`)}
                >
                  Export Data
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/project/${data?.project.$oid}/test_wbs` && 'active')
                  }
                  to={`/project/${data?.project.$oid}/test_wbs`}
                  state={data?.output}
                  onClick={() => checkDataForNav(`/project/${data?.project.$oid}/test_wbs`)}
                >
                  Test Wbs Calculating
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/project/${data?.project.$oid}/settings` && 'active')
                  }
                  to={(checkCompleteData(new Date(newVersion?.data_date.$date || 0), data)) ? `/project/${data?.project.$oid}/settings` : '#'}
                  onClick={() => checkDataForNav(`/project/${data?.project.$oid}/settings`)}
                >
                  Settings
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname.startsWith(`/project/${data?.project.$oid}/wbs`) && 'active')
                  }
                  to={(checkCompleteData(new Date(newVersion?.data_date.$date || 0), data)) ? `/project/${data?.project.$oid}/wbs` : '#'}
                  onClick={() => checkDataForNav(`/project/${data?.project.$oid}/wbs`)}
                >
                  WBS
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export { ProjectDetailsHeader }
