/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useTeam } from '../../modules/apps/user-management/_queries'
import {
  getCurrentLimit,
  getCurrentUsed,
} from '../../modules/apps/user-management/users-list/SlotHelpers'
import { useAuth } from '../../modules/auth'
import {
  getProjectCount,
  getSimulationCount,
  getUsedProjects,
  getUsedSimulations,
} from '../../modules/profile/components/billing/billingHelpers'
import { useProject } from '../../modules/projects/core/ProjectContext'
import { useProjectDetailsById, useProjectsData } from '../../modules/projects/core/_queries'
import { useSimulationsData } from '../../modules/simulations/core/_queries'
import { ActiveCount } from './ActiveCount'
import CPIGauge from './CPIGauge'
import Chart2 from './Chart2'
import Latest from './Latest'
import NearestActions from './NearestActions'
import { Overview } from './Overview'
import SummaryChart from './SummaryChart'
import { TeamCount } from './TeamCount'
import TestCostChart from './TestCostChart'
import TestScheduleChart from './TestScheduleChart'
import EmptyValuesCard from '../../components/emptyValuesCard'
import { useNavigate } from 'react-router-dom'
import { getGlobalDashboard, getProjectById } from '../../modules/projects/core/_requests'
import { TreeProvider } from '../../modules/wbs/components/TreeView/DashboardChartTreeContext'
import ShowLoadingModal from '../../modules/projects/components/pagination/header/ShowLoadingModal'
import { useDispatch } from 'react-redux'
import { updateBarsthickness, updateColors } from '../../modules/wbs/dashboardData'

const DashboardWrapper: FC = () => {
  const { originalVersion: data, setProject, setDataDate, setDataDateAsync, setOriginalVersion, displayVersion, project } = useProject()
  const [versionId, setVersionId] = useState<string>()
  const [selectedDataDate, setSelectedDataDate] = useState<Date>(
    new Date(data?.data_date?.$date || 0)
  )
  const realValue = displayVersion?.output?.cpi?.cumulative?.at(-1)
  const dispatch = useDispatch()
  const gaugeValue = realValue > 2 ? 2 : realValue < 0 ? 0 : realValue

  const [isFirstDataDate, setIsFirstDataDate] = useState<boolean>(true)
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [page, setpage] = useState(1)
  const navigate = useNavigate()
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [perPage, setPerPage] = useState(9)
  const moveHereRef = useRef<HTMLDivElement>(null);
  
  const { data: projectData } = useProjectsData({
    page,
    perPage,
    filter: '',
    featured: true,
  })

  const [id, setId] = useState<any>({
    id: '',
    name: '',
    data_date: data?.data_date.$date
  })

  useEffect(() => {
    if (projectData) {
      setId({
        id: projectData?.items[0]?._id.$oid,
        name: projectData?.items[0]?.name,
      })

      getGlobalDashboard().then((dashboard) =>
      {
        dispatch(updateColors(dashboard.dashboard_colors))
        dispatch(updateBarsthickness(dashboard.bars_thickness))
      }
      )

    }
  }, [projectData])

  let { isLoading, error } = useProjectDetailsById(
    id.id,
    (newData) => {
      // Your code will execute only if id is available
      // find the period where the default version is present
      setProject(newData)
      setOriginalVersion(newData.default_version)
      setDataDate(selectedDataDate, true)
      if (isFirstDataDate) {
        setSelectedDataDate(new Date(newData.default_version.data_date.$date))
        setIsFirstDataDate(false)
      }
      if (!versionId) setVersionId(newData.default_version._id.$oid)
    },
    undefined,
    (err) => console.log('Dashboard error: ' + err)
  )

  useEffect(() => {
    getProjectById(
      id.id).then(
      (newData: any) => {
        setProject(newData)
        setOriginalVersion(newData.default_version)
      }
    )
  }, [id])

  useEffect(() => {
    const updateDataDate = async () => {
      await setDataDateAsync(selectedDataDate, true)
    };

    updateDataDate()
  }, [project])

  const intl = useIntl()
  const { currentUser } = useAuth()

  const { data: allProjects, isLoading: allLoading } = useProjectsData(
    {
      page,
      perPage: 60,
      filter: '',
      featured: false,
    },
    'always'
  )
  const { data: simulationData } = useSimulationsData({ page, perPage, filter: '' })
  const { data: teamData } = useTeam(() => { },
    currentUser?.role === 'team_member' || currentUser?.role === 'team_owner')

  return (


    <TreeProvider  projectsData={projectData?.items || []}>

    <ShowLoadingModal/>
    <div className='container-fluid gx-0'>
     

      <div
        className='row  gx-0 d-flex flex-row flex-nowrap gap-3 p-3 ps-5 pe-5'
        style={{
          backgroundColor: 'var(--kt-card-bg)',
          border: ' 1px solid var(--kt-card-border-color)',
        }}
      >
        <div className='h-25px w-25px'>
          <img
            style={{
              top: '6px',
              position: 'relative',
            }}
            src={toAbsoluteUrl('/media/svg/card-logos/dashboard.svg')}
            alt='Dashboard logo'
          />
        </div>
        <div>
          <div
            className='fw-bold'
            style={{
              fontSize: '22px',
            }}
          >
            {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          </div>
          <div>{intl.formatMessage({ id: 'DASHBOARD.DESCRIPTION' })}</div>
        </div>
        {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <PageBreadCrumbs /> */}
      </div>
      <div
        style={{
          backgroundColor: 'var(--kt-card-bg)',
          borderBottom: '1px solid #1C2355',
          display: 'flex',
          padding: '0px 45px',
          alignItems: 'flex-start',
          gap: '10px',
          alignSelf: 'stretch',
        }}
      >
        <ul
          style={{
            backgroundColor: 'var(--kt-card-bg)',
            padding: '12px 15px',
          }}
          className='nav nav-tabs nav-line-tabs  nav-stretch fs-6 border-0'
        >
          <li className='nav-item' style={{ height: '46px' }}>
            <a
              className='nav-link active d-flex align-items-center'
              style={{
                padding: '12px 15px',
                gap: '10px',
              }}
              data-bs-toggle='tab'
              href='#projects'
            >
              <div>
                <img
                  className='w-24px h-24px'
                  src={toAbsoluteUrl('/media/svg/card-logos/project.svg')}
                  alt='Dashboard logo'
                />
              </div>
              <div>Projects</div>
            </a>
          </li>
          <li className='nav-item' style={{ height: '46px' }}>
            <a
              className='nav-link d-flex align-items-center'
              style={{
                padding: '12px 15px',
                gap: '10px',
              }}
              data-bs-toggle='tab'
              href='#teams'
            >
              <div>
                <img
                  className='w-24px h-24px'
                  src={toAbsoluteUrl('/media/svg/card-logos/teams.svg')}
                  alt='Dashboard logo'
                />
              </div>
              <div>Accounts & Teams</div>
            </a>
          </li>
        </ul>
      </div>
      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade active show' id='projects' role='tabpanel'>
          <div className='container-fluid' style={{ padding: '24px 43px' }}>
            <div className='row g-3'>
              <div className='col-12'>
                {!!projectData?.items.length && (
                  <div className='card'>
                    {data?.data_date?.$date && (

                     <Chart2
                        changeProject={(id: string, name: string, data_date: Date) => {
                          setId({
                            id: id,
                            name: name,
                            data_date: data_date
                          })
                          if (moveHereRef.current) {
                            const yOffset = window.innerWidth >= 992 ? -70 : 0; // Adjusts for header height on larger screens
                            const yPosition = moveHereRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: yPosition, behavior: 'smooth' });
                          }
                        }}
                        projects={projectData?.items || []}
                        selectedId={id}
                        selectedDataDate={selectedDataDate}
                        setSelectedDataDate={setSelectedDataDate}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            {(isLoading || allLoading || !displayVersion) && !error ? (
              <div className='d-flex flex-row justify-content-center'>
                <div
                  className='spinner-border text-primary'
                  style={{ width: '3rem', height: '3rem' }}
                  role='status'
                >
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            ) : error ? (
              <EmptyValuesCard
                buttonText='Select favourite Projects'
                setShowCreateAppModal={(value: any) => value && navigate('/projects')}
                text={'Go Select Your favourite Projects'}
                title='Welcome to Project Curve'
              />
            ) : (
              <div ref={moveHereRef} >
                <div className='row mt-5'>
                  <div className='col-sm-12 col-lg-6 mb-3'>
                    <Overview
                      className='h-100 g-1'
                      description='Team members'
                      icon={true}
                      labelColor='green'
                      textColor='red'
                      dataDate={selectedDataDate}
                    />
                  </div>
                  <div className='col-sm-12 col-lg-3 mb-3'>
                    <div className='card h-100 w-100'>
                      <CPIGauge type='SPI' />
                    </div>
                  </div>
                  <div className='col-sm-12 col-lg-3 mb-3'>
                    <div className='card h-100 w-100'>
                      <TestScheduleChart isLoading={isLoading} />
                    </div>
                  </div>
                </div>

                <div className='row mt-5'>
                  <div className='col-sm-12 col-lg-6 mb-3'>
                    <SummaryChart />
                  </div>
                  <div className='col-sm-12 col-lg-3 mb-3'>
                    <div className='card h-100 w-100'>
                      <CPIGauge type='CPI' />
                    </div>
                  </div>
                  <div className='col-sm-12 col-lg-3 mb-3'>
                    <div className='card h-100 w-100'>
                      <TestCostChart />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
         className='tab-pane fade'
          id='teams' role='tabpanel'>
          <div className='container-fluid'
          style={{ padding: '24px 43px' }}>
            <div className='row g-3'>
              <div className='col-xl-6 h-100'>
                <Latest
                  className='mb-5'
                  projectData={allProjects}
                  simulationData={simulationData}
                />
              </div>
              <div className='col-xl-6 h-100'>
                {currentUser?.role === 'team_owner' && (
                  <TeamCount
                    className='row h-50 g-1 mb-5'
                    description='Team members'
                    icon={true}
                    labelColor='green'
                    textColor='red'
                  />
                )}
                <div className='row g-3 h-50'>
                  <div className='col-xl-6 h-100'>
                    <ActiveCount
                      className='h-200px'
                      img=''
                      description={
                        currentUser?.role === 'team_owner'
                          ? 'Team Projects'
                          : 'Active Projects'
                      }
                      color='rgb(241, 65, 108)'
                      used={
                        currentUser?.role === 'team_member'
                          ? getCurrentUsed(
                            'slot-project',
                            currentUser?._id.$oid || '',
                            teamData
                          )
                          : getUsedProjects(currentUser?.current_subscription, currentUser)
                      }
                      total={
                        currentUser?.role === 'team_member'
                          ? getCurrentLimit(
                            'slot-project',
                            currentUser?._id.$oid || '',
                            teamData
                          )
                          : getProjectCount(currentUser?.current_subscription)
                      }
                    />
                  </div>
                  <div className='col-xl-6'>
                    <ActiveCount
                      className='h-200px'
                      img=''
                      description={
                        currentUser?.role === 'team_owner'
                          ? 'Team Simulations'
                          : 'Active Simulations'
                      }
                      color='#6600F5'
                      used={
                        currentUser?.role === 'team_member'
                          ? getCurrentUsed(
                            'slot-simulation',
                            currentUser?._id.$oid || '',
                            teamData
                          )
                          : getUsedSimulations(currentUser?.current_subscription)
                      }
                      total={
                        currentUser?.role === 'team_member'
                          ? getCurrentLimit(
                            'slot-simulation',
                            currentUser?._id.$oid || '',
                            teamData
                          )
                          : getSimulationCount(currentUser?.current_subscription)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <NearestActions projects={projectData?.items || []} />
          </div>
        </div>
      </div>
    </div>
    </TreeProvider>
  )
}

export { DashboardWrapper }
