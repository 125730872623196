import { useState } from 'react'
import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import { getFormattedBudget, IBudget, sweetAlert } from '../../../../utils/funcs'
import { getPeriodicFromCumulative } from './prepareDataTable'
import { ProjectObject } from '../../core/_models'
import { patchVersion } from '../../core/_requests'
import { useProjectVersionById } from '../../core/_queries'
import { getProjectData } from '../../../../utils/project-data'
import * as Yup from 'yup'
import { greaterThanOrEqual } from '../../core/ProjectHelpers'
import { useProject } from '../../core/ProjectContext'
import { useDispatch } from 'react-redux'
import { updateLabel, updateLoaded, updateShow, updateTotal } from '../../../wbs/treeLoader'
import { combineVersionsV2 } from '../../../wbs/components/TreeView/_helpers'
import { setVersionData } from '../../../wbs/treeVersionData'
import { updateIsWpChanged, updateWpChangedId } from '../../../wbs/treedata'

interface Props {
  data: any
  labels: string[]
  dataDateIndex: number
  isEditable?: boolean;
  wpId?: string
}
export default function EarnedValueTable({ data, labels, dataDateIndex, isEditable = true, wpId }: Props) {
  const {
    setWbsDataDateFirstStepAsync,
    setWbsDataDateSecondStepAsync,
    setOriginalVersion,
    setVersion,
    setLoading: setProjectLoading,
    project,
    originalVersion
  } = useProject()
  const { refetch } = useProjectVersionById(data._id.$oid, (version) => { }, false)
  const [evEdit, setEvEdit] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      evCumulative:
        data?.output?.earnedValues?.mixed.cumulative ||
        (data as ProjectObject)?.custom_curve
          ?.find((item) => item.name === 'cumulativeEarnedValue')
          ?.values?.filter((value) => !isNaN(value) && value !== '') ||
        [],
    },
    validationSchema: Yup.object().shape({
      evCumulative: Yup.array()
        .test('number', 'Earned Values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('evTest', 'Earned values must be cumulative', (value, context) => {
          return value?.slice?.(0, dataDateIndex + 1).every(greaterThanOrEqual) || false
        }),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!formik.dirty) return setEvEdit(false)
      setLoading(true)
      setTimeout(async () => {
        try {
          const newCurve = JSON.parse(JSON.stringify(data?.custom_curve));
          newCurve[1].values = values.evCumulative
            ?.map((value: number) => +value)
            .slice(0, dataDateIndex + 1)
          dispatch(updateShow(true));
          setLoading(true)
          setProjectLoading(true)
          dispatch(updateLabel('Calculating Data'));
          dispatch(updateTotal(1));
          dispatch(updateLoaded(0));

          const calculatedVersionData = await combineVersionsV2(
            data?.name ? data?.name : 'WBS',
            [{ ...data, custom_curve: newCurve }],
            true,
            setWbsDataDateFirstStepAsync,
            setWbsDataDateSecondStepAsync,
          );
          setProjectLoading(false)
          setLoading(false)
          dispatch(updateShow(false));

          patchVersion(
            {
              custom_curve: newCurve,
              output: calculatedVersionData ? calculatedVersionData.output : data.output,
            },
            data._id?.$oid
          )
            .then((res) => {
              dispatch(setVersionData({ id: res._id.$oid, data: res }));
              if (originalVersion?._id.$oid === res._id.$oid) {
                setOriginalVersion(res)
                setVersion(res)
              }
              sweetAlert({
                title: 'Success',
                text: 'Earned Value has been updated successfully',
                icon: 'success',
              }).then(() => {
                if (wpId) {
                  dispatch(updateWpChangedId(wpId))
                  dispatch(updateIsWpChanged(true));
                }
              })
              refetch()
              setEvEdit(false)
              formik.resetForm()
            })
            .catch((err) => {
              sweetAlert({
                title: 'Error',
                text: err.message || 'Earned Value has not been updated',
                icon: 'error',
              })
            })
        } catch (error: any) {
          sweetAlert({
            title: 'Error',
            text: error.message || 'Earned Value has not been updated',
            icon: 'error',
          })
        } finally {
          setLoading(false)
        }
      }, 0)
    },
  })

  const handleCancel = () => {
    formik.resetForm();
    setEvEdit(false);
  };

  if (!formik.values.evCumulative.length) return null
  return (
    <FormikProvider value={formik}>
      <div className='d-flex flex-wrap flex-stack align-items-end'>
        <h3 className=''>Earned value</h3>
        <div>
          {isEditable && (
            <button
              onClick={() => {
                if (evEdit) {
                  formik.submitForm()
                } else setEvEdit((prev) => !prev)
              }}
              type='button'
              disabled={(evEdit && (!formik.isValid || !formik.dirty)) || loading}
              className=' btn btn-sm btn-primary'
            >
              {loading ? (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  <span className='spinner-border spinner-border-sm align-middle'></span>
                </span>
              ) : evEdit ? (
                'Save'
              ) : (
                'Edit'
              )}
            </button>
          )}
          {evEdit && (
            <button
              onClick={handleCancel}
              type='button'
              className='btn btn-sm btn-secondary ms-2'
            >
              Cancel
            </button>
          )}
        </div>
      </div>
      <div className='card card-xxl-stretch mb-5 mb-xl-10'>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-150px'></th>
                  {formik.values.evCumulative.map((item: any, index: number) => (
                    <th
                      key={index}
                      className='min-w-120px'
                      style={{ maxWidth: '13ch', minWidth: '13ch' }}
                    >
                      {labels[index]}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a className='text-primary fw-bolder text-hover-primary fs-6'>Cumulative</a>
                  </td>
                  <FieldArray
                    name='evCumulative'
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.evCumulative &&
                          formik.values.evCumulative.map((value: any, index: number) => (
                            <td className='position-relative' key={index}>
                              {evEdit && isEditable ? (
                                <Field
                                  disabled={index > dataDateIndex}
                                  className={`text-dark fw-bold d-block fs-7 text-danger ${index <= dataDateIndex ? 'border-bottom border-primary' : null
                                    }`}
                                  name={`evCumulative.${index}`}
                                />
                              ) : (
                                <span
                                  className={`fw-bold d-block fs-7 ${index <= dataDateIndex ? 'text-dark' : 'text-muted'
                                    }`}
                                >
                                  {getFormattedBudget({
                                    ...data?.budget_at_completion,
                                    amount: value,
                                    floatFormater: data.float_formatter,
                                  } as IBudget)}
                                </span>
                              )}
                            </td>
                          ))}
                      </>
                    )}
                  />
                </tr>
                <tr>
                  <td>
                    <a className='text-primary fw-bolder text-hover-primary fs-6'>Periodic</a>
                  </td>
                  {data?.output?.earnedValues?.mixed.periodic
                    ? data?.output?.earnedValues?.mixed.periodic.map((item: any, index: number) => (
                      <td key={index}>
                        <input
                          className='text-dark fw-bold d-block fs-7'
                          value={getFormattedBudget({
                            ...data?.budget_at_completion,
                            amount: item,
                            floatFormater: data.float_formatter,
                          } as IBudget)}
                          disabled
                        />
                      </td>
                    ))
                    : getPeriodicFromCumulative(
                      (data as ProjectObject)?.custom_curve
                        ?.find((item) => item.name === 'cumulativeEarnedValue')
                        ?.values?.filter((value) => !isNaN(value) && value !== '')
                    ).map((item: any, index: number) => (
                      <td key={index}>
                        <input
                          className='text-dark fw-bold d-block fs-7'
                          value={getFormattedBudget({
                            ...data?.budget_at_completion,
                            amount: item,
                            floatFormater: data.float_formatter,
                          } as IBudget)}
                          disabled
                        />
                      </td>
                    ))}
                </tr>
              </tbody>
            </table>
          </div>
          {formik.errors.evCumulative && (
            <h4 className='text-danger'>{formik.errors.evCumulative.toString()}</h4>
          )}
          {!data?.output?.earnedValues?.mixed.cumulative && (
            <div className='d-flex mt-2 align-items-center'>
              <i className='bi bi-info-circle fs-5'></i>
              <p className='fw-7 ms-2 text-muted mb-0'>Forecast Data Unavailable</p>
            </div>
          )}
        </div>
      </div>
    </FormikProvider>
  )
}
