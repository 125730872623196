/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react'
import type { FC } from 'react'
import ApexCharts from 'apexcharts'
import Toast from 'react-bootstrap/Toast';
import { getCSS, getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { getFormattedDate, getLabels, getMaxArrayLength } from '../../../../helpers/DateFormatter'
import { getBAC, getBiggestChartValue, getCustomCurve } from './Project'
import { getPeriodicFromCumulative } from '../data/prepareDataTable'
import { getFormattedBudget, getFormattedUnitAndCurrency } from '../../../../utils/funcs'
import moment from 'moment'
import MilestonesChart from './milestones/MilestonesChart'
import ForecastSettingsModal from './modals/ForecastSettingsModal'
import { getSCurvesColors } from './_helpers'
import { KTSVG } from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import { Collapse } from 'react-bootstrap'
import CustomLegends from './CustomLegends'
import { labels, legendLabels } from './_models'
import { useLang } from '../../../../../_metronic/i18n/Metronici18n'
import useFormatter from '../../../../../hooks/useFormatter'

interface IChartWithAnnotations {
  projectData: any
  data?: any
  hideBudgetReserve?: boolean
  isRebaseLined?: boolean
  rebaseLineIndex?: any[]
  isShared?: boolean;
  isWp?: boolean;
}
type DateFormatType = 'mm/dd/yyyy' | 'dd/mm/yyyy'

const ChartWithAnnotations: FC<IChartWithAnnotations> = ({ projectData, data, isShared, isWp = true, isRebaseLined, rebaseLineIndex }) => {

  const [chart, setChart] = useState<ApexCharts | undefined>(undefined)
  const [showBudgetReserve, setShowBudgetReserve] = useState<boolean>(true)
  const [showDelayTolerance, setShowDelayTolerance] = useState<boolean>(true)
  const [showRebaseline, setShowRebaseline] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false)
  const [enableToast, setEnableToast] = useState<boolean>(false);
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [legends, setLegends] = useState<boolean[]>(
    Array(12)
      .fill(true)
      .map((item, index) => (index === 1 ? false : true))
  )
  const [expanded, setExpanded] = useState(true)
  const { mode } = useThemeMode()
  const lang = useLang()
  const formatter = useFormatter()
  //max length of multiple arrays
  const getChartOptions = (_height: number) => {
    const labelColor = getCSSVariableValue('--kt-gray-500')
    const baseColor = getCSSVariableValue('--kt-primary')
    const secondaryColor = getCSSVariableValue('--kt-warning')
    const thirdColor = getCSSVariableValue('--kt-success')
    const darkColor = getCSSVariableValue('--kt-dark')

    return {
      stroke: {
        curve: 'straight',
        width: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
        dashArray: [0, 0, 0, 15, 0, 0, 0, 15, 0, 0, 3],
        show: true,
      },

      plotOptions: {
        area: {
          fillTo: 'end',
        },
        bar: {
          columnWidth: '60%',
        },
      },
      fill: {
        type: [
          'solid',
          'solid',
          'solid',
          'solid',
          'solid',
          'pattern',
          'solid',
          'solid',
          'solid',
          'pattern',
          'solid',
        ],
        pattern: {
          style: 'slantedLines',
        },
      },

      series: [
        {
          name: 'PV(cum)',
          type: 'line',
          data: projectData?.plannedValues
            ? projectData?.plannedValues?.original?.cumulative
            : getCustomCurve('cumulativePlannedValue', data),
        },
        {
          name: 'PV(m)',
          type: 'column',
          data: (projectData?.plannedValues
            ? [
              ...projectData?.plannedValues?.original?.periodic,
              ...new Array(data?.delay_tolerance?.count).fill(null),
            ]
            : [
              ...getPeriodicFromCumulative(getCustomCurve('cumulativePlannedValue', data)),
              ...new Array(data?.delay_tolerance?.count).fill(null),
            ]).map((item, index) => {
              if (isRebaseLined) {
                if (rebaseLineIndex?.includes(index)) {
                  return 0
                } else return item
              } else return item
            }),
        },
        {
          name: 'EV(cum)',
          type: 'line',
          data:
            projectData?.earnedValues?.original?.cumulative ||
            getCustomCurve('cumulativeEarnedValue', data),
        },
        {
          name: 'EV(forecast)',
          type: 'line',
          data: projectData?.earnedValues?.forecast?.cumulative || [],
        },
        {
          name: 'EV(m)',
          type: 'column',
          data:
            projectData?.earnedValues?.original?.periodic ||
            getPeriodicFromCumulative(getCustomCurve('cumulativeEarnedValue', data)),
          yaxis: 2,
        },
        {
          name: 'EV(m forecast)',
          type: 'column',
          data:
            projectData?.earnedValues?.forecast?.periodic?.map((item: any, index: any) =>
              index === data?.output?.nbOfTimeUnitPassed ? null : item
            ) || [],
          yaxis: 2,
        },
        {
          name: 'AC(cum)',
          type: 'line',
          data:
            projectData?.actualCosts?.original?.cumulative ||
            getCustomCurve('cumulativeActualCost', data),
        },
        {
          name: 'AC(forcast)',
          type: 'line',
          data: projectData?.actualCosts?.forecast?.cumulative || [],
        },
        {
          name: 'AC(m)',
          type: 'column',
          data:
            projectData?.actualCosts?.original?.periodic ||
            getPeriodicFromCumulative(getCustomCurve('cumulativeActualCost', data)),
          yaxis: 2,
        },

        {
          name: 'AC(forecast m)',
          type: 'column',
          data:
            projectData?.actualCosts?.forecast?.periodic?.map((item: any, index: any) =>
              index === data?.output?.nbOfTimeUnitPassed ? null : item
            ) || [],
        },
        {
          name: 'BAC',
          type: 'line',
          data: getBAC(data?.start_date.$date, new Date(data?.output?.estimatedEndDate) > new Date(data?.end_date?.$date) ? data?.output?.estimatedEndDate : data?.end_date?.$date, data?.budget_at_completion?.amount, data),
        },
        {
          name: 'Latest PV',
          type: 'line',
          data: getCustomCurve('worstCaseBaseline', data) || [],
        },
      ],
      colors: [
        ...(data.colors?.s_cruve_colors && data.colors?.s_cruve_colors?.length
          ? getSCurvesColors(data.colors?.s_cruve_colors)
          : getSCurvesColors([baseColor, secondaryColor, thirdColor])),
        getCSSVariableValue('--kt-danger'),
      ],
      chart: {
        id: 'mixed',
        height: 650,
        // group: 'overview',
        animations: {
          enabled: false,
        },

        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: -20,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {},
          autoSelected: 'zoom',
        },
        zoom: {
          enabled: true,
          type: 'x',

          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
      },
      tooltip: {
        enabled: true,

        shared: false,
        intersect: false,
        followCursor: false,
        marker: {
          show: true,
        },
      },
      annotations: {
        position: 'back',
        yaxis: data?.rebase_line?.length > 0 ? [] : [
          {
            y: data?.budget_at_completion?.amount,
            borderColor: 'red',
            label: {
              borderColor: 'red',
              text: getFormattedBudget(data.budget_at_completion),
              style: { background: 'red', color: '#fff' },
              position: 'left',
              offsetX: 20,
            },
            borderWidth: 2,
            strokeDashArray: 0,
          },
        ],
        xaxis:
          [
            {
              x: formatter(data.data_date.$date),
              strokeDashArray: 0,
              borderColor: '#775DD0',
              label: {
                borderColor: '#775DD0',
                orientation: 'horizontal',
                style: {
                  color: '#fff',
                  background: 'rgba(119, 93, 208, 0.5)',
                  cssClass: 'border-0',
                },
                //format Data date using date unit
                text:
                  'Data Date ' +
                  getFormattedDate(
                    data?.data_date?.$date,
                    data?.period_count?.type,
                    data?.date_format
                  ),
              },
            },
            ...[isRebaseLined ? data?.rebase_line?.map((result: any, index: number) => (
              {
                x: formatter(result?.data_date),
                strokeDashArray: 0,
                height: 3,
                width: "20px",
                borderColor: '#F00',
                label: {
                  borderColor: '#F00',
                  orientation: 'vertical',
                  style: {
                    color: '#fff',
                    background: '#F00',
                    cssClass: 'border-0 mt-3 pt-3',
                  },
                  text:
                    `RBL ${index}`
                },
              })) : {}]]
      },
      dataLabels: {
        enabled: false,
      },

      grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },

        padding: {
          top: 0,
          left: 50,
          right: 50,
        },
      },
      labels: getLabels(
        data.start_date.$date,
        5 +
        getMaxArrayLength(
          projectData?.plannedValues?.original?.cumulative,
          projectData?.earnedValues?.mixed?.cumulative,
          projectData?.actualCosts?.mixed?.cumulative,
          getCustomCurve('cumulativePlannedValue', data)
        ),
        data?.delay_tolerance.type === 'monthly'
          ? 'month'
          : data?.delay_tolerance.type === 'monthly'
            ? 'month'
            : 'day',
        data?.date_format as DateFormatType,
        lang
      ),
      yaxis: [
        {
          seriesName: 'EV(forecast)',
          decimalsInFloat: data.float_formatter,
          max: getBiggestChartValue(
            projectData,
            getCustomCurve('cumulativePlannedValue', data),
            data?.reserve.amount
          ),
          showAlways: true,
          labels: {
            minWidth: 40,
            style: {
              colors: getCSSVariableValue('--kt-dark'),
              fontSize: '12px',
            },
          },
          title: {
            style: { color: getCSSVariableValue('--kt-dark') },
            text: 'Cumulative in ' + getFormattedUnitAndCurrency(data?.budget_at_completion),
          },
        },
        {
          seriesName: 'EV(m)',
          decimalsInFloat: data.float_formatter,
          showAlways: true,
          opposite: true,
          labels: {
            show: true,
            minWidth: 40,
            style: {
              colors: getCSSVariableValue('--kt-dark'),
              fontSize: '12px',
            },
          },
          title: {
            style: { color: getCSSVariableValue('--kt-dark') },
            text: 'Periodic in ' + getFormattedUnitAndCurrency(data?.budget_at_completion),
          },
        },
        {
          seriesName: 'EV(forecast)',
          decimalsInFloat: data.float_formatter,
          max: getBiggestChartValue(projectData, getCustomCurve('cumulativePlannedValue', data)),
          labels: {
            show: false,
          },
        },
        {
          seriesName: 'EV(forecast)',
          max: getBiggestChartValue(projectData, getCustomCurve('cumulativePlannedValue', data)),
          decimalsInFloat: data.float_formatter,
          labels: {
            show: false,
          },
        },
        {
          seriesName: 'EV(m)',
          decimalsInFloat: data.float_formatter,
          opposite: true,
          labels: {
            show: false,
          },
        },
        {
          seriesName: 'EV(m)',
          decimalsInFloat: data.float_formatter,
          opposite: true,
          labels: {
            show: false,
          },
        },
        {
          seriesName: 'EV(forecast)',
          decimalsInFloat: data.float_formatter,
          max: getBiggestChartValue(projectData, getCustomCurve('cumulativePlannedValue', data)),
          labels: {
            show: false,
          },
        },
        {
          seriesName: 'EV(forecast)',
          decimalsInFloat: data.float_formatter,
          max: getBiggestChartValue(projectData, getCustomCurve('cumulativePlannedValue', data)),
          labels: {
            show: false,
          },
        },
        {
          seriesName: 'EV(m)',
          decimalsInFloat: data.float_formatter,
          opposite: true,
          labels: {
            show: false,
          },
        },
        {
          seriesName: 'EV(m)',
          decimalsInFloat: data.float_formatter,
          labels: {
            show: false,
          },
        },
        {
          seriesName: 'EV(forecast)',
          max: getBiggestChartValue(
            projectData,
            getCustomCurve('cumulativePlannedValue', data),
            data?.reserve.amount
          ),
          decimalsInFloat: data.float_formatter,
          labels: {
            show: false,
          },
        },
        {
          seriesName: 'EV(forecast)',
          max: getBiggestChartValue(
            projectData,
            getCustomCurve('cumulativePlannedValue', data),
            data?.reserve.amount
          ),
          decimalsInFloat: data.float_formatter,
          labels: {
            show: false,
          },
        },
      ],
      xaxis: {
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: darkColor,
            fontSize: '12px',
          },
          rotate: -45,
          rotateAlways: true,
        },
      },
      legend: {
        show: false,
      },
      markers: {
        size: [4],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: '450px',
              width: '450px',
            },
            grid: {
              margin: {
                left: -111,
              },
            },
          },
        },
      ],
    }
  }

  const toggleDelayAnnotation = () => {
    setShowDelayTolerance((prev) => {
      if (prev) {
        chart?.removeAnnotation('delay-tolerance')
        return false
      } else {
        chart?.addXaxisAnnotation({
          id: 'delay-tolerance',
          x: moment(projectData?.delayTolerance?.startDateLabel).format('MMM YY') ||
            moment(data?.end_date.$date).format(
              data?.delay_tolerance.type === 'monthly' ? 'MMM YY' : 'D MMM YYYY'
            ),
          x2: moment(projectData?.delayTolerance?.endDateLabel).format('MMM YY') ||
            moment(data?.end_date.$date)
              .add(
                data?.delay_tolerance.count,
                data?.delay_tolerance.type === 'monthly' ? 'months' : 'days'
              )
              .format(data?.delay_tolerance.type === 'monthly' ? 'MMM YY' : 'D MMM YYYY'),

          fillColor: 'grey',
          opacity: 0.4,
        })
        return true
      }
    })
  }

  const toggleBudgetReserve = () => {
    setShowBudgetReserve((prev) => {
      if (prev) {
        chart?.removeAnnotation('budget-reserve')
        return false
      }
      chart?.addYaxisAnnotation({
        id: 'budget-reserve',
        y: data?.budget_at_completion?.amount,
        y2: data?.reserve?.amount + data?.budget_at_completion?.amount,
        borderColor: '#000',
        fillColor: 'grey',
        opacity: 0.2,
      })
      return true
    })
  }

  const toggleRebaseline = () => {
    setShowRebaseline((prev) => {
      if (prev) {
        data.rebase_line.forEach((result: any, index: number) => {
          chart?.removeAnnotation(`rebaseline-${index}`);
        });
      } else {
        // Group rebaseline annotations by their x-axis value (date)
        const groupedAnnotations = data.rebase_line.reduce((acc: any, result: any) => {
          const formattedDate = formatter(result.data_date);

          if (!acc[formattedDate]) {
            acc[formattedDate] = [];
          }
          acc[formattedDate].push(result);
          return acc;
        }, {});

        // Add a single annotation for each date with the count of rebaselines
        Object.keys(groupedAnnotations).forEach((dateKey, index) => {
          const rebaselineCount = groupedAnnotations[dateKey].length;
          const formattedDate = getFormattedDate(
            groupedAnnotations[dateKey][0].data_date,
            data?.period_count?.type,
            data?.date_format
          );

          let annotationText = '';
          if (isWp) {
            // Display only the date of the rebaseline
            annotationText = `RBL ${formattedDate}`;
          } else {
            // Display the count of rebaselines if there's more than one
            annotationText = `${rebaselineCount} RBL ${formattedDate}`;
          }

          chart?.addXaxisAnnotation({
            id: `rebaseline-${index}`,
            x: dateKey,
            strokeDashArray: 0,
            borderColor: '#FF6347',
            label: {
              borderColor: '#FF6347',
              orientation: 'vertical',
              style: {
                color: '#fff',
                background: '#FF6347',
                cssClass: 'border-0',
              },
              text: annotationText,
            },
          });
        });
      }
      return !prev;
    });
  };


  const refreshChart = () => {
    if (!chartRef.current) return
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    setChart(chart)

    if (chart) chart.render()

    return { chart }
  }

  useEffect(() => {
    const { chart } = refreshChart() || {};

    chart?.addXaxisAnnotation({
      id: 'delay-tolerance',
      x: moment(projectData?.delayTolerance?.startDateLabel).format('MMM YY') ||
        moment(data?.end_date.$date).format(
          data?.delay_tolerance.type === 'monthly' ? 'MMM YY' : 'D MMM YYYY'
        ),
      x2: moment(projectData?.delayTolerance?.endDateLabel).format('MMM YY') ||
        moment(data?.end_date.$date)
          .add(
            data?.delay_tolerance.count,
            data?.delay_tolerance.type === 'monthly' ? 'months' : 'days'
          )
          .format(data?.delay_tolerance.type === 'monthly' ? 'MMM YY' : 'D MMM YYYY'),
      fillColor: 'grey',
      opacity: 0.4,
    });
    setShowDelayTolerance(true);

    chart?.addYaxisAnnotation({
      id: 'budget-reserve',
      y: data?.budget_at_completion?.amount,
      y2: data?.reserve?.amount + data?.budget_at_completion?.amount,
      borderColor: '#000',
      fillColor: 'grey',
      opacity: 0.2,
    });
    setShowBudgetReserve(true);

    if (showRebaseline) {
      // Group rebaseline annotations by their x-axis value (date)
      const groupedAnnotations = data.rebase_line.reduce((acc: any, result: any) => {
        const formattedDate = formatter(result.data_date);

        if (!acc[formattedDate]) {
          acc[formattedDate] = [];
        }
        acc[formattedDate].push(result);
        return acc;
      }, {});

      // Add a single annotation for each date with the count of rebaselines
      Object.keys(groupedAnnotations).forEach((dateKey, index) => {
        const rebaselineCount = groupedAnnotations[dateKey].length;
        const formattedDate = getFormattedDate(
          groupedAnnotations[dateKey][0].data_date,
          data?.period_count?.type,
          data?.date_format
        );

        let annotationText = '';
        if (isWp) {
          // Display only the date of the rebaseline
          annotationText = `RBL ${formattedDate}`;
        } else {
          // Display the count of rebaselines if there's more than one
          annotationText = `${rebaselineCount} RBL ${formattedDate}`;
        }

        chart?.addXaxisAnnotation({
          id: `rebaseline-${index}`, // Unique ID for each rebaseline annotation
          x: dateKey,
          strokeDashArray: 0,
          borderColor: '#FF6347',
          label: {
            borderColor: '#FF6347',
            orientation: 'vertical',
            style: {
              color: '#fff',
              background: '#FF6347',
              cssClass: 'border-0',
            },
            text: annotationText,
          },
        });
      });
    }


    return () => {
      chart?.destroy()
    }
  }, [chartRef, mode, data, isWp]);


  useEffect(() => {
    if (!chart || !(chart as any).series) return
    legends.forEach((item, index) => {
      if (!item) {
        chart?.hideSeries(labels[index].id)
      }
    })
  }, [chart])

  const toggleSeries = (index: number) => {
    if (!chart || !(chart as any).series) return
    setLegends((prev) => {
      if (prev[index]) chart?.hideSeries(labels[index].id)
      else chart?.showSeries(labels[index].id)
      return prev.map((item, idx) => (index === idx ? !item : item))
    })
  }

  const baseColor = getCSSVariableValue('--kt-primary')
  const secondaryColor = getCSSVariableValue('--kt-warning')
  const thirdColor = getCSSVariableValue('--kt-success')

  const colors = [
    ...(data?.colors?.s_cruve_colors && data.colors?.s_cruve_colors?.length
      ? getSCurvesColors(data.colors?.s_cruve_colors)
      : getSCurvesColors([baseColor, secondaryColor, thirdColor])),
    getCSSVariableValue('--kt-danger'),
  ]

  return (
    <div>
      {/* begin::Body */}
      <div>
        {/* begin::Chart */}
        <div className='my-3 px-5 align-items-center d-flex justify-content-between'>
          <div className='d-inline-flex gap-2'>
            <span>
              <button
                onClick={() => setExpanded((prev) => !prev)}
                className='btn btn-light p-2 d-inline-flex align-items-center justify-content-center rotate active z-index-2'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr021.svg'
                  className={clsx('svg-icon-muted svg-icon svg-icon-sm me-0', {
                    'rotate-270': !expanded,
                    'rotate-90': expanded,
                  })}
                />
              </button>
            </span>
            <span className='card-label fw-bold fs-3 mb-1'>S-Curves</span>
          </div>
          <div className='d-flex align-items-center gap-2'>
            {!!data.reserve && expanded && (
              <label className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  name='notifications'
                  checked={showBudgetReserve}
                  onChange={toggleBudgetReserve}
                />

                <span className='form-check-label text-muted fs-6'>Budget Reserve</span>
              </label>
            )}
            {!!data.delay_tolerance?.count && expanded &&
              projectData?.delayTolerance?.startDateLabel !== projectData?.delayTolerance?.endDateLabel && (
                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input w-30px h-20px'
                    type='checkbox'
                    value='1'
                    name='notifications'
                    onChange={toggleDelayAnnotation}
                    checked={showDelayTolerance}
                  />

                  <span className='form-check-label text-muted fs-6'>Delay Tolerance</span>
                </label>
              )}
            <label className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input w-30px h-20px'
                type='checkbox'
                value='1'
                name='rebaseline'
                onChange={toggleRebaseline}
                checked={showRebaseline}
              />
              <span className='form-check-label text-muted fs-6'>Rebaseline</span>
            </label>

            {!isShared && (<i
              onClick={() => {
                if (isWp) {
                  setShowModal(true);
                } else {
                  setEnableToast(true)
                }
              }}
              className='bi bi-gear d-flex text-hover-primary justify-content-end cursor-pointer ms-2'
              onMouseOver={(e) => (e.currentTarget.style.opacity = '0.75')}
              onMouseOut={(e) => (e.currentTarget.style.opacity = '1')}
              style={{ fontSize: '2rem', transition: 'all 0.2s ease-in-out' }}
            ></i>
            )}

            <ForecastSettingsModal onHide={() => setShowModal((prev) => !prev)} show={showModal} />
          </div>
        </div>
        <Collapse in={expanded}>
          <div>
            <div className='pt-4' ref={chartRef} id='kt_charts_widget_4_chart'></div>
            <CustomLegends
              legendLabels={legendLabels}
              labelsStyles={labels}
              colors={colors}
              toggleLegend={toggleSeries}
              legends={
                !getCustomCurve('worstCaseBaseline', data) ||
                  !getCustomCurve('worstCaseBaseline', data).length
                  ? legends.slice(0, 12)
                  : legends
              }
            />
          </div>
        </Collapse>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
      {enableToast &&
        <div style={{ position: 'fixed', bottom: '1%', right: '1%' }}>
          <Toast bg={'primary'} onClose={() => setEnableToast(false)} show={enableToast} delay={3000} autohide>
            <Toast.Header>
              <strong className="me-auto">Info</strong>
            </Toast.Header>
            <Toast.Body>
              <div className='justify-content-center'>
                <p style={{ textAlign: 'center', fontWeight: '800', fontSize: '1.5em', color: 'white' }}>
                  This option is available only for WP.
                </p>
              </div>
            </Toast.Body>
          </Toast>
        </div>
      }
    </div>
  )
}

export default ChartWithAnnotations
