import type { DataNode, EventDataNode } from 'antd/es/tree'
import dayjs from 'dayjs';
import { sweetAlert } from '../../../../utils/funcs';

interface TreeNodeProps {
  folder: {
    _id: { $oid: string };
    name: string;
    parent_wbs?: { $oid: string };
    data: { $oid: string };
    subwbs: any;
  };
}

export const updateTreeData = (list: DataNode[], key: string, children: DataNode[]): DataNode[] =>
  list.map((node) => {
    if (node.key === key) return {...node, children}
    if (node.children) return {...node, children: updateTreeData(node.children, key, children)}
    return node
  })

export const appendTreeData = (list: DataNode[], key: string, children: DataNode[]): DataNode[] =>
  list.map((node) => {
    if (node.key === key)
      return {
        ...node,
        children: node.children ? [...node.children, ...children] : children,
        switcherIcon: undefined,
      }
    if (node.children) return {...node, children: appendTreeData(node.children, key, children)}

    return node
  })

export const deleteTreeNode = (list: DataNode[], keyToDelete: string): DataNode[] => {
  return list.filter((node) => {
    if (node.key === keyToDelete) {
      // Filter out the node if its key matches the key to delete
      return false
    }

    if (node.children) {
      // Recursively call deleteTreeNode on the children
      const updatedChildren = deleteTreeNode(node.children, keyToDelete)

      if (updatedChildren.length > 0) {
        // If there are updated children, include the node with updated children
        node.children = updatedChildren
      } else {
        // If there are no updated children, remove the children property
        delete node.children
      }
    }

    // Include the node in the filtered result
    return true
  })
}


export const moveTreeNode = async (
  treeData: DataNode[],
  oldParentKey: string | null,
  newParentKey: string,
  nodeKey: string
): Promise<DataNode[]> => {
  let nodeToMove: DataNode | null = null;

  // Step 1: Remove the node from the old parent
  const removeNode = async (nodes: DataNode[]): Promise<DataNode[]> => {
    const updatedNodes: (DataNode | null)[] = await Promise.all(
      nodes.map(async (node): Promise<DataNode | null> => {
        if (node.key === nodeKey) {
          nodeToMove = node; // Save the node to be moved
          return null; // Mark this node for removal
        }

        // Recursively search and update children
        if (node.children) {
          node.children = await removeNode(node.children);
        }

        return node; // Return the updated node (or unchanged if not matched)
      })
    );

    // Filter out null values from the updated nodes, applying type guard
    return updatedNodes.filter((n): n is DataNode => n !== null);
  };

  // Step 2: Add the node to the new parent
  const addNode = async (nodes: DataNode[]): Promise<DataNode[]> => {
    return Promise.all(
      nodes.map(async (node): Promise<DataNode> => {
        if (String(node.key) === newParentKey) {
          const updatedChildren = node.children ? [...node.children, nodeToMove!] : [nodeToMove!];
          return { ...node, children: updatedChildren };
        }

        // Recursively search and update children
        if (node.children) {
          node.children = await addNode(node.children);
        }

        return node;
      })
    );
  };

  // Remove the node from its old parent
  const treeAfterRemoval = await removeNode(treeData);

  // Check if nodeToMove is set before continuing
  if (!nodeToMove) {
    throw new Error(`Node with key ${nodeKey} not found for removal.`);
  }

  // Add the node to the new parent
  const treeAfterAddition = await addNode(treeAfterRemoval);

  return treeAfterAddition;
};

export const findNodeById = (origin: DataNode[], idToFind: string): DataNode | null => {
  for (const node of origin) {
    if (node.key === idToFind) {
      return node // Return the node if the ID matches
    }

    if (node.children) {
      const foundNode = findNodeById(node.children, idToFind)
      if (foundNode) {
        return foundNode // If found in children, return that node
      }
    }
  }

  return null // Return null if the ID is not found in the tree
}

export const buildFullPath = (node: EventDataNode<DataNode>, nodesMap: Map<string, DataNode>): string => {
  const titleElement = node.title as React.ReactElement<TreeNodeProps>;
  if (!titleElement || !titleElement.props || !titleElement.props.folder) {
    return '';
  }

  let path = titleElement.props.folder.name;
  let parentKey = titleElement.props.folder.parent_wbs?.$oid;

  while (parentKey) {
    const parentNode = nodesMap.get(parentKey);
    if (!parentNode) break;

    const parentTitleElement = parentNode.title as React.ReactElement<TreeNodeProps>;
    if (!parentTitleElement || !parentTitleElement.props || !parentTitleElement.props.folder) {
      break;
    }

    path = `${parentTitleElement.props.folder.name}/${path}`;
    parentKey = parentTitleElement.props.folder.parent_wbs?.$oid;
  }

  return path;
};

export const combineVersions = async (versions: any[], calculate_forcaste = false, data_date?: Date, setWbsDataDateAsync?: (wps: any[], data_date: Date) => Promise<any[]>): Promise<any> => {

  if (versions.length === 0) return {};

  const minStartDate = new Date(Math.min(...versions.map(v => new Date(v.start_date.$date).getTime())));
  const maxEndDate = new Date(Math.max(...versions.map(v => new Date(v.end_date.$date).getTime())));
  const minDataDate = new Date(Math.min(...versions.map(v => new Date(v.data_date.$date).getTime())));
  const passedDataDate = data_date ? data_date : minDataDate
  
  let updatedWps = versions
  if  (calculate_forcaste && setWbsDataDateAsync) 
     updatedWps = await setWbsDataDateAsync(versions, passedDataDate)

  if (versions.length === 1) return updatedWps[0];

  const getMonthlySteps = (startDate: Date, endDate: Date): Date[] => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return dates;
  };

  const monthlySteps = getMonthlySteps(minStartDate, maxEndDate);

  const extendValues = (version: any, fieldName: string, type: string) => {
    const extendedCumulative: number[] = Array(monthlySteps.length).fill(0);
    const extendedPeriodic: number[] = Array(monthlySteps.length).fill(0);

    const startIndex = monthlySteps.findIndex(date => date.getTime() >= new Date(version.start_date.$date).getTime());

    if (startIndex !== -1) {
      const cumulativeValues = version.output[fieldName]?.[type]?.cumulative || [];
      const periodicValues = version.output[fieldName]?.[type]?.periodic || [];

      cumulativeValues.forEach((value: any, index: number) => {
        if (startIndex + index < extendedCumulative.length) {
          extendedCumulative[startIndex + index] = parseFloat(value);
        }
      });

      periodicValues.forEach((value: any, index: number) => {
        if (startIndex + index < extendedPeriodic.length) {
          extendedPeriodic[startIndex + index] = parseFloat(value);
        }
      });

      for (let i = 1; i < extendedCumulative.length; i++) {
        if (extendedCumulative[i] === 0) {
          extendedCumulative[i] = extendedCumulative[i - 1] + extendedPeriodic[i];
        }
      }
    }

    return { cumulative: extendedCumulative, periodic: extendedPeriodic };
  };

  const extendAllValues = (version: any, fieldName: string) => {
    return {
      original: extendValues(version, fieldName, 'original'),
      mixed: extendValues(version, fieldName, 'mixed'),
      forecast: extendValues(version, fieldName, 'forecast')
    };
  };

  const extendedVersions = updatedWps.map((version: any) => {
    return {
      ...version,
      output: {
        ...version.output,
        labels: monthlySteps.map(date => date.toISOString().slice(0, 10)),
        plannedValues: extendAllValues(version, 'plannedValues'),
        earnedValues: extendAllValues(version, 'earnedValues'),
        actualCosts: extendAllValues(version, 'actualCosts')
      }
    };
  });

  const combineValues = (fieldName: string, type: string) => {
    const combinedCumulative: number[] = Array(monthlySteps.length).fill(0);
    const combinedPeriodic: number[] = Array(monthlySteps.length).fill(0);

    extendedVersions.forEach((version: any) => {
      version.output[fieldName]?.[type]?.cumulative.forEach((value: any, index: number) => {
        combinedCumulative[index] += value;
      });
      version.output[fieldName]?.[type]?.periodic.forEach((value: any, index: number) => {
        combinedPeriodic[index] += value;
      });
    });

    return { cumulative: combinedCumulative, periodic: combinedPeriodic };
  };

  const combinedPlannedValue = {
    original: combineValues('plannedValues', 'original'),
    mixed: combineValues('plannedValues', 'mixed'),
    forecast: combineValues('plannedValues', 'forecast')
  };

  const combinedEarnedValue = {
    original: combineValues('earnedValues', 'original'),
    mixed: combineValues('earnedValues', 'mixed'),
    forecast: combineValues('earnedValues', 'forecast')
  };

  const combinedActualCost = {
    original: combineValues('actualCosts', 'original'),
    mixed: combineValues('actualCosts', 'mixed'),
    forecast: combineValues('actualCosts', 'forecast')
  };

  const combinedVersion: any = {
    ...updatedWps[0],
    start_date: { $date: minStartDate.toISOString() },
    end_date: { $date: maxEndDate.toISOString() },
    data_date: { $date: passedDataDate.toISOString() },
    output: {
      ...updatedWps[0].output,
      labels: monthlySteps.map(date => date.toISOString().slice(0, 10)),
      plannedValues: combinedPlannedValue,
      earnedValues: combinedEarnedValue,
      actualCosts: combinedActualCost,
    },
    custom_curve: []
  };

  const combineCustomCurves = (curveName: string) => {
    const curveValues: number[] = Array(monthlySteps.length).fill(0);

    extendedVersions.forEach((version: any) => {
      const startIndex = monthlySteps.findIndex(date => date.getTime() >= new Date(version.start_date.$date).getTime());
      if (startIndex === -1) return;

      const curve = version.custom_curve.find((c: any) => c.name === curveName);
      if (!curve) return;

      curve.values.forEach((value: any, index: number) => {
        if (startIndex + index < curveValues.length) {
          curveValues[startIndex + index] += parseFloat(value);
        }
      });
    });

    return curveValues;
  };

  combinedVersion.custom_curve = [
    {
      name: 'cumulativePlannedValue',
      color: 'black',
      values: combineCustomCurves('cumulativePlannedValue'),
    },
    {
      name: 'cumulativeEarnedValue',
      color: 'white',
      values: combineCustomCurves('cumulativeEarnedValue'),
    },
    {
      name: 'cumulativeActualCost',
      color: 'red',
      values: combineCustomCurves('cumulativeActualCost'),
    },
  ];

  combinedVersion.reserve = {
    _cls: versions[0].reserve._cls,
    amount: versions.reduce((sum, v) => sum + v.reserve.amount, 0),
    unit: versions[0].reserve.unit,
  };

  combinedVersion.delay_tolerance = {
    type: versions[0].delay_tolerance.type,
    count: versions.reduce((sum, v) => sum + v.delay_tolerance.count, 0),
  };

  combinedVersion.budget_at_completion = {
    _cls: versions[0].budget_at_completion._cls,
    amount: versions.reduce((sum, v) => sum + v.budget_at_completion.amount, 0),
    unit: versions[0].budget_at_completion.unit,
    currency: versions[0].budget_at_completion.currency,
  };

  combinedVersion.total_hours = versions.reduce((sum, v) => sum + (v.total_hours || 0), 0);
  combinedVersion.achieved_percentage = versions.reduce((sum, v) => sum + (v.achieved_percentage || 0), 0) / versions.length;

  return combinedVersion;
};

export const combineVersionsV2 = async (
name: string,
versions: any[], 
calculate_forcaste = false, 
setWbsDataDateFirstStepAsync?: (name: string, wps: any[], data_date: Date) => Promise<any[]>, 
setWbsDataDateSecondStepAsync?: (name: string, data: any, data_date: Date, isShared?: boolean, changes?: Partial<any>) => Promise<void>, 
data_date?: Date, 
setWpsCalculatedData?: (wpsData: any) => { payload: any[]; type: "wbstreedata/updateWpsCalculatedData"; }, 
): Promise<any> => {

  if (versions.length === 0) return {};

  const minDataDate = new Date(Math.min(...versions.map(v => new Date(v.data_date.$date).getTime())));
  const passedDataDate = data_date ? data_date : minDataDate;

  const processCombination = () => {
    return processVersionCombination(name, versions, calculate_forcaste, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync, passedDataDate, setWpsCalculatedData);
  };

  return processCombination();
};


export const combineVersionsV2WithoutAlert = async (
  name: string,
  versions: any[], 
  calculate_forcaste = false, 
  setWbsDataDateFirstStepAsync?: (name: string, wps: any[], data_date: Date) => Promise<any[]>, 
  setWbsDataDateSecondStepAsync?: (name: string, data: any, data_date: Date, isShared?: boolean, changes?: Partial<any>) => Promise<void>, 
  data_date?: Date, 
  setWpsCalculatedData?: (wpsData: any) => { payload: any[]; type: "wbstreedata/updateWpsCalculatedData"; }, 
  ): Promise<any> => {
  
    if (versions.length === 0) return {};
  
    const minDataDate = new Date(Math.min(...versions.map(v => new Date(v.data_date.$date).getTime())));
    const passedDataDate = data_date ? data_date : minDataDate;
  
    const processCombination = () => {
      return processVersionCombination(name, versions, calculate_forcaste, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync, passedDataDate, setWpsCalculatedData, false);
    };
  
    return processCombination();
  };

const processVersionCombination = async (
  name: string,
  versions: any[], 
  calculate_forcaste: boolean, 
  setWbsDataDateFirstStepAsync: ((name: string, wps: any[], data_date: Date) => Promise<any[]>) | undefined, 
  setWbsDataDateSecondStepAsync: ((name: string, data: any, data_date: Date, isShared?: boolean, changes?: Partial<any>) => Promise<void>) | undefined,
  passedDataDate: Date,
  setWpsCalculatedData: ((wpsData: any) => { payload: any[]; type: "wbstreedata/updateWpsCalculatedData"; }) | undefined,
  isAlertEnabled: boolean = true,
): Promise<any> => {

  let updatedWps = versions;
  if (calculate_forcaste && setWbsDataDateFirstStepAsync) {
    updatedWps = await setWbsDataDateFirstStepAsync(name, versions, passedDataDate);
  }

  if (versions.length === 1 && setWbsDataDateSecondStepAsync) {
    let combinedRebaseLines: any[] = []
    updatedWps[0].rebase_line?.forEach((rebase: any, index: number) => {
      combinedRebaseLines.push({
        ...rebase,
        name: updatedWps[0]?.name,
        wp_data_id: updatedWps[0]?._id?.$oid,
        custom_curve: rebase.custom_curve.map((curve: any) => ({
          ...curve,
          values: [...curve.values]
        }))
      });
    });
    updatedWps[0] = {
      ...updatedWps[0],
      rebase_line: combinedRebaseLines,
    }
    const result = await setWbsDataDateSecondStepAsync(name, updatedWps[0], passedDataDate, !isAlertEnabled);
    return result;
  }

  if(setWpsCalculatedData)
    setWpsCalculatedData(updatedWps)

  const minStartDate = new Date(Math.min(...versions.map(v => new Date(v.start_date.$date).getTime())));
  const maxEndDate = new Date(Math.max(...versions.map(v => new Date(v.end_date.$date).getTime())));

  const maxEstimatedEndDate = new Date(Math.max(...updatedWps.map(v => new Date(v?.output.CardsData?.SchedulePerformance?.EED?.Value).getTime())));
  maxEstimatedEndDate.setHours(maxEstimatedEndDate.getHours() + 1);

  const getMonthlySteps = (startDate: Date, endDate: Date): Date[] => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return dates;
  };

  const monthlySteps = getMonthlySteps(minStartDate, maxEndDate);
  const ev_ac_monthlySteps = getMonthlySteps(minStartDate, maxEstimatedEndDate);

  const extendValues = (version: any, fieldName: string, type: string, limitDate?: Date) => {
    const steps = fieldName === 'plannedValues' ? monthlySteps : ev_ac_monthlySteps
    const extendedCumulative: number[] = Array(steps.length).fill(0);
    const extendedPeriodic: number[] = Array(steps.length).fill(0);

    const startIndex = steps.findIndex(date => date.getTime() >= new Date(version.start_date.$date).getTime());

    if (startIndex !== -1) {
      const cumulativeValues = version.output[fieldName]?.[type]?.cumulative || [];
      const periodicValues = version.output[fieldName]?.[type]?.periodic || [];

      cumulativeValues.forEach((value: any, index: number) => {
        if (startIndex + index < extendedCumulative.length) {
          if (!limitDate || steps[startIndex + index] <= limitDate) {
            extendedCumulative[startIndex + index] = parseFloat(value);
          }
        }
      });

      periodicValues.forEach((value: any, index: number) => {
        if (startIndex + index < extendedPeriodic.length) {
          if (!limitDate || steps[startIndex + index] <= limitDate) {
            extendedPeriodic[startIndex + index] = parseFloat(value);
          }
        }
      });

      for (let i = 1; i < extendedCumulative.length; i++) {
        if (extendedCumulative[i] === 0) {
          extendedCumulative[i] = extendedCumulative[i - 1] + extendedPeriodic[i];
        }
      }
    }

    return { cumulative: extendedCumulative, periodic: extendedPeriodic };
  };

  const extendAllValues = (version: any, fieldName: string) => {
    return {
      original: fieldName === 'plannedValues' ? extendValues(version, fieldName, 'original') : extendValues(version, fieldName, 'original', passedDataDate),
      mixed: extendValues(version, fieldName, 'mixed'),
      forecast: extendValues(version, fieldName, 'forecast')
    };
  };

  const extendedVersions = updatedWps.map((version: any) => {
    return {
      ...version,
      output: {
        ...version.output,
        plannedValues: extendAllValues(version, 'plannedValues'),
        earnedValues: extendAllValues(version, 'earnedValues'),
        actualCosts: extendAllValues(version, 'actualCosts')
      }
    };
  });

  const replaceZeroWithNull = (arr: number[]) => arr.map(value => Number.isNaN(value) ? null : value);

  const combineValues = (fieldName: string, type: string, limitDate?: Date) => {
    const steps = fieldName === 'plannedValues' ? monthlySteps : ev_ac_monthlySteps
    const combinedCumulative: number[] = Array(steps.length).fill(0);
    const combinedPeriodic: number[] = Array(steps.length).fill(0);

    extendedVersions.forEach((version: any) => {
      version.output[fieldName]?.[type]?.cumulative.forEach((value: any, index: number) => {
        if (!limitDate || steps[index] <= limitDate) {
          combinedCumulative[index] += value;
        }
      });
      version.output[fieldName]?.[type]?.periodic.forEach((value: any, index: number) => {
        if (!limitDate || steps[index] <= limitDate) {
          combinedPeriodic[index] += value;
        }
      });
    });

    const limitedIndex = limitDate ? steps.findIndex(date => date > limitDate) : -1;
    const slicedCumulative = limitedIndex !== -1 ? combinedCumulative.slice(0, limitedIndex) : combinedCumulative;
    const slicedPeriodic = limitedIndex !== -1 ? combinedPeriodic.slice(0, limitedIndex) : combinedPeriodic;

    return {
      cumulative: replaceZeroWithNull(slicedCumulative),
      periodic: replaceZeroWithNull(slicedPeriodic)
    };
  };

  const combineRebaseLines = () => {
    const combinedRebaseLines: any[] = [];
    extendedVersions.forEach((version: any) => {
      version.rebase_line?.forEach((rebase: any, index: number) => {
          combinedRebaseLines.push({
            ...rebase,
            name: version?.name,
            wp_data_id: version?._id?.$oid,
            custom_curve: rebase.custom_curve.map((curve: any) => ({
              ...curve,
              values: [...curve.values]
            }))
          });
      });
    });
    return combinedRebaseLines;
  };

  const combinedPlannedValue = {
    original: combineValues('plannedValues', 'original'),
    mixed: combineValues('plannedValues', 'mixed'),
    forecast: combineValues('plannedValues', 'forecast')
  };

  const combinedEarnedValue = {
    original: combineValues('earnedValues', 'original', passedDataDate),
    mixed: combineValues('earnedValues', 'mixed'),
    forecast: combineValues('earnedValues', 'forecast')
  };

  const combinedActualCost = {
    original: combineValues('actualCosts', 'original', passedDataDate),
    mixed: combineValues('actualCosts', 'mixed'),
    forecast: combineValues('actualCosts', 'forecast')
  };

  const combinedVersion: any = {
    ...updatedWps[0],
    start_date: { $date: minStartDate.toISOString() },
    end_date: { $date: maxEndDate.toISOString() },
    data_date: { $date: passedDataDate.toISOString() },
    output: {
      ...updatedWps[0].output,
      plannedValues: combinedPlannedValue,
      earnedValues: combinedEarnedValue,
      actualCosts: combinedActualCost,
    },
    rebase_line: combineRebaseLines(),
    custom_curve: []
  };

  const combineCustomCurves = (curveName: string) => {
    const curveValues: number[] = Array(monthlySteps.length).fill(0);

    extendedVersions.forEach((version: any) => {
      const startIndex = monthlySteps.findIndex(date => date.getTime() >= new Date(version.start_date.$date).getTime());
      if (startIndex === -1) return;

      const curve = version.custom_curve.find((c: any) => c.name === curveName);
      if (!curve) return;

      curve.values.forEach((value: any, index: number) => {
        if (startIndex + index < curveValues.length) {
          curveValues[startIndex + index] += parseFloat(value);
        }
      });
    });

    return curveValues;
  };

  combinedVersion.custom_curve = [
    {
      name: 'cumulativePlannedValue',
      color: 'black',
      values: combineCustomCurves('cumulativePlannedValue'),
    },
    {
      name: 'cumulativeEarnedValue',
      color: 'white',
      values: combineCustomCurves('cumulativeEarnedValue'),
    },
    {
      name: 'cumulativeActualCost',
      color: 'red',
      values: combineCustomCurves('cumulativeActualCost'),
    },
  ];

  combinedVersion.reserve = {
    _cls: versions[0].reserve._cls,
    amount: versions.reduce((sum, v) => sum + v.reserve.amount, 0),
    unit: versions[0].reserve.unit,
  };

  const calculateDelayTolerance = (versions: any[]) => {
    const endDates = versions.map(v => new Date(v.end_date.$date));
    const delayTolerances = versions.map(v => v.delay_tolerance.count);
    const maxEndDates = endDates.map((date, index) => {
      const newDate = new Date(date);
      newDate.setMonth(newDate.getMonth() + delayTolerances[index]);
      return newDate;
    });

    const latestEndDate = new Date(Math.max(...endDates.map(date => date.getTime())));
    const latestMaxEndDate = new Date(Math.max(...maxEndDates.map(date => date.getTime())));

    const delayToleranceWBS = new Date(latestMaxEndDate.getTime() - latestEndDate.getTime());
    const monthsDifference = (delayToleranceWBS.getFullYear() - 1970) * 12 + delayToleranceWBS.getMonth();

    return monthsDifference;
  };

  combinedVersion.delay_tolerance = {
    type: versions[0].delay_tolerance.type,
    count: calculateDelayTolerance(versions),
  };

  combinedVersion.budget_at_completion = {
    _cls: versions[0].budget_at_completion._cls,
    amount: versions.reduce((sum, v) => sum + v.budget_at_completion.amount, 0),
    unit: versions[0].budget_at_completion.unit,
    currency: versions[0].budget_at_completion.currency,
  };

  combinedVersion.total_hours = versions.reduce((sum, v) => sum + (v.total_hours || 0), 0);
  combinedVersion.achieved_percentage = versions.reduce((sum, v) => sum + (v.achieved_percentage || 0), 0) / versions.length;

  if (setWbsDataDateSecondStepAsync) {
    return await setWbsDataDateSecondStepAsync(name, combinedVersion, passedDataDate, !isAlertEnabled);
  }

  return combinedVersion;
};

export const combineVersionsWithoutStep1 = async (
  name: string,
  versions: any[],  
  setWbsDataDateSecondStepAsync?: (name: string, data: any, data_date: Date, isShared?: boolean, changes?: Partial<any>) => Promise<void>,
  data_date?: Date, 
  ): Promise<any> => {
  
    if (versions.length === 0) return {};

    const minStartDate = new Date(Math.min(...versions.map(v => new Date(v.start_date.$date).getTime())));
    const maxEndDate = new Date(Math.max(...versions.map(v => new Date(v.end_date.$date).getTime())));
    const minDataDate = new Date(Math.min(...versions.map(v => new Date(v.data_date.$date).getTime())));
    const passedDataDate = data_date ? data_date : minDataDate

    if (versions.length === 1 && setWbsDataDateSecondStepAsync) {
      let combinedRebaseLines: any[] = []
      versions[0].rebase_line?.forEach((rebase: any, index: number) => {
        combinedRebaseLines.push({
          ...rebase,
          name: versions[0]?.name,
          wp_data_id: versions[0]?._id?.$oid,
          custom_curve: rebase.custom_curve.map((curve: any) => ({
            ...curve,
            values: [...curve.values]
          }))
        });
      });
      versions[0] = {
        ...versions[0],
        rebase_line: combinedRebaseLines,
      }
      const result = await setWbsDataDateSecondStepAsync(name, versions[0], passedDataDate);
      return result;
    }
  
    const maxEstimatedEndDate = new Date(Math.max(...versions.map(v => new Date(v?.output.CardsData?.SchedulePerformance?.EED?.Value).getTime())));
    maxEstimatedEndDate.setHours(maxEstimatedEndDate.getHours() + 1);
  
    const getMonthlySteps = (startDate: Date, endDate: Date): Date[] => {
      const dates = [];
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
      return dates;
    };
  
    const monthlySteps = getMonthlySteps(minStartDate, maxEndDate);
    const ev_ac_monthlySteps = getMonthlySteps(minStartDate, maxEstimatedEndDate);
  
    const extendValues = (version: any, fieldName: string, type: string, limitDate?: Date) => {
      const steps = fieldName === 'plannedValues' ? monthlySteps : ev_ac_monthlySteps
      const extendedCumulative: number[] = Array(steps.length).fill(0);
      const extendedPeriodic: number[] = Array(steps.length).fill(0);
  
      const startIndex = steps.findIndex(date => date.getTime() >= new Date(version.start_date.$date).getTime());
  
      if (startIndex !== -1) {
        const cumulativeValues = version.output[fieldName]?.[type]?.cumulative || [];
        const periodicValues = version.output[fieldName]?.[type]?.periodic || [];
  
        cumulativeValues.forEach((value: any, index: number) => {
          if (startIndex + index < extendedCumulative.length) {
            if (!limitDate || steps[startIndex + index] <= limitDate) {
              extendedCumulative[startIndex + index] = parseFloat(value);
            }
          }
        });
  
        periodicValues.forEach((value: any, index: number) => {
          if (startIndex + index < extendedPeriodic.length) {
            if (!limitDate || steps[startIndex + index] <= limitDate) {
              extendedPeriodic[startIndex + index] = parseFloat(value);
            }
          }
        });
  
        for (let i = 1; i < extendedCumulative.length; i++) {
          if (extendedCumulative[i] === 0) {
            extendedCumulative[i] = extendedCumulative[i - 1] + extendedPeriodic[i];
          }
        }
      }
  
      return { cumulative: extendedCumulative, periodic: extendedPeriodic };
    };
  
    const extendAllValues = (version: any, fieldName: string) => {
      return {
        original: fieldName === 'plannedValues' ? extendValues(version, fieldName, 'original') : extendValues(version, fieldName, 'original', passedDataDate),
        mixed: extendValues(version, fieldName, 'mixed'),
        forecast: extendValues(version, fieldName, 'forecast')
      };
    };
  
    const extendedVersions = versions.map((version: any) => {
      return {
        ...version,
        output: {
          ...version.output,
          plannedValues: extendAllValues(version, 'plannedValues'),
          earnedValues: extendAllValues(version, 'earnedValues'),
          actualCosts: extendAllValues(version, 'actualCosts')
        }
      };
    });
  
    const replaceZeroWithNull = (arr: number[]) => arr.map(value => Number.isNaN(value) ? null : value);
  
    const combineValues = (fieldName: string, type: string, limitDate?: Date) => {
      const steps = fieldName === 'plannedValues' ? monthlySteps : ev_ac_monthlySteps
      const combinedCumulative: number[] = Array(steps.length).fill(0);
      const combinedPeriodic: number[] = Array(steps.length).fill(0);
  
      extendedVersions.forEach((version: any) => {
        version.output[fieldName]?.[type]?.cumulative.forEach((value: any, index: number) => {
          if (!limitDate || steps[index] <= limitDate) {
            combinedCumulative[index] += value;
          }
        });
        version.output[fieldName]?.[type]?.periodic.forEach((value: any, index: number) => {
          if (!limitDate || steps[index] <= limitDate) {
            combinedPeriodic[index] += value;
          }
        });
      });
  
      const limitedIndex = limitDate ? steps.findIndex(date => date > limitDate) : -1;
      const slicedCumulative = limitedIndex !== -1 ? combinedCumulative.slice(0, limitedIndex) : combinedCumulative;
      const slicedPeriodic = limitedIndex !== -1 ? combinedPeriodic.slice(0, limitedIndex) : combinedPeriodic;
  
      return {
        cumulative: replaceZeroWithNull(slicedCumulative),
        periodic: replaceZeroWithNull(slicedPeriodic)
      };
    };
  
    const combineRebaseLines = () => {
      const combinedRebaseLines: any[] = [];
      extendedVersions.forEach((version: any) => {
        version.rebase_line?.forEach((rebase: any, index: number) => {
            combinedRebaseLines.push({
              ...rebase,
              name: version?.name,
              wp_data_id: version?._id?.$oid,
              custom_curve: rebase.custom_curve.map((curve: any) => ({
                ...curve,
                values: [...curve.values]
              }))
            });
        });
      });
      return combinedRebaseLines;
    };
  
    const combinedPlannedValue = {
      original: combineValues('plannedValues', 'original'),
      mixed: combineValues('plannedValues', 'mixed'),
      forecast: combineValues('plannedValues', 'forecast')
    };
  
    const combinedEarnedValue = {
      original: combineValues('earnedValues', 'original', passedDataDate),
      mixed: combineValues('earnedValues', 'mixed'),
      forecast: combineValues('earnedValues', 'forecast')
    };
  
    const combinedActualCost = {
      original: combineValues('actualCosts', 'original', passedDataDate),
      mixed: combineValues('actualCosts', 'mixed'),
      forecast: combineValues('actualCosts', 'forecast')
    };
  
    const combinedVersion: any = {
      ...versions[0],
      start_date: { $date: minStartDate.toISOString() },
      end_date: { $date: maxEndDate.toISOString() },
      data_date: { $date: passedDataDate.toISOString() },
      output: {
        ...versions[0].output,
        plannedValues: combinedPlannedValue,
        earnedValues: combinedEarnedValue,
        actualCosts: combinedActualCost,
      },
      rebase_line: combineRebaseLines(),
      custom_curve: []
    };
  
    const combineCustomCurves = (curveName: string) => {
      const curveValues: number[] = Array(monthlySteps.length).fill(0);
  
      extendedVersions.forEach((version: any) => {
        const startIndex = monthlySteps.findIndex(date => date.getTime() >= new Date(version.start_date.$date).getTime());
        if (startIndex === -1) return;
  
        const curve = version.custom_curve.find((c: any) => c.name === curveName);
        if (!curve) return;
  
        curve.values.forEach((value: any, index: number) => {
          if (startIndex + index < curveValues.length) {
            curveValues[startIndex + index] += parseFloat(value);
          }
        });
      });
  
      return curveValues;
    };
  
    combinedVersion.custom_curve = [
      {
        name: 'cumulativePlannedValue',
        color: 'black',
        values: combineCustomCurves('cumulativePlannedValue'),
      },
      {
        name: 'cumulativeEarnedValue',
        color: 'white',
        values: combineCustomCurves('cumulativeEarnedValue'),
      },
      {
        name: 'cumulativeActualCost',
        color: 'red',
        values: combineCustomCurves('cumulativeActualCost'),
      },
    ];
  
    combinedVersion.reserve = {
      _cls: versions[0].reserve._cls,
      amount: versions.reduce((sum, v) => sum + v.reserve.amount, 0),
      unit: versions[0].reserve.unit,
    };
  
    const calculateDelayTolerance = (versions: any[]) => {
      const endDates = versions.map(v => new Date(v.end_date.$date));
      const delayTolerances = versions.map(v => v.delay_tolerance.count);
      const maxEndDates = endDates.map((date, index) => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + delayTolerances[index]);
        return newDate;
      });
  
      const latestEndDate = new Date(Math.max(...endDates.map(date => date.getTime())));
      const latestMaxEndDate = new Date(Math.max(...maxEndDates.map(date => date.getTime())));
  
      const delayToleranceWBS = new Date(latestMaxEndDate.getTime() - latestEndDate.getTime());
      const monthsDifference = (delayToleranceWBS.getFullYear() - 1970) * 12 + delayToleranceWBS.getMonth();
  
      return monthsDifference;
    };
  
    combinedVersion.delay_tolerance = {
      type: versions[0].delay_tolerance.type,
      count: calculateDelayTolerance(versions),
    };
  
    combinedVersion.budget_at_completion = {
      _cls: versions[0].budget_at_completion._cls,
      amount: versions.reduce((sum, v) => sum + v.budget_at_completion.amount, 0),
      unit: versions[0].budget_at_completion.unit,
      currency: versions[0].budget_at_completion.currency,
    };
  
    combinedVersion.total_hours = versions.reduce((sum, v) => sum + (v.total_hours || 0), 0);
    combinedVersion.achieved_percentage = versions.reduce((sum, v) => sum + (v.achieved_percentage || 0), 0) / versions.length;
  
    if (setWbsDataDateSecondStepAsync) {
      return await setWbsDataDateSecondStepAsync(name, combinedVersion, passedDataDate);
    }
  
    return combinedVersion;
  };

