import { useNavigate } from 'react-router-dom'
import { Wbs } from '../_models'
import { KTSVG } from '../../../../_metronic/helpers'
import { Dropdown, Modal } from 'react-bootstrap'
import { CustomBorderlessToggle } from '../../projects/core/ProjectHelpers'
import { useState } from 'react'
import { deleteWbs, patchWbs } from '../_requests'
import { showError, showSuccess } from '../../../utils/funcs'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDrag, useDrop } from 'react-dnd'
import clsx from 'clsx'
import MoveItem from './MoveItem'
import { useSelector, useDispatch } from 'react-redux';
import { isrename, isntrename } from '../../wbs/treeStates';
import WpData from '../../wp/components/WpData'
import { RootState } from '../../../store'
import { useProject } from '../../projects/core/ProjectContext'
import { combineVersions, combineVersionsV2 } from './TreeView/_helpers'
import WbsData from '../../wp/components/WbsData'
import { setCurrentId } from '../CurrentFolder'
import { updateLoaded, updateTotal } from '../treeLoader'
import { addWbsToStore } from '../treedata'
type Props = { projectId: any; folder: any; refetch: any }

export default function FolderItem({ projectId, folder, refetch }: Props) {
  const [showwbs, setShowwbs] = useState<boolean>(false)
  const handleClose = () => setShowwbs(false);
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [isRenaming, setIsRenaming] = useState<boolean>(false)
  const [showMoving, setShowMoving] = useState<boolean>(false)
  const { setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync } = useProject();
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const collectedLeafData = useSelector((state: RootState) => state.treedata.collectedLeafData);
  const projectversiondata = useSelector((state: RootState) => state.versiondata.data)
  const [wbsShow, setWbsShow] = useState<boolean>(false)
  const handleCloseWbs = () => setWbsShow(false);
  const [wbsData, setWbsData] = useState<any | null>(null);
  const dispatch = useDispatch();
  const currentId = useSelector((state: RootState) => state.CurrentFolder.id);

  const handleUpdateWbsDataDate = async (data_date?: Date) => {

    setLoadingData(true);

    const combinedVersion = await combineVersionsV2(
      'WBS',
      collectedLeafData,
      true,
      setWbsDataDateFirstStepAsync,
      setWbsDataDateSecondStepAsync,
      data_date);

    combinedVersion && setWbsData(combinedVersion);

    setLoadingData(false);

  };

  const formik = useFormik({
    initialValues: { name: folder.name },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Name is required')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols'),
    }),
    onSubmit: (values) => {
      if (!formik.dirty) {

        return setIsRenaming(false)
      }
      setLoading(true)
      patchWbs(folder._id.$oid, { name: values.name })
        .then((res) => {
          dispatch(addWbsToStore(res))
          refetch()

          setIsRenaming(false)
        })
        .catch(showError)
        .finally(() => {
          setLoading(false);
        })
    },
  })

  const handleDelete = () => {
    setLoading(true)
    deleteWbs(folder._id.$oid)
      .then(() => {
        refetch()
        showSuccess('Wbs deleted successfully')
      })
      .catch(showError)
      .finally(() => setLoading(false))
  }

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'folder',
    collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    item: folder,
  }))

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'folder',
    collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    drop: (item: Wbs) => {
      if (item._id.$oid === folder._id.$oid) return
      patchWbs(item._id.$oid, {
        parent_wbs: folder._id.$oid,
      })
        .then((res) => {
          dispatch(addWbsToStore(res))
          refetch()
        })

        .catch(showError)
    },
  }))
  const getPath = () => {
    if (isOver && !isDragging) {
      return '/media/icons/duotune/arrows/arr044.svg';
    } else if (folder.subwbs.length !== 0) {
      return '/media/icons/duotune/files/fil012.svg';
    } else {
      return '/media/icons/duotune/files/fil003.svg';
    }
  };
  return (
    <>
      <form
        ref={drag}
        onSubmit={formik.handleSubmit}
        onClick={() => {
          if (folder.subwbs.length > 0) {
            if (!isRenaming) {
              dispatch(setCurrentId(folder._id.$oid))
            }
          } else {
            setShowwbs(true);
          }
        }} key={folder._id.$oid}
        className={clsx('mb-5 col-md-4 col-xl-3', {
          'opacity-50': isDragging,
        })}
      >
        <div
          ref={drop}
          style={{ height: '200px' }}
          className='card border border-2 border-gray-300 border-hover cursor-pointer d-flex justify-content-center align-items-center pt-10'
        >
          {folder.subwbs.length > 0 && <button onClick={() => setWbsShow(true)} type="button" className="btn btn-primary btn-sm position-absolute top-0 end-0 m-2">
            <i className='bi bi-eye-fill fs-2'></i>
          </button>}
          <div className='symbol symbol-50px w-50px bg-light'>
            <KTSVG
              path={getPath()}
              className='svg-icon-muted svg-icon-3hx text-primary'
            />
          </div>
          {isRenaming ? (
            <input
              autoComplete='off'
              placeholder='New Wbs'
              id='name'
              {...formik.getFieldProps('name')}
              className='form-control mt-3 py-1 px-2 border  w-75'
            />
          ) : (
            <p className='mt-3 fw-bold'>{folder.name}</p>
          )}
          <div className='me-2' style={{ justifySelf: 'flex-end', alignSelf: 'flex-end' }}>
            {loading ? (
              <button type='button' disabled={true} className='btn btn-icon btn-sm text-muted'>
                <span className='indicator-progress d-inline-flex'>
                  <span className='spinner-border spinner-border-sm align-middle'></span>
                </span>
              </button>
            ) : isRenaming ? (
              <div>
                <button
                  disabled={loading}
                  type='submit'
                  className='btn btn-primary btn-icon btn-sm mt-3 mx-1'
                >
                  <KTSVG
                    className='svg-icon-muted svg-icon-3'
                    path='/media/icons/duotune/arrows/arr012.svg'
                  />
                </button>
                <button
                  onClick={() => { setIsRenaming(true); }}
                  type='submit'
                  className='btn btn-danger btn-icon btn-sm mt-3 mx-1'
                >
                  <KTSVG
                    className='svg-icon-muted svg-icon-3'
                    path='/media/icons/duotune/arrows/arr088.svg'
                  />
                </button>
              </div>
            ) : (
              <Dropdown onClick={(e) => e.stopPropagation()}>
                <Dropdown.Toggle as={CustomBorderlessToggle}></Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item disabled={loading} onClick={() => setWbsShow(true)}>
                    Open Wbs
                  </Dropdown.Item>
                  <Dropdown.Item disabled={loading} onClick={handleDelete}>
                    Delete Wbs
                  </Dropdown.Item>
                  <Dropdown.Item disabled={loading} onClick={() => { setIsRenaming(true); }}>
                    Rename Wbs
                  </Dropdown.Item>
                  <Dropdown.Item disabled={loading} onClick={() => setShowMoving(true)}>
                    Move Wbs
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </form>
      <MoveItem folder={folder} onHide={() => setShowMoving(false)} show={showMoving} />

      <Modal size="xl" show={showwbs} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{folder.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!folder ? (
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border text-primary mt-5"
                role="status"
                style={{ width: '3rem', height: '3rem' }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>) : (
            <WpData wpData={wbsData} />
          )}
        </Modal.Body>
      </Modal>

      <Modal size="xl" show={wbsShow} onHide={handleCloseWbs}>
        <Modal.Header closeButton>
          <Modal.Title>{folder.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingData ? (
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border text-primary mt-5"
                role="status"
                style={{ width: '3rem', height: '3rem' }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>) : (
            <WbsData wbsData={wbsData} handleUpdateWbsDataDate={handleUpdateWbsDataDate} />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
