import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import EacFormulaSelection from '../../settings/EacFormulaSelection'
import ForecastFormulaSelection from '../../settings/ForecastFormulaSelection'
import {
  ForecastSettingsFormType,
  getForecastSettingsValidationSchema,
  getInitForecastSettings,
} from '../_models'
import ColorInputWithLabel from '../../../../../components/inputWithLabel/ColorInputWithLabel'
import { useProject } from '../../../core/ProjectContext'
import { changeForecastType } from '../../../../../utils/forecast'
import { useDispatch } from 'react-redux'
import { setVersionData } from '../../../../wbs/treeVersionData'

type Props = { onHide: () => void }

export default function ForecastSettingsForm({ onHide }: Props) {
  const { displayVersion, setVersion, setOriginalVersion, originalVersion, project } = useProject()
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch();

  const updateVersionState = (version: any) => {
    dispatch(setVersionData(version))
    formik.setValues(getInitForecastSettings(version))
  }

  const formik = useFormik<ForecastSettingsFormType>({
    initialValues: getInitForecastSettings(displayVersion),
    enableReinitialize: true,
    validationSchema: getForecastSettingsValidationSchema(displayVersion),
    onSubmit: (values) =>
      changeForecastType(
        values,
        displayVersion,
        originalVersion,
        project,
        setVersion,
        setOriginalVersion,
        setLoading,
        onHide,
        updateVersionState
      ),
  })

  return (
    <>
      <Modal.Body>
        <h2>Forecast Settings</h2>
        <EacFormulaSelection formik={formik} />
        <ForecastFormulaSelection formik={formik} sector={project?.sector} />
        <h2 className='mb-5'>Chart Settings</h2>
        <ColorInputWithLabel
          error={formik.errors.plannedColor}
          formikProps={formik.getFieldProps('plannedColor')}
          label='Planned Value Color'
          touched={formik.touched.plannedColor}
          value={formik.values.plannedColor}
        />
        <ColorInputWithLabel
          error={formik.errors.earnedValueColor}
          formikProps={formik.getFieldProps('earnedValueColor')}
          label='Earned Value Color'
          touched={formik.touched.earnedValueColor}
          value={formik.values.earnedValueColor}
        />
        <ColorInputWithLabel
          error={formik.errors.actualCostColor}
          formikProps={formik.getFieldProps('actualCostColor')}
          label='Actual Cost Color'
          touched={formik.touched.actualCostColor}
          value={formik.values.actualCostColor}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => formik.handleSubmit()}
          disabled={loading || !formik.dirty || !formik.isValid}
          className='btn btn-primary'
        >
          {loading ? (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            'Save'
          )}
        </button>
      </Modal.Footer>
    </>
  )
}
