import { DatePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { getFormattedDate } from '../../helpers/DateFormatter'
import { DateUnit } from '../../modules/projects/components/pagination/header/ProjectDetailsHeader'
import { useProject } from '../../modules/projects/core/ProjectContext'
import { ProjectObject } from '../../modules/projects/core/_models'
import { Tooltip } from 'antd';
import ProjectTimelineChart2 from './ProjectTimelineChart2'
import DashboardSettingsModal from '../../modules/projects/components/overview/modals/DashboardSettingsModal'
import { useTree } from '../../modules/wbs/components/TreeView/DashboardChartTreeContext'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { getVersionById } from '../../modules/projects/core/_requests'
import { setVersionData } from '../../modules/wbs/treeVersionData'
import { combineVersionsV2, combineVersionsV2WithoutAlert } from '../../modules/wbs/components/TreeView/_helpers'
import { incrementLoaded, updateLabel, updateLoaded, updateShow, updateTotal } from '../../modules/wbs/treeLoader'
import type { DataNode } from 'antd/es/tree'
import { getWbs } from '../../modules/wbs/_requests'
import { addWbsToStore, updateWbsCalculatedData, updateWpsCalculatedData } from '../../modules/wbs/treedata'
import TreeFolderItem from '../../modules/wbs/components/TreeView/TreeFolderItem'
import ChartTreeFolderItem from '../../modules/wbs/components/TreeView/ChartTreeFolderItem'
import GlobalDashboardSettingsModal from '../../modules/projects/components/overview/modals/GlobalDashboardSettingsModal'

interface Props {
  projects: ProjectObject[]
  changeProject: (id: string, name: string, data_date: Date) => void
  selectedId: any
  selectedDataDate: Date;
  setSelectedDataDate: any;
}


export default function Chart2({ projects: projectsData, changeProject, selectedId, selectedDataDate, setSelectedDataDate }: Props) {
  const [projects, setProjects] = useState<ProjectObject[]>([]);
  const [plottedProjects, setPlottedProjects] = useState<any>([])
  const [datePicker, setDatePicker] = useState<boolean>(false)
  const [isCalculated, setIsCalculated] = useState<boolean>(false)
  const [dataDateDates, setDataDateDates] = useState<Date[]>([])
  const [interval, setInterval] = useState(6);
  const [selectedLabel, setSelectedLabel] = useState("6 Months");
  const [selectedProjectIds, setSelectedProjectIds] = useState<Set<string>>(new Set());
  const { setDashboardDataDate, setDataDate, originalVersion: data, project: projectData, version ,project,setProjectDashboardDataDate, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync,} = useProject()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false);
  const initialProjectVersionData = useSelector((state: RootState) => state.versiondata.data)
  const isTreeLoaded = useSelector((state: RootState) => state.treedata.isTreeLoaded);
  const { expandedKeys, treeData } = useTree();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const projectversiondata = useSelector((state: RootState) => state.versiondata.data)
  const wpsCalculatedData = useSelector((state: RootState) => state.treedata.wpsCalculatedData);
  const wbsCalculatedData = useSelector((state: RootState) => state.treedata.wbsCalculatedData);
  const dispatch = useDispatch();

 

  // useEffect(() => {

  //   const initiateDataDateSetup = async () => {
  //     if (projectData && projects.length > 0) {
  //       await setDashboardDataDate(selectedDataDate, projects, setProjects);
  //     }
  //   };

  //   initiateDataDateSetup();
  // }, [selectedDataDate]);

  useEffect(() => {

    setPlottedProjects([]);

    projects
      // .filter(
      //   project => selectedProjectIds.has(project._id.$oid))
      .forEach(project => {
        addProjectToChart(project);
      });


    setPlottedProjects((prev: any) => [
      {
        id: '',
        name: '',
        graphEndDate: [selectedDataDate.getTime(), selectedDataDate.getTime()],
        graphEstimatedEndDate: [selectedDataDate.getTime(), selectedDataDate.getTime()],
        brokenLine: selectedDataDate.getTime(),
      },
      ...prev,
      {
        id: 'dsd',
        name: '  ',
        graphEndDate: [selectedDataDate.getTime(), selectedDataDate.getTime()],
        graphEstimatedEndDate: [selectedDataDate.getTime(), selectedDataDate.getTime()],
        brokenLine: selectedDataDate.getTime(),
      }
    ]);

    console.log("projects ==>")
    console.log(projects)

  }, [projects]);


  const fetchProjectData = useCallback(async () => {

    let projectversiondata: Record<string, any> = JSON.parse(JSON.stringify(initialProjectVersionData));
  
    wpsCalculatedData.forEach((data) => {
      projectversiondata[data._id.$oid] = data;
    });
    wbsCalculatedData.forEach((data) => {
      projectversiondata[data._id.$oid] = data;
    });
  
    if (isCalculated) {
      try {
        setLoading(true); // Start loading
        const expandedKeysSet = new Set(expandedKeys);
  
        // Define shownWbs to include only the current WBS if it's not in expandedKeys
        let shownWbs: any[] = [];
  
        // Existing filtering logic with a Set to avoid duplicates
        const uniqueWbs = new Set<string>();
  
        shownWbs = expandedKeys.filter(w => {
          const wbs = knownWbs[w];
          const parentOid = wbs?.parent_wbs?.$oid;
          if (wbs && parentOid && expandedKeysSet.has(parentOid) && !uniqueWbs.has(w)) {
            uniqueWbs.add(w); // Add to the set to keep track of seen WBS
            return true;
          }
          return false;
        });
  
        shownWbs.forEach(w => {
          const wbs = knownWbs[w];
          if (wbs && wbs.subwbs) {
            wbs.subwbs.forEach(subWbs => {
              if (!uniqueWbs.has(subWbs.$oid)) {
                uniqueWbs.add(subWbs.$oid); // Add to the set to keep track of seen WBS
                shownWbs.push(subWbs.$oid);
              }
            });
          }
        });
  
        const order = extractTreeOrder(treeData);
        shownWbs.sort((a, b) => order.indexOf(a) - order.indexOf(b));
  
        const filteredprojectsData = shownWbs.filter(w => knownWbs[w]?.data?.$oid);
  
        const mappedData = await Promise.all(
          filteredprojectsData.map(async (w) => {
            if (projectversiondata[knownWbs[w].data.$oid]) {
              return {
                ...projectversiondata[knownWbs[w].data.$oid],
                name: knownWbs[w].name,
                isWbs: knownWbs[w].subwbs && knownWbs[w].subwbs.length !== 0
              };
            } else {
              const res = await getVersionById(knownWbs[w].data.$oid);
              const data = {
                ...res,
                name: knownWbs[w].name,
                isWbs: knownWbs[w].subwbs && knownWbs[w].subwbs.length !== 0
              };
              dispatch(setVersionData({ id: data._id.$oid, data: data }));
              return data;
            }
          })
        );
        
        let validProjectsData = mappedData.filter(data => data !== undefined);
        validProjectsData = validProjectsData.map((project: any) => {
          if (project && project.output && project.output.CardsData && project.output.CardsData.SchedulePerformance) {
            const eedValue = project.output.CardsData.SchedulePerformance.EED?.Value;
            if (eedValue) {
              project = {
                ...project,
                output: {
                  ...project.output,
                  estimatedEndDate: eedValue
                }
              }
            }
          }
          return project;
        });
  
        const eedValues = validProjectsData.map((p) => {
          if (p && p.output && p.output.estimatedEndDate)
            return p?.name + "= " + p.output.estimatedEndDate
          return p?.name + "= " + 'no eed'
        });
  
        await setProjectDashboardDataDate(selectedDataDate, validProjectsData, setProjects);
        setSelectedProjectIds(new Set(validProjectsData.map(p => p._id.$oid)));
        
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // End loading
      }
    }
  }, [expandedKeys, wpsCalculatedData, wbsCalculatedData, isCalculated, initialProjectVersionData]);
  

useEffect(() => {
    fetchProjectData();
}, [fetchProjectData]);


  
  useEffect(() => {

    const updateProjects = async () => {
      if (projectData && projects.length > 0) {
        await setProjectDashboardDataDate(selectedDataDate, projects, setProjects);
      }
    };
    handleDataDateChange(dayjs(selectedDataDate.getTime()))
    updateProjects()
    setDataDate(selectedDataDate, true)
    // Update dependent on selectedDataDate, likely needs to modify specific properties only
    setPlottedProjects((prevProjects: any) => prevProjects.map((project: any) => ({
      ...project,
      brokenLine: project.graphEstimatedEndDate[1] === project.graphEndDate[1]
        ? selectedDataDate.getTime()
        : selectedDataDate.getTime() - (project.graphEstimatedEndDate[1] - project.graphEndDate[1]),
    })));
  }, [selectedDataDate]);




  useEffect(() => {
    if (plottedProjects[0]) {
      const result = findMinMaxDates(plottedProjects)
      if (result) {
        setDataDateDates([result?.minStartDate, result?.maxEndDate])
      }
    }

    console.log("plottedProjects ==>")
    console.log(plottedProjects)
  }, [plottedProjects])

  useEffect(() => {

    let firstValidMonth = false
    const intervals = [1, 3, 6, 12]

    if (interval !== 12 && !checkInterval(dataDateDates[0], dataDateDates[1], interval)) {
      intervals.map(months => {

        const isAppropriate = months === 12 ? true : dataDateDates.length === 2
          ? checkInterval(dataDateDates[0], dataDateDates[1], months)
          : false;

        if (!firstValidMonth && isAppropriate) {
          setInterval(months);
          const monthLabel = months === 1 ? "1 Month" : months + " Months"
          setSelectedLabel(monthLabel);
          firstValidMonth = true;
        }
      });
    }

  }, [plottedProjects, dataDateDates])



  // if (!projects[0]) return <div>empty</div>
  const handleClick = (project: ProjectObject) => {
    setPlottedProjects((prevProjects: any) => {
      const isProjectPlotted = prevProjects.some(
        (projected: any) => projected.id === project._id.$oid
      )

      const newSelectedProjectIds = new Set(selectedProjectIds);
      if (isProjectPlotted) {
        removeProjectFromChart(project)
        newSelectedProjectIds.delete(project._id.$oid);
        setSelectedProjectIds(newSelectedProjectIds);

        const result = prevProjects.filter((value: any) => value.id !== project._id.$oid)
        if (result.length === 2) {
          setSelectedDataDate(new Date());
        } else {
          const minMax = findMinMaxDates(result)
          if (selectedDataDate.getTime() < minMax?.minStartDate || selectedDataDate.getTime() > minMax?.maxEndDate) {
            setSelectedDataDate(new Date(result[1].dataDate))
          }
          if (project._id.$oid === selectedId.id) changeProject(result[1].id, result[1].name, selectedDataDate)
        }

        return result
      }

      else {
        addProjectToChart(project)
        setSelectedProjectIds(newSelectedProjectIds);
        if (prevProjects.length == 2 && project && project.default_version) {
          setSelectedDataDate(new Date(project.default_version.data_date.$date))
          changeProject(project._id.$oid, project.name, new Date(project.default_version.data_date.$date))
        }

        newSelectedProjectIds.add(project._id.$oid);
        return [...prevProjects, { name: project._id.$oid }]

      }
    })
  }

  const removeProjectFromChart = (project: ProjectObject) => {
    setPlottedProjects((prev: any) => {
      let newArray = [...prev]
      newArray = newArray.filter((value: any) => value.id !== project._id.$oid)
      return newArray
    })
  }

  const addProjectToChart = (project: ProjectObject) => {
    const defaultVersion = project
    if (!defaultVersion) return
    let startDate = new Date(defaultVersion.start_date.$date).getTime()
    let realStartDate = defaultVersion?.real_start_date ? defaultVersion.real_start_date : new Date(defaultVersion.start_date.$date).getTime();
    let endDate = new Date(defaultVersion.end_date.$date).getTime()
    let estimatedEndDate = defaultVersion.output && defaultVersion.output.estimatedEndDate ? parseFrenchDate(defaultVersion.output.estimatedEndDate) : endDate
    let dataDate = new Date(defaultVersion.data_date.$date).getTime()

    setPlottedProjects((prev: any) => {
      const projectToAdd = {
        id: project._id.$oid,
        name: project.name,
        forecastable: project.forecastable,
        forecast_msg: project.forecast_msg,
        graphEndDate: [startDate, endDate],
        graphEstimatedEndDate: [realStartDate, estimatedEndDate],
        dataDate: dataDate,
        brokenLine: estimatedEndDate === endDate ? selectedDataDate.getTime() :
          selectedDataDate.getTime() - (estimatedEndDate - endDate),
        isWbs: project.isWbs
      };

      return [...prev, projectToAdd];
    });
  }

  const handleIntervalChange = (item: any) => {
    setInterval(item.value);
    setSelectedLabel(item.label);
  };

  function checkInterval(minDate: any, maxDate: any, intervalMonths: number) {
    const totalMonths = dayjs(maxDate).diff(dayjs(minDate), 'months');
    const ticksCount = Math.ceil(totalMonths / intervalMonths);
    const requiredSpace = ticksCount * 50;
    return requiredSpace < 1080;
  }

  const intervals = [1, 3, 6, 12].map(months => {
    const isAppropriate = months === 12 ? true : dataDateDates.length === 2
      ? checkInterval(dataDateDates[0], dataDateDates[1], months)
      : false;

    return {
      value: months,
      label: `${months} Month${months > 1 ? 's' : ''}`,
      disabled: !isAppropriate,
      message: !isAppropriate ? `The ${months}-month interval makes the chart too crowded.` : ""
    };
  });




  const collectLeafData = async (node: string): Promise<any[]> => {
    const leafData: any[] = []
    const traverse = async (currentNode: string) => {
      const nodeValue = knownWbs[currentNode]
  
      if (!nodeValue?.subwbs || nodeValue?.subwbs?.length === 0) {
        if (nodeValue?.data) {
        let versionData = projectversiondata[nodeValue.data.$oid]
        if (!versionData) {
          let fetchedData = await getVersionById(nodeValue.data.$oid)
          fetchedData = { ...fetchedData, name: nodeValue.name }
          dispatch(setVersionData({ id: nodeValue.data.$oid, data: fetchedData }))
          versionData = fetchedData
        }
        leafData.push(versionData)
      }
      } else {
        for (const child of nodeValue.subwbs) {
  
          const childWbsData = knownWbs[child.$oid] || (await getWbs(child?.$oid))
          if (!knownWbs[child?.$oid]) {
            dispatch(addWbsToStore(childWbsData))
          }
          if (childWbsData?.data)
          await traverse(childWbsData._id.$oid)
        }
      }
  
    }
    await traverse(node as string)
    return leafData
  } 
  
  
  const getTree = async (): Promise<DataNode[]> => {
    const allKeys: string[] = [];
    if (!treeData[0].children) return []
    const rootWbsIds = treeData[0].children.map((node: any) => node.key);
  
    // Helper function to fetch WBS recursively
    const fetchWbsRecursively = async (wbsId: string): Promise<DataNode> => {
      let knownWbsItem = knownWbs[wbsId];
      if (!knownWbsItem) {
        const response = await getWbs(wbsId);
        dispatch(addWbsToStore(response)); // Store the WBS in Redux
        knownWbsItem = response;
      }
  
      // Add the current WBS ID to the list of all keys
      allKeys.push(knownWbsItem._id.$oid);
  
      // Recursively fetch the children of this WBS
      const childrenData: DataNode[] = await Promise.all(
        knownWbsItem.subwbs.map(async (subWbs) => {
          return await fetchWbsRecursively(subWbs.$oid); // Recursive call for each child
        })
      );
  
      // Return the current WBS node with its children
      return {
        key: knownWbsItem._id.$oid,
        title: <ChartTreeFolderItem folder={knownWbsItem} />,
        children: childrenData,
      };
    };
  
    // Fetch the full tree starting from the root WBS nodes found in `treeData`
    const rootNodes = await Promise.all(
      rootWbsIds.map(async (rootWbsId: string) => {
        return await fetchWbsRecursively(rootWbsId); // Fetch the tree for each root node
      })
    );
  
    // Return the full tree with all root nodes and their children
    return rootNodes;
  };
  
  

  const handleDataDateChange = async (date: dayjs.Dayjs) => {
    setIsCalculated(false)
    setDatePicker(false);
    dispatch(updateLabel('Calculating WBS Data'));
    dispatch(updateTotal(2));
    dispatch(updateLoaded(0));

    // Wait for a short moment to ensure the date picker has closed completely
    await new Promise((resolve) => setTimeout(resolve, 300));
    dispatch(updateShow(true));


    const wbsListData = await calculateCombinedVersionForEachWbs(date.toDate())

    dispatch(updateWbsCalculatedData(wbsListData));

    dispatch(updateShow(false));
    setIsCalculated(true)
  }

  
  const calculateCombinedVersionForEachWbs = async (data_date: Date): Promise<any[]> => {
  
    const wbsList: string[] = [];
    const wbsListData: any[] = [];
  
    const tree = await getTree()
  
    const findParent = (node: DataNode): void => {
      if (node.children) {
        for (const child of node.children) {
          if (child.children && child.children.length > 0) {
            wbsList.push(child.key as string);
          }
          findParent(child);
        }
      }
    };
    
    if(tree && tree.length > 0)
      tree.map((t) => {
        wbsList.push(t.key as string)
        findParent(t)
  })
    
    dispatch(updateLoaded(0));
    dispatch(updateTotal(wbsList.length));
    dispatch(updateShow(wbsList.length !== 0));
    dispatch(updateLabel('Calculating Dashboard Data...'));
  

    for (const [index, node] of wbsList.entries()) {
      dispatch(updateLabel('Calculating Dashboard Data ' + (index + 1) + ' of ' + wbsList.length));
  
      const leafData = await collectLeafData(node);
      let combinedVersion = await combineVersionsV2WithoutAlert(
        knownWbs[node].name, 
        leafData, true, 
        setWbsDataDateFirstStepAsync, 
        setWbsDataDateSecondStepAsync, 
        data_date, 
        (wpsData) => dispatch(updateWpsCalculatedData(wpsData))
      );
      combinedVersion = { ...combinedVersion, _id: { $oid: knownWbs[node]?.data?.$oid } };
      wbsListData.push(combinedVersion);
      dispatch(incrementLoaded());
    }

    dispatch(updateShow(false));
    return wbsListData;
  };


  return (
    <>
      <div>
        <div className='card-header '>
          <div className='card-title d-flex justify-content-between w-100'>
            <div className='card-title mr-auto p-2'>
              <span
                className=' fw-bold text-dark  d-flex align-items-start gap-2'
                style={{ fontSize: '16px' }}
              >
                <img
                  className='w-24px h-24px '
                  src={toAbsoluteUrl('/media/svg/card-logos/overview.svg')}
                  alt='Overview logo'
                />
                <div>
                  <div>Projects Overview</div>
                  <div className='overview-description'>
                    Get a global overview over you favourite projects

                  </div>
                </div>
              </span>
            </div>
            <div className='card-title ml-auto p-2 gap-2'>
              <Dropdown className='my-5 dropdown-custom' autoClose='outside'>
                <Dropdown.Toggle className='calendar-dropdown'>
                  <img
                    className='w-24px h-24px'
                    src={toAbsoluteUrl('/media/svg/card-logos/calendar.svg')}
                    alt='Dashboard logo'
                  />
                  <span style={{ color: 'var(--bs-btn-color)' }}>
                    {selectedLabel}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className='dropdown-projects'>
                  {intervals.map((item) => (
                    <Tooltip title={item.disabled ? item.message : ""} key={item.value} placement="right">
                      <Dropdown.Item
                        className={`d-flex align-items-center fs-5 gap-2 p-3 ${item.label === selectedLabel ? 'selected-interval' : ''}`}
                        onClick={() => { if (!item.disabled) handleIntervalChange(item); }}
                        style={{ opacity: item.disabled ? 0.5 : 1 }}

                      >
                        <div className='btn btn-outline form-check form-check-custom me-6 p-3 w-100'>
                          <label className='form-label fs-5'>{item.label}</label>
                        </div>
                      </Dropdown.Item>
                    </Tooltip>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className='my-5 dropdown-custom  ' autoClose='outside'>
                <Dropdown.Toggle className='calendar-dropdown'>
                  <img
                    className='w-24px h-24px'
                    src={toAbsoluteUrl('/media/svg/card-logos/project-color.svg')}
                    alt='Dashboard logo'
                  />
                  <span
                    style={{
                      color: 'var(--bs-btn-color)',
                    }}
                  >
                    {' '}
                    Choose Projects
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className='dropdown-projects'>
                  {projects
                    // .filter((item, index) => index < 7)
                    .map((item) => (
                      <Dropdown.Item
                        className='d-flex align-items-center fs-5 gap-2 p-3'
                        onClick={() => handleClick(item)}
                        key={item._id.$oid}
                      >
                        <div className='btn btn-outline form-check form-check-custom me-6 p-3 w-100'>
                          <input
                            className='form-check-input'
                            checked={plottedProjects.some(
                              (projected: any) => projected.id === item._id.$oid
                            )}
                            type='checkbox'
                            readOnly
                          />
                          <label className='form-check-label fs-5'> {item.name} </label>
                        </div>
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
              <div className='d-flex flex-column '>
                <div className='d-flex flex-wrap'>
                  <DatePicker
                    cellRender={(current: Dayjs, info: any) => {
                      if (
                        current.isBefore(dayjs(dataDateDates[0])) ||
                        current.isAfter(dayjs(dataDateDates[1]))
                      )
                        return '--'
                      return (
                        <div
                        // className={clsx(
                        //   (data?.data_date.$date || 0) >= current.toDate().getTime()
                        //     ? 'highlighted'
                        //     : ''
                        // )}
                        >
                          {info.originNode}
                        </div>
                      )
                    }}
                    className='invisible w-0 h-0 position-absolute p-0'
                    picker={data?.period_count?.type === 'daily' ? 'date' : 'month'}
                    value={dayjs(selectedDataDate)}
                    onOpenChange={(open) => setDatePicker(open)}
                    open={datePicker}
                    onChange={async (date) => {
                      if (
                        date &&
                        (date.isSame(dayjs(dataDateDates[0])) || date.isAfter(dayjs(dataDateDates[0]))) &&
                        (date.isSame(dayjs(dataDateDates[1])) || date.isBefore(dayjs(dataDateDates[1])))
                      ) {
                        setSelectedDataDate(date.toDate())

                      }
                      setDatePicker(false)
                    }}
                  />
                  <div
                    onClick={() => setDatePicker((prev) => !prev)}
                    className='calendar-dropdown btn '
                  >
                    <img
                      className='w-24px h-24px'
                      src={toAbsoluteUrl('/media/svg/card-logos/calendar.svg')}
                      alt='Dashboard logo'
                    />
                    <div className='d-flex align-items-center'>
                      <div className=''>
                        {getFormattedDate(
                          selectedDataDate,
                          data?.period_count?.type as DateUnit,
                          data?.date_format
                        )}
                      </div>
                    </div>
             

                    {/* <div className='fw-bold fs-6 text-gray-400'>Data Date</div> */}
                  </div>
                  <div className='d-flex align-items-center gap-2'>
          <i
            onClick={() => setShowModal(true)}
            className='bi bi-gear text-hover-primary d-flex justify-content-end cursor-pointer ms-2'
            onMouseOver={(e) => (e.currentTarget.style.opacity = '0.75')}
            onMouseOut={(e) => (e.currentTarget.style.opacity = '1')}
            style={{fontSize: '2rem', transition: 'all 0.2s ease-in-out'}}
          ></i>
          <GlobalDashboardSettingsModal show={showModal} onHide={() => setShowModal(false)} />
        </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div ref={chartRef.elRef}></div> */}

      <div className='card-body'>
        {
          !plottedProjects[0] ? (
            <div>no projects selected, please select one</div>
          ) : (
            <ProjectTimelineChart2
          
                       projects={plottedProjects}
                        treeData={treeData}
                        dataDate={selectedDataDate}
                        interval={interval}
                        loading={loading}
            />
          )
        }
      </div>
    </>
  )
}

function extractTreeOrder(treeData: any[]) {
  const order: any[] = [];
  function traverse(node: { key: any; children: any[]; }) {
      order.push(node.key);
      if (node.children) {
          node.children.forEach((child: any) => traverse(child));
      }
  }
  treeData.forEach(node => traverse(node));
  return order;
}

function findMinMaxDates(dateArray: any) {
  if (dateArray && dateArray.length <= 2) {
      return null;
  }

  let minStartDate = dateArray[1].graphEndDate[0];
  let maxEndDate = dateArray[1].graphEndDate[1];

  for (const entry of dateArray) {
      if (entry.id !== '' && entry.id !== 'dsd') {
          const startDate = entry.graphEndDate[0];
          const endDate = entry.graphEndDate[1];
          const dataDate = entry.dataDate;

          if (startDate < minStartDate) {
              minStartDate = startDate;
          }

          if (endDate > maxEndDate) {
              maxEndDate = endDate;
          }

          if (dataDate > maxEndDate) {
              maxEndDate = dataDate;
          }
      }
  }

  return { minStartDate, maxEndDate };
}

// Map of French month names to month numbers (0-based for JavaScript Date object)
const frenchMonths: { [key: string]: number } = {
  "janv.": 0, "févr.": 1, "mars.": 2, "avr.": 3, "mai.": 4, "juin.": 5,
  "juil.": 6, "août.": 7, "sept.": 8, "oct.": 9, "nov.": 10, "déc.": 11,
  "janvier": 0, "février": 1, "mars": 2, "avril": 3, "mai": 4, "juin": 5,
  "juillet": 6, "août": 7, "septembre": 8, "octobre": 9, "novembre": 10, "décembre": 11
};

// Function to parse French date string to timestamp
function parseFrenchDate(dateStr: string) {
  // Split the date string into components (assuming "month year" format)
  const parts = dateStr.trim().split(' ');
  if (parts.length !== 2) return NaN; // Invalid format

  // Extract the month and year
  const monthStr = parts[0].toLowerCase(); // Normalize to lowercase
  const year = parseInt(parts[1], 10);

  // Find the month number from the map
  const month = frenchMonths[monthStr];
  if (month === undefined || isNaN(year)) return new Date(dateStr).getTime();

  // Create and return the timestamp
  return new Date(year, month, 1).getTime();
}