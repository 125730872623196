import { DataNode } from 'antd/es/tree';
import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { WithChildren } from '../../../../../_metronic/helpers';
import { getWbs } from '../../_requests';
import { useDispatch, useSelector } from 'react-redux';
import { addWbsToStore } from '../../treedata';
import { RootState } from '../../../../store';
import ChartTreeFolderItem from './ChartTreeFolderItem';

// Update the type to include projectsData
type TreeViewProps = {
  treeData: DataNode[];
  setTreeData: Dispatch<SetStateAction<DataNode[]>>;
  expandedKeys: string[];
  setExpandedKeys: Dispatch<SetStateAction<string[]>>;
  isLoading: boolean;
  setDisplayNodeNumber: Dispatch<SetStateAction<boolean>>;
  displayNodeNumber: boolean;
};

// Initialize context default values
const initTreeViewProps: TreeViewProps = {
  treeData: [],
  setTreeData: () => { },
  expandedKeys: [],
  setExpandedKeys: () => { },
  isLoading: true,
  setDisplayNodeNumber: () => { },
  displayNodeNumber: false,
};

// Create context with the updated type
const TreeContext = createContext<TreeViewProps>(initTreeViewProps);

// Custom hook for using the context
const useTree = () => useContext(TreeContext);

interface TreeProviderProps extends WithChildren {
  projectsData: any[]; 
}

const TreeProvider: FC<TreeProviderProps> = ({ children, projectsData }) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [displayNodeNumber, setDisplayNodeNumber] = useState(false);

  const dispatch = useDispatch();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);

  useEffect(() => {
    const loadAllWbs = async (wbsId: string, parentWbsOid: string | null = null): Promise<DataNode | void> => {
      let knownWbsItem = knownWbs[wbsId];
      if (!knownWbsItem) {
        const response = await getWbs(wbsId);
        
        // If a parent WBS is provided, update the response with its parent_wbs?.$oid
        if (parentWbsOid) {
          response.parent_wbs = { $oid: parentWbsOid };
        }
        
        dispatch(addWbsToStore(response)); // Dispatch after updating parent_wbs
        knownWbsItem = response;
      }

      const childrenData: any = await Promise.all(
        knownWbsItem.subwbs.map(async (subWbs) => {
          // Recursively load children WBS and pass the current WBS's oid as parentWbsOid
          return await loadAllWbs(subWbs.$oid, knownWbsItem._id.$oid);
        })
      );

      if (knownWbsItem.data) {
        return {
          key: knownWbsItem._id.$oid,
          title: <ChartTreeFolderItem folder={knownWbsItem} />,
          children: childrenData.filter((item: any) => item),
        };
      }
    };

    const initializeTree = async () => {
      const rootNodes: DataNode[] = [];

      // Load the WBS for each project and add it to rootNodes
      for (const project of projectsData) {
        const defaultWbs = project.default_wbs?.$oid;
        if (defaultWbs) {
          // For WBS directly under the root, we pass 'root' as the parentWbsOid
          const projectNode = await loadAllWbs(defaultWbs, 'root');
          if (projectNode) {
            rootNodes.push(projectNode);
            setExpandedKeys((keys) => [...keys, projectNode.key.toString()]);
          }
        }
      }

      // Create a root element containing all project nodes
      const rootNode: DataNode = {
        key: 'root',  // Unique key for the root element
        title: 'Project Root',  // Title for the root element
        children: rootNodes,    // All project WBS nodes as children
      };

      // Set the treeData to only contain the root node
      setTreeData([rootNode]);
      setIsLoading(false);
    };

    initializeTree();
  
  }, [projectsData]);

  return (
    <TreeContext.Provider value={{ expandedKeys, isLoading, setExpandedKeys, setTreeData, treeData, displayNodeNumber, setDisplayNodeNumber }}>
      {children}
    </TreeContext.Provider>
  );
};

export { TreeProvider, useTree };
