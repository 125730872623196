import { Tree } from 'antd'
import type { DataNode, EventDataNode } from 'antd/es/tree'
import { createWbs, getWbs, patchWbs } from '../../_requests'
import TreeFolderItem from './TreeFolderItem'
import {
  appendTreeData,
  buildFullPath,
  combineVersionsV2,
  deleteTreeNode,
  moveTreeNode,
  updateTreeData,
} from './_helpers'
import { useTree } from './TreeContext'
import SwitcherIcon from './icons/SwitcherIcon'
import DraggableIcon from './icons/DraggableIcon'
import { showError, sweetAlert } from '../../../../utils/funcs'
import TreeViewHeader from './TreeViewHeader'
import WpData from '../../../wp/components/WpData'
import { Row, Col } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useProject } from '../../../projects/core/ProjectContext'
import WbsData from '../../../wp/components/WbsData'
import { useSelector, useDispatch } from 'react-redux'
import {
  close,
  loading,
  loaded,
  isheader,
  isntheader,
  setmaxdatadate,
  setcurrentwbs,
} from '../../treeStates'
import { addWbsToStore, updateCollectedLeafData, updateIsTreeLoaded, updateWbsCalculatedData } from '../../treedata'
import { RootState } from '../../../../store'
import { setVersionData } from '../../treeVersionData'
import { updateLabel, updateLoaded, updateShow, updateTotal } from '../../treeLoader'
import React from 'react'
import { createVersion, deleteVersion, getVersionById, patchVersion, setDefaultVersion } from '../../../projects/core/_requests'
import WpCreateData from '../../../wp/components/WpCreateData'
import { Wbs } from '../../_models'


interface TreeNodeProps {
  folder: {
    _id: { $oid: string }
    name: string
    parent_wbs?: { $oid: string }
    data: { $oid: string }
    subwbs: any
  }
}

type WbsType = {
  _id: { $oid: string };
  name: string;
  parent_wbs?: { $oid: string };
  subwbs: { $oid: string }[];
  rank: number;
  user: { $oid: string };
  milestones: any[];
  hierarchy: any[];
  data?: { $oid: string }
};

interface Props {
  isProjectHeader?: boolean
}
const TreeView: React.FC<Props> = ({ isProjectHeader = false }) => {
  const { expandedKeys, setExpandedKeys, setTreeData, treeData } = useTree()
  const {
    setProject,
    setOriginalVersion,
    setVersion,
    setWbsDataDateFirstStepAsync,
    setWbsDataDateSecondStepAsync,
    project,
    originalVersion,
  } = useProject()
  const [wpCreateShow, setWpCreateShow] = useState(false)
  const [wpShow, setWpShow] = useState(false)
  const [wbsShow, setWbsShow] = useState(false)
  const [wbsData, setWbsData] = useState<any | null>(null)
  const [wbsId, setWbsId] = useState<string>(project?.current_wbs)
  const [wpName, setWpName] = useState<string>(project?.current_wbs)
  const dispatch = useDispatch()
  const isrenaming = useSelector((state: RootState) => state.treerenaming.value)
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs)
  const [currentPath, setCurrentPath] = useState<string>('')
  const collectedLeafData = useSelector((state: RootState) => state.treedata.collectedLeafData)
  const projectversiondata = useSelector((state: RootState) => state.versiondata.data)
  const showLoading = useSelector((state: RootState) => state.treeLoader.show);
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [loadTreeCompleted, setLoadTreeCompleted] = useState<boolean>(false);

  const getLastSegment = (path: string) => {
    const segments = path.split('/')
    return segments[segments.length - 1]
  }

  useEffect(() => {
    isProjectHeader ? dispatch(isheader()) : dispatch(isntheader())
  }, [isProjectHeader])

  useEffect(() => {

    const initializeNode = async () => {
      const currentWbsId = project?.current_wbs
      if (!currentWbsId) return
      const currentWbs = knownWbs[currentWbsId] || (await getWbs(currentWbsId))
      if (!knownWbs[currentWbsId]) {
        dispatch(addWbsToStore(currentWbs))
      }

      const targetNodeId = currentWbs.last_opened_wbs
        ? currentWbs.last_opened_wbs.$oid
        : currentWbsId

      const nodesMap = new Map<string, DataNode>()
      const buildMap = (nodes: DataNode[], map: Map<string, DataNode>) => {
        nodes.forEach((node) => {
          map.set(node.key as string, node)
          if (node.children) {
            buildMap(node.children, map)
          }
        })
      }
      buildMap(treeData, nodesMap)

      const targetNode = nodesMap.get(targetNodeId)

      const isSlash = project?.name.includes('/')

      if (targetNode && (knownWbs[targetNodeId]?.name != getLastSegment(project?.name) || !isSlash)) {
        dispatch(updateShow(true))
        const fullPath = buildFullPath(targetNode as EventDataNode<DataNode>, nodesMap)
        setCurrentPath(fullPath)

        const leafData = await collectLeafData(targetNodeId);
        dispatch(updateCollectedLeafData(leafData));


        let combinedVersion: any;
        if (knownWbs[targetNodeId]?.data) {
          let versionData = projectversiondata[knownWbs[targetNodeId]?.data?.$oid];
          if (!versionData) {
            let fetchedData = await getVersionById(knownWbs[targetNodeId]?.data?.$oid);
            fetchedData = { ...fetchedData, name: knownWbs[targetNodeId].name };
            dispatch(setVersionData({ id: knownWbs[targetNodeId]?.data?.$oid, data: fetchedData }));
            versionData = fetchedData;
          }

          combinedVersion = versionData
        } else {

          combinedVersion = await combineVersionsV2(knownWbs[targetNodeId].name, leafData, true, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync);
        }

        combinedVersion = {
          ...combinedVersion,
          _id: { $oid: knownWbs[targetNodeId]?.data?.$oid },
        };

        setWbsData(combinedVersion);

        setOriginalVersion(combinedVersion)
        setVersion(combinedVersion)
        const folderName = fullPath
        setProject((prev) => {
          if (prev) {
            const [baseName, existingFolderName] = prev.name.split('/')
            const newName = existingFolderName
              ? `${baseName}/${folderName}`
              : `${prev.name}/${folderName}`
            return { ...prev, name: newName }
          }
          return prev
        })

        dispatch(updateShow(false))
      }
    };


    const runAsyncTasks = async () => {
      dispatch(updateIsTreeLoaded(false))

      if (project?.current_wbs && loadTreeCompleted) {
        await initializeNode();
      }

      dispatch(updateIsTreeLoaded(true))

    };

    runAsyncTasks();


  }, [project?.current_wbs, loadTreeCompleted]);


  const loadData = async (treeNode: EventDataNode<DataNode>) => {
    let childWbs = knownWbs[treeNode.key.toString()]
    if (!childWbs) {
      childWbs = await getWbs(treeNode.key.toString());
      dispatch(addWbsToStore(childWbs));
    }

    const childData: DataNode[] = await Promise.all(childWbs.subwbs.map(async (item) => {
      let response = knownWbs[item.$oid];
      if (!response) {
        response = await getWbs(item.$oid);
        dispatch(addWbsToStore(response));
      }

      return {
        key: response._id.$oid,
        name: response.name,
        title: <TreeFolderItem folder={response} />,
        switcherIcon: response.subwbs.length ? undefined : <></>,
      }
    })
    )
    const sortedChildData = childData.slice().sort((a, b) => {
      const getRank = (node: DataNode) => {
        if (React.isValidElement(node.title) && node.title.props?.folder?.rank !== undefined) {
          return node.title.props.folder.rank
        }
        return Infinity; // Assign a default high rank if undefined
      };

      const rankA = getRank(a);
      const rankB = getRank(b);

      return rankA - rankB; // Ascending order
    });


    setTreeData((currentTreeData) => {
      return updateTreeData(currentTreeData, treeNode.key.toString(), sortedChildData)
    })
  }
  const collectLeafData = async (node: string, oldParentWbs?: Wbs | undefined, newParentWbs?: Wbs | undefined): Promise<any[]> => {
    const leafData: any[] = []
    const traverse = async (currentNode: string) => {
      let nodeValue = knownWbs[currentNode]
      if (!nodeValue) {
        nodeValue = await getWbs(currentNode)
        dispatch(addWbsToStore(nodeValue))
      }
      if (oldParentWbs && oldParentWbs?._id?.$oid === currentNode)
        nodeValue = oldParentWbs
      if (newParentWbs && newParentWbs?._id?.$oid === currentNode)
        nodeValue = newParentWbs

      if (!nodeValue?.subwbs || nodeValue?.subwbs?.length === 0) {
        if (nodeValue?.data) {
          let versionData = projectversiondata[nodeValue.data.$oid]
          if (!versionData) {
            let fetchedData = await getVersionById(nodeValue.data.$oid)
            fetchedData = { ...fetchedData, name: nodeValue.name }
            dispatch(setVersionData({ id: nodeValue.data.$oid, data: fetchedData }))
            versionData = fetchedData
          }
          leafData.push(versionData)
        }
      } else {
        for (const child of nodeValue.subwbs) {
          await traverse(child.$oid)
        }
      }

    }

    let wbs = knownWbs[node]
    if (!wbs) {
      wbs = await getWbs(node)
      dispatch(addWbsToStore(wbs))
    }
    if (oldParentWbs && oldParentWbs?._id?.$oid === node)
      wbs = oldParentWbs
    if (newParentWbs && newParentWbs?._id?.$oid === node)
      wbs = newParentWbs

    if (wbs?.subwbs.length > 0) {
      for (const child of wbs.subwbs) {
        await traverse(child.$oid)
      }
    } else if (wbs?.data) {
      let versionData = projectversiondata[wbs.data.$oid]
      if (!versionData) {
        let fetchedData = await getVersionById(wbs.data.$oid)
        fetchedData = { ...fetchedData, name: wbs.name }
        dispatch(setVersionData({ id: wbs.data.$oid, data: fetchedData }))
        versionData = fetchedData
      }
      leafData.push(versionData)
    }


    return leafData
  }


  useEffect(() => {
    const maxdatadate = async (): Promise<any> => {
      if (treeData && treeData.length > 0) {
        const node = await collectLeafData(treeData[0].key as string)
        const maxdatadatee = {
          $date: new Date(
            Math.max(...node.map((v) => new Date(v.data_date.$date).getTime()))
          ).toISOString(),
        }
        dispatch(setmaxdatadate(maxdatadatee.$date))
      }
    }
    maxdatadate()
  }, [treeData])

  const handleClick = async (node: EventDataNode<DataNode>) => {

    setWbsId(node.key as string)
    let clickedNode = knownWbs[node.key]
    if (!clickedNode) {
      clickedNode = await getWbs(node.key)
      dispatch(addWbsToStore(clickedNode))
    }

    dispatch(updateShow(true))
    setLoadingData(true)
    dispatch(close())
    dispatch(isntheader())
    if (isProjectHeader === true) dispatch(loading())

    if (!isProjectHeader && !isrenaming) {
      if (!clickedNode.subwbs || clickedNode.subwbs.length === 0) {
        if (clickedNode.data) {
          setWpCreateShow(false);
          setWpShow(true);
          setWbsShow(false);
        } else {
          setWpCreateShow(true);
          setWpShow(false)
          setWbsShow(false);
          setWpName(clickedNode.name);
        }
      } else {
        if (clickedNode.data) {
          setWpCreateShow(false);
          setWpShow(false)
          setWbsShow(true);
        }
        else {
          setWpCreateShow(false);
          setWpShow(false)
          setWbsShow(false);
        }
      }
    }

    const nodesMap = new Map<string, DataNode>()
    const buildMap = (nodes: DataNode[], map: Map<string, DataNode>) => {
      nodes.forEach((node) => {
        map.set(node.key as string, node)
        if (node.children) {
          buildMap(node.children, map)
        }
      })
    }
    buildMap(treeData, nodesMap)

    const fullPath = buildFullPath(node, nodesMap)
    //console.log("Full path of the clicked node: ", fullPath);
    setCurrentPath(fullPath)

    const leafData = await collectLeafData(node.key as string)
    if (leafData.length === 0) {
      dispatch(loaded())
      setLoadingData(false)
      dispatch(updateShow(false))
      dispatch(updateTotal(0))
      return
    }
    //console.log("Leaf node data fields: ", leafData);
    dispatch(updateCollectedLeafData(leafData));


    const targetNodeId = node.key as string;
    let combinedVersion: any;
    if (knownWbs[targetNodeId]?.data) {
      let versionData = projectversiondata[knownWbs[targetNodeId]?.data?.$oid];
      if (!versionData) {
        let fetchedData = await getVersionById(knownWbs[targetNodeId]?.data?.$oid);
        fetchedData = { ...fetchedData, name: knownWbs[targetNodeId].name };
        dispatch(setVersionData({ id: knownWbs[targetNodeId]?.data?.$oid, data: fetchedData }));
        versionData = fetchedData;
      }

      combinedVersion = versionData
    } else {

      combinedVersion = await combineVersionsV2(knownWbs[targetNodeId].name, leafData, true, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync);
    }
    combinedVersion = {
      ...combinedVersion,
      _id: { $oid: knownWbs[targetNodeId]?.data?.$oid },
    };
    setWbsData(combinedVersion);

    if (isProjectHeader) {
      dispatch(setcurrentwbs(node.key))
      setOriginalVersion(combinedVersion)
      setVersion(combinedVersion)
      if (clickedNode) {
        const folderName = fullPath
        setProject((prev) => {
          if (prev) {
            const [baseName, existingFolderName] = prev.name.split('/')
            const newName = existingFolderName
              ? `${baseName}/${folderName}`
              : `${prev.name}/${folderName}`
            return { ...prev, name: newName }
          }
          return prev
        })

        patchWbs(project?.current_wbs, { last_opened_wbs: node.key as string }).then((res) => {
          dispatch(addWbsToStore(res))
          if (project && knownWbs[node.key].data)
            setDefaultVersion(knownWbs[node.key].data.$oid).then((res) => {
              setProject((prev) =>
                prev && res
                  ? {
                    ...prev,
                    default_version: res.default_version,
                  }
                  : undefined
              )
            })
        }
        )
      }
      //console.log(node)
      if (
        !clickedNode.subwbs ||
        clickedNode.subwbs.length === 0
      ) {
        let data = projectversiondata[clickedNode?.data?.$oid]
        if (!data) {
          data = await getVersionById(clickedNode?.data?.$oid)
        }

        setOriginalVersion(data)

        //console.log("This is a WP");
        //console.log(node);
      }
    }

    dispatch(loaded())
    setLoadingData(false)
    dispatch(updateShow(false))
    dispatch(updateTotal(0))
  }

  const handleUpdateWbsDataDate = async (data_date: Date) => {

    dispatch(updateShow(true));
    setLoadingData(true);

    const combinedVersion = await combineVersionsV2(
      knownWbs[wbsId].name,
      collectedLeafData,
      true,
      setWbsDataDateFirstStepAsync,
      setWbsDataDateSecondStepAsync,
      data_date
    )
    if (combinedVersion)
      setWbsData(combinedVersion)

    setLoadingData(false);
    dispatch(updateShow(false));
    dispatch(updateTotal(0));
  };

  const handleUpdateWpDataDate = async (data_date: Date) => {

    dispatch(updateShow(true));
    setLoadingData(true);

    const combinedVersion = await combineVersionsV2(
      knownWbs[wbsId].name,
      collectedLeafData,
      true,
      setWbsDataDateFirstStepAsync,
      setWbsDataDateSecondStepAsync,
      data_date);
    if (combinedVersion)
      setWbsData(combinedVersion);

    setLoadingData(false);
    dispatch(updateShow(false));
    dispatch(updateTotal(0));
  };


  // Extracted handleDrop function
  const handleDrop = async (info: any) => {
    let draggedWbs = knownWbs[info.dragNode.key.toString()];
    if (!draggedWbs) {
      draggedWbs = await getWbs(info.dragNode.key.toString());
      dispatch(addWbsToStore(draggedWbs));
    }

    const oldParentKey = draggedWbs.parent_wbs?.$oid || null; // Get old parent key
    const newParentKey = info.node.key.toString(); // Get new parent key

    if (oldParentKey === newParentKey) {
      // No need to do anything if the node is dropped back to the same parent
      return;
    }

    let newParentWbs = knownWbs[newParentKey];
    if (!newParentWbs) {
      newParentWbs = await getWbs(newParentKey);
      dispatch(addWbsToStore(newParentWbs));
    }

    let oldParentWbs: Wbs | undefined;
    if (oldParentKey) {
      oldParentWbs = knownWbs[oldParentKey];
      if (!oldParentWbs) {
        oldParentWbs = await getWbs(oldParentKey);
        dispatch(addWbsToStore(oldParentWbs));
      }
    }

    // Check if the new parent has no subwbs and has a data field
    if (newParentWbs && newParentWbs.subwbs.length === 0 && newParentWbs.data) {
      sweetAlert({
        title: 'Attention',
        text: 'Curve will push the current data of the WP you wanna drop into a new WP beside the one you are dropping. Press no if you wish to drop only the current node.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
      }).then(async (res) => {
        if (!res.isConfirmed) {
          // Proceed with normal drop
          await proceedWithDrop(info, oldParentKey, newParentKey, newParentWbs, oldParentWbs, false);
        } else {
          // Proceed with special drop (remove data and create a new node)
          await proceedWithDrop(info, oldParentKey, newParentKey, newParentWbs, oldParentWbs, true);
        }
      });
    } else {
      // Proceed directly with the drop
      await proceedWithDrop(info, oldParentKey, newParentKey, newParentWbs, oldParentWbs, false);
    }
  };




  // Updated proceedWithDrop to handle the special case
  // Updated proceedWithDrop to ensure oldParent and newParent are fully updated
  const proceedWithDrop = async (
    info: any,
    oldParentKey: string | null,
    newParentKey: string,
    newParentWbs: Wbs | undefined,
    oldParentWbs: Wbs | undefined,
    isConfirmed: boolean
  ) => {
    try {
      let updatedNewParent = newParentWbs;
      let updatedOldParent = oldParentWbs;

      // Patch the dragged node to update its parent
      const patchedDraggedNode = await patchWbs(info.dragNode.key.toString(), { parent_wbs: newParentKey });
      dispatch(addWbsToStore(patchedDraggedNode));

      // Update the old parent
      if (oldParentKey && oldParentWbs) {
        const updatedOldParentSubwbs = oldParentWbs.subwbs.filter(subwbs => subwbs.$oid !== info.dragNode.key.toString());
        updatedOldParent = await patchWbs(oldParentKey, {
          subwbs: updatedOldParentSubwbs?.map(subwbs => subwbs.$oid) || [],
          data: null
        });
        dispatch(addWbsToStore(updatedOldParent));
      }

      // Update new parent's subwbs
      if (updatedNewParent) {
        const updatedNewParentSubwbs = [
          ...(updatedNewParent.subwbs.map(subwbs => subwbs.$oid) || []),
          info.dragNode.key.toString(),
        ];
        updatedNewParent = await patchWbs(newParentKey, { subwbs: updatedNewParentSubwbs });
        dispatch(addWbsToStore(updatedNewParent));
      }

      // Ensure the oldParent and newParent are fully updated before proceeding
      await Promise.all([updatedOldParent, updatedNewParent]);

      // Update the tree state after parents are updated
      const updatedTree = await moveTreeNode(treeData, oldParentKey, newParentKey, info.dragNode.key.toString());
      setTreeData(updatedTree); // Update tree data in state


      // If confirmed, remove the data field and create a new node with that data
      if (isConfirmed && newParentWbs) {
        const newSubNode = await createWbs({
          name: 'sub ' + newParentWbs.name,
          parent_wbs: newParentWbs._id.$oid,
          data: newParentWbs.data?.$oid,
          parent_project: project?._id.$oid,
        });

        dispatch(addWbsToStore(newSubNode));

        // Remove the data field from the new parent node
        updatedNewParent = await patchWbs(newParentWbs._id.$oid, { data: null });
        dispatch(addWbsToStore(updatedNewParent));

        setTreeData((currentTreeData) => {
          return appendTreeData(currentTreeData, newParentKey, [
            {
              key: newSubNode._id.$oid,
              title: <TreeFolderItem folder={newSubNode} />,
              children: [],
              switcherIcon: <></>
            },
          ]);
        });
      }
      // Now pass the updated parents to updateDataForParents
      await updateDataForParents(oldParentKey, newParentKey, updatedOldParent, updatedNewParent);
    } catch (error) {
      showError(error);
    }
  };



  const updateDataForParents = async (oldParentKey: string | null, newParentKey: string | null, oldParentWbs: Wbs | undefined, newParentWbs: Wbs | undefined) => {

    const wbsListData: any[] = []

    const parents = await getSubParentsKeys(oldParentKey, newParentKey);
    dispatch(updateTotal(parents.length))
    dispatch(updateLoaded(0))
    dispatch(updateShow(true))
    dispatch(updateLabel('Updating data for parents...'))
    for (let i = 0; i < parents.length; i++) {
      dispatch(updateLabel(`Updating data for parent ${i + 1} of ${parents.length}`))
      let parent = knownWbs[parents[i]];
      if (!parent) {
        parent = await getWbs(parents[i]);
        dispatch(addWbsToStore(parent));
      }
      if (oldParentKey === parents[i] && oldParentWbs)
        parent = oldParentWbs
      if (newParentKey === parents[i] && newParentWbs)
        parent = newParentWbs
      const leafData = await collectLeafData(parents[i], oldParentWbs, newParentWbs);
      if (leafData.length > 0) {
        const combinedVersion = await combineVersionsV2(parent.name, leafData, true, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync);
        let data
        if (parent?.data) {
          data = await patchVersion({
            start_date: new Date(combinedVersion.start_date.$date),
            end_date: new Date(combinedVersion.end_date.$date),
            data_date: new Date(combinedVersion.data_date.$date),
            custom_curve: combinedVersion?.custom_curve,
            reserve: combinedVersion?.reserve,
            delay_tolerance: combinedVersion?.delay_tolerance,
            budget_at_completion: combinedVersion?.budget_at_completion,
            total_hours: combinedVersion?.total_hours,
            achieved_percentage: combinedVersion?.achieved_percentage,
            rebase_line: combinedVersion?.rebase_line,
            output: combinedVersion?.output,
          }, parent?.data.$oid);
        } else if (project?._id.$oid) {
          const { _id, associated_calendar, project: projectData, user, updated_at, created_at, ...rest } = combinedVersion;
          data = await createVersion(
            {
              project_version: {
                ...rest,
                start_date: new Date(rest.start_date.$date),
                end_date: new Date(rest.end_date.$date),
                data_date: new Date(rest.data_date.$date),
                forecast_settings: {
                  ...rest.forecast_settings,
                  custom_end_date: rest.forecast_settings?.custom_end_date
                    ? new Date(rest.forecast_settings.custom_end_date.$date)
                    : null,
                },
              },
              timestamp_period: 1
            },
            project?._id.$oid
          );
          const patchedWbs = await patchWbs(parents[i], { data: data._id.$oid })
          dispatch(addWbsToStore(patchedWbs));
        }

        if (data) {
          dispatch(setVersionData({ id: data._id.$oid, data: data }));
          if (originalVersion?._id.$oid === data._id.$oid) {
            dispatch(updateCollectedLeafData(leafData));
            setOriginalVersion(data);
            setVersion(data);
          }
        }
      }

    }
    dispatch(updateWbsCalculatedData(wbsListData));
    dispatch(updateShow(false));

  }




  const getSubParentsKeys = async (oldParentKey: string | null, newParentKey: string | null): Promise<string[]> => {
    const parentsList = [];

    let parent = oldParentKey;
    while (parent && parent !== 'root') {
      let parentWbs = knownWbs[parent]
      if (!parentWbs) {
        parentWbs = await getWbs(parent);
        dispatch(addWbsToStore(parentWbs))
      }
      parentsList.push(parentWbs._id.$oid);
      parent = parentWbs.parent_wbs?.$oid || null;
    }

    parent = newParentKey;
    while (parent) {
      let parentWbs = knownWbs[parent]
      if (!parentWbs) {
        parentWbs = await getWbs(parent);
        dispatch(addWbsToStore(parentWbs))
      }
      parentsList.push(parentWbs._id.$oid);
      parent = parentWbs.parent_wbs?.$oid || null;
    }

    return [...new Set(parentsList)];
  };


  return (
    <>
      <TreeViewHeader setLoadTreeCompleted={setLoadTreeCompleted} />
      <div>
        <Row>
          <Col lg={isProjectHeader ? 12 : 3} md={isProjectHeader ? 12 : 4} sm={12}>
            <div>
              <Tree
                style={{ overflowX: 'scroll' }}
                selectable={false}
                switcherIcon={SwitcherIcon}
                onExpand={(expandedKeys, { expanded, node }) =>
                  setExpandedKeys((prev) =>
                    !expanded
                      ? prev.filter((item) => item !== node.key)
                      : [...prev, node.key.toString()]
                  )
                }
                onClick={(event, node) => !isrenaming && handleClick(node)}
                expandedKeys={expandedKeys}
                loadData={loadData}
                treeData={treeData}
                draggable={
                  !isProjectHeader && {
                    icon: DraggableIcon(),
                  }
                }
                onDrop={handleDrop}
                onDragEnter={
                  !isProjectHeader
                    ? (info) => {
                      if (!expandedKeys.find((item) => item === info.node.key))
                        setExpandedKeys((keys) => [...keys, info.node.key.toString()]);
                    }
                    : undefined
                }
              />
            </div>
          </Col>

          {/* Main Content Area */}
          <Col lg={9} md={8} sm={12} className="bg-white rounded-4 mt-3">
            <div className="p-3">
              {wpCreateShow && !showLoading && (
                <div>
                  {loadingData ? (
                    <div className='d-flex justify-content-center'>
                      <div
                        className='spinner-border text-primary mt-5'
                        role='status'
                        style={{ width: '3rem', height: '3rem' }}
                      >
                        <span className='sr-only'>Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <WpCreateData wpId={wbsId} wpName={wpName} afterCreate={(data: any) => {
                      setWbsData(data);
                      setWpCreateShow(false);
                      setWpShow(true);
                    }} />
                  )}
                </div>
              )}

              {wpShow && !showLoading && (
                <div>
                  <h5>{currentPath}</h5>
                  {loadingData ? (
                    <div className='d-flex justify-content-center'>
                      <div
                        className='spinner-border text-primary mt-5'
                        role='status'
                        style={{ width: '3rem', height: '3rem' }}
                      >
                        <span className='sr-only'>Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <WpData wpId={wbsId} wpData={wbsData} handleUpdateWpDataDate={handleUpdateWpDataDate} />
                  )}
                </div>
              )}

              {wbsShow && !showLoading && (
                <div>
                  <h5>{currentPath}</h5>
                  {loadingData ? (
                    <div className='d-flex justify-content-center'>
                      <div
                        className='spinner-border text-primary mt-5'
                        role='status'
                        style={{ width: '3rem', height: '3rem' }}
                      >
                        <span className='sr-only'>Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <WbsData wbsId={wbsId} wbsData={wbsData} handleUpdateWbsDataDate={handleUpdateWbsDataDate} />
                  )}
                </div>
              )}

              {!wpCreateShow && !wpShow && !wbsShow && !isProjectHeader && (
                <div>

                  {currentPath !== '' ? (
                    <h5>The WBS <span className='text-decoration-underline'>{currentPath}</span> has no data</h5>
                  ) : (
                    <h5>No WBS/WP selected</h5>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );

}

export default TreeView
