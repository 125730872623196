import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import dayjs from 'dayjs';
import { Spin } from 'antd'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils';
import { useThemeMode } from '../../../_metronic/partials';
import DashboardChartTreeView from '../../modules/wbs/components/TreeView/DashboardChartTreeView';
import { useProject } from '../../modules/projects/core/ProjectContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';


interface ProjectProps {
  projects: any;
  treeData: any;
  dataDate: Date;
  interval: number;
  loading: boolean;
}


function extractTreeOrder(treeData: any[]) {
  const order: any[] = [];
  function traverse(node: { key: any; children: any[]; }) {
    order.push(node.key);
    if (node.children) {
      node.children.forEach((child: any) => traverse(child));
    }
  }
  treeData.forEach(node => traverse(node));
  return order;
}

// Helper function to calculate Ticks intervals
const getIntervalTicks = (start: any, end: any, interval: number) => {
  const ticks = [];
  let current = dayjs(start);
  const finish = dayjs(end);

  while (current.isBefore(finish)) {
    ticks.push(current.valueOf());
    current = current.add(interval, 'months');
  }
  ticks.push(finish.valueOf());
  return ticks;
};

export default function ProjectTimelineChart(props: ProjectProps) {
  const { mode } = useThemeMode();
  const textColor = mode === 'dark' ? 'white' : 'black';
  const { project } = useProject()
  // Filter projects based on expandedKeys
  // const filteredProjects = useMemo(() => {
  //   const extendedTreeKeys = new Set(expandedKeys.map((node: any) => node.key));
  //   return props.projects.filter((project: any) => extendedTreeKeys.has(project.id));
  // }, [props.projects, expandedKeys]);

  const dashboard_colors = useSelector((state: RootState) => state.globalDashboardData.global_dashboard_colors)
  const bars_thickness = useSelector((state: RootState) => state.globalDashboardData.global_bars_thickness)

  const filteredProjects = props.projects
  // Reorder validProjectsData based on the order of treeData
  const treeOrder = extractTreeOrder(props.treeData);
  const ordered_projects = [...filteredProjects];
  let projet = ordered_projects.sort((a, b) => {
    return treeOrder.indexOf(a.id) - treeOrder.indexOf(b.id);
  });


  // let projet = filteredProjects.sort((a: any, b: any) => {
  //   if (a.id < b.id) return -1;
  //   if (a.id > b.id) return 1;
  //   return 0;
  // });

  const CustomizedAxisTick = ({ x, y, payload }: any) => {
    const dateTip = new Date(payload.value);
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={18}
          dx={-18}
          transform="rotate(-45)"
          fontSize="12px"
          fontFamily="Outfit"
          fill={textColor}
          fontWeight={400}
        >
          {dateTip.toLocaleString('default', { month: 'short', year: '2-digit' })}
        </text>
      </g>
    );
  };



  const Legend = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0' }}>
        <div style={{ marginRight: '20px' }}>
          <span
            style={{
              display: 'inline-block',
              width: '15px',
              height: '15px',
              backgroundColor: dashboard_colors[0],
              marginRight: '5px',
            }}
          ></span>
          WBS Real End Date
        </div>
        <div style={{ marginRight: '20px' }}>
          <span
            style={{
              display: 'inline-block',
              width: '15px',
              height: '15px',
              backgroundColor: dashboard_colors[1],
              marginRight: '5px',
            }}
          ></span>
          WBS Estimated End Date
        </div>
        <div style={{ marginRight: '20px' }}>
          <span
            style={{
              display: 'inline-block',
              width: '15px',
              height: '15px',
              backgroundColor: dashboard_colors[2],
              marginRight: '5px',
            }}
          ></span>
          WP Real End Date
        </div>
        <div>
          <span
            style={{
              display: 'inline-block',
              width: '15px',
              height: '15px',
              backgroundColor: dashboard_colors[3],
              marginRight: '5px',
            }}
          ></span>
          WP Estimated End Date
        </div>
      </div>
    );
  };


  const CustomizedGroupTick = (params: any) => {
    const { y, payload } = params;
    const chosenProject = filteredProjects.find((a: any) => a.name === payload.value);
    const isForecastable = chosenProject ? chosenProject.forecastable : false;
    const borderColor = getCSSVariableValue('--bs-gray-400');

    return (
      <g
        width={'100px'}
        transform={`translate(${0},${y})`}
        style={{ cursor: 'pointer', background: 'btn-primary', fontFamily: 'inherit', fontSize: '30px' }}
      >
        {payload.value !== '  ' && payload.value !== '' && (
          <g className="activeProjectSvg">
            <rect
              x="1"
              y="-12"
              width="130"
              height="30"
              rx="6"
              className="rectStyle"
              fill={isForecastable ? 'rgba(30, 30, 45, 0.50)' : borderColor}
              stroke={isForecastable ? '#535B7A' : '#369EFD'}
            />
            <text
              x="13"
              y="7"
              dominantBaseline="left"
              textAnchor="left"
              fontSize="12px"
              fontFamily="inherit"
              fill="#FFF"
              fontWeight={400}
            >
              <title>{payload.value}</title>
              {payload.value.length > 10 ? payload.value.slice(0, 18) + '...' : payload.value}
            </text>
          </g>
        )}
      </g>
    );
  };

  const CustomTooltip: any = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const projectDetails = payload[0].payload;

      if (projectDetails.id === '' || projectDetails.id === 'dsd') return null;
      const projectName = projectDetails?.name
      const startDate = projectDetails?.graphEndDate[0] ? dayjs(projectDetails.graphEndDate[0]).format('MM/YYYY') : 'N/A';
      const realStartDate = projectDetails?.graphEstimatedEndDate[0] ? dayjs(projectDetails.graphEstimatedEndDate[0]).format('MM/YYYY') : 'N/A';
      const plannedEndDate = projectDetails?.graphEndDate[1] ? dayjs(projectDetails.graphEndDate[1]).format('MM/YYYY') : 'N/A';
      const estimatedEndDate = projectDetails?.graphEstimatedEndDate[1] ? dayjs(projectDetails.graphEstimatedEndDate[1]).format('MM/YYYY') : 'N/A';

      const noticeClass = projectDetails?.forecastable ? 'text-success' : 'text-warning';

      return (
        <div className="custom-tooltip" style={{ width: '300px' }}>
          <div>Name: {projectName}</div>
          <p>Start Date: {startDate}</p>
          <p>Real Start Date: {realStartDate}</p>
          <p>Planned End Date: {plannedEndDate}</p>
          <p>Estimated End Date: {estimatedEndDate}</p>
          <p className={noticeClass}>Notice: {projectDetails?.forecast_msg || 'No forecast message available'}</p>
        </div>
      );
    }

    return null;
  };

  const CustomDot = (props: any) => {
    const { cx, cy, payload } = props;

    if (payload.id === '' || payload.id === 'dsd') {
      return <circle cx={cx} cy={cy} r={2} fill="#F44" stroke="none" />;
    }

    if (payload.forecastable) {
      return <circle cx={cx} cy={cy} r={4} fill="#F44" stroke="none" />;
    } else {
      return (
        <>
          <circle cx={cx} cy={cy} r={5} fill="#F44" stroke="none" />
          <circle cx={cx} cy={cy} r={4} fill="white" stroke="none" />
        </>
      );
    }
  };

  let minDate1 = Math.min(...filteredProjects.map((p: any) => new Date(p.graphEndDate[0]).getTime()));
  let maxDate1 = Math.max(...filteredProjects.map((p: any) => new Date(p.graphEndDate[1]).getTime()));

  let minDate2 = Math.min(...filteredProjects.map((p: any) => new Date(p.graphEstimatedEndDate[0]).getTime()));
  let maxDate2 = Math.max(...filteredProjects.map((p: any) => new Date(p.graphEstimatedEndDate[1]).getTime()));

  let minDate3 = Math.min(...filteredProjects.map((p: any) => new Date(p.brokenLine).getTime()));
  let maxDate3 = Math.max(...filteredProjects.map((p: any) => new Date(p.brokenLine).getTime()));

  const globalMinDate = Math.min(minDate1, minDate2, minDate3);
  const globalMaxDate = Math.max(maxDate1, maxDate2, maxDate3);

  const extendedMinDate = dayjs(globalMinDate).startOf('month').subtract(props.interval, 'months').valueOf();
  const extendedMaxDate = dayjs(globalMaxDate).endOf('month').add(props.interval, 'months').valueOf();

  const ticks = getIntervalTicks(extendedMinDate, extendedMaxDate, props.interval);

  return (
    <>
          <Legend />

      <div style={{ display: 'flex' }}>
        <div style={{ width: '300px', overflowY: 'auto', padding: '10px', paddingTop: '28px' }}>
          
          <DashboardChartTreeView/>
        </div>
        {props.loading ? (
          <Spin tip="Loading Dashboard..." />
        ) : (

          <ResponsiveContainer height={projet.length * 31.1}>
            <ComposedChart
              layout='vertical'
              width={800}
              height={projet.length * 31.1}
              data={projet}
              margin={{
                top: 5,
                right: 0,
                left: 0,
                bottom: 5,
              }}
            >
              <defs>
                <pattern id="gradientPattern1" patternUnits="userSpaceOnUse" width="9" height="9" patternTransform="rotate(45)">
                  <rect x="0" y="0" width="3" height="9" fill={dashboard_colors[1]} />
                  <rect x="3" y="0" width="3" height="9" fill="transparent" />
                  <rect x="6" y="0" width="3" height="9" fill={dashboard_colors[1]} />
                </pattern>
              </defs>
              <defs>
                <pattern id="gradientPattern2" patternUnits="userSpaceOnUse" width="9" height="9" patternTransform="rotate(45)">
                  <rect x="0" y="0" width="3" height="9" fill={dashboard_colors[3]} />
                  <rect x="3" y="0" width="3" height="9" fill="transparent" />
                  <rect x="6" y="0" width="3" height="9" fill={dashboard_colors[3]} />
                </pattern>
              </defs>
              <CartesianGrid stroke='#49485F' />
              <XAxis
                type='number'
                domain={[extendedMinDate, extendedMaxDate]}
                tickLine={true}
                ticks={ticks}
                tickFormatter={(tick) => {
                  return new Date(tick).getFullYear().toString()
                }}
                scale='time'
                tick={CustomizedAxisTick}
                tickCount={ticks.length}
                padding={{ right: 30 }}
              />
              <YAxis
                dataKey='name'
                type='category'
                tick={(tickObject) => <></>}
                tickFormatter={(value: string, i: any) => {
                  return value !== '  ' && value !== '' && value.length > 10
                    ? value.slice(0, 10) + '...'
                    : value
                }}
                tickLine={false}
                width={0}
                className='yAxis_dashboard'
              >
                {filteredProjects.map(() => {
                  return <Cell fill={'#776BFF'} />
                })}
              </YAxis>
              <Tooltip content={<CustomTooltip />} />


              <Bar dataKey='graphEstimatedEndDate'>
                {projet.map((projet: any) => {
                  const barHeight = projet?.isWbs ? bars_thickness[1] : bars_thickness[3];
                  return (
                    <Cell
                      fill={projet?.isWbs ? (projet?.forecastable ? dashboard_colors[1] : 'url(#gradientPattern1)') :
                        (projet?.forecastable ? dashboard_colors[3] : 'url(#gradientPattern2)')}
                      height={barHeight}
                    />
                  );
                })}
              </Bar>

              <Bar dataKey='graphEndDate'>
                {projet.map((projet: any) => {
                  const barHeight = projet?.isWbs ? bars_thickness[0] : bars_thickness[2];
                  return (
                    <Cell
                      fill={projet?.isWbs ? dashboard_colors[0] : dashboard_colors[2]}
                      height={barHeight}
                    />
                  );
                })}
              </Bar>

          
              <Line dataKey='brokenLine' strokeWidth={2} stroke='#F44' dot={<CustomDot />} />
              <ReferenceLine
                x={props.dataDate.getTime()}
                stroke='#369EFD'
                strokeDasharray='4 4'
                label={{
                  position: 'insideTop',
                  value: 'Data Date ' +
                    ((props.dataDate.getMonth() + 1) < 10 ? '0' + (props.dataDate.getMonth() + 1) : (props.dataDate.getMonth() + 1)) +
                    '/' + props.dataDate.getFullYear(),
                  fill: textColor,
                  fontSize: 14,
                }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  );


  
}