import { useFormik } from 'formik'
import { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import ColorInputWithLabel from '../../../../../components/inputWithLabel/ColorInputWithLabel'
import { InitDashboardSettings, DashboardSettings } from '../_models'
import { useProject } from '../../../core/ProjectContext'
import { patchGlobalDashboard, createGlobalDashboard, getGlobalDashboard } from '../../../core/_requests'
import { showError, showSuccess } from '../../../../../utils/funcs'
import Input from '../../../../../components/Input/Input'
import { useDispatch } from 'react-redux'
import { updateBarsthickness, updateColors } from '../../../../wbs/dashboardData'

type Props = { onHide: () => void }

export default function GlobalDashboardSettingsForm({ onHide }: Props) {
  const { setProject, project } = useProject()
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState<DashboardSettings>(InitDashboardSettings) // Default initial form values
  const [dashboardExists, setDashboardExists] = useState<boolean>(false) // Track if dashboard exists
  const dispatch = useDispatch()

  // Fetch the global dashboard data if it exists
  useEffect(() => {
    getGlobalDashboard()
      .then(res => {
        if (res && res.dashboard_colors && res.bars_thickness) {
          // Dashboard exists, use fetched values to update form's initial values
          setInitialValues({
            WbsRealEndDateColor: res.dashboard_colors[0] || InitDashboardSettings.WbsRealEndDateColor,
            WbsRealEndDateThickness: res.bars_thickness[0]?.toString() || InitDashboardSettings.WbsRealEndDateThickness,
            WbsEstimatedEndDateColor: res.dashboard_colors[1] || InitDashboardSettings.WbsEstimatedEndDateColor,
            WbsEstimatedEndDateThickness: res.bars_thickness[1]?.toString() || InitDashboardSettings.WbsEstimatedEndDateThickness,
            WpRealEndDateColor: res.dashboard_colors[2] || InitDashboardSettings.WpRealEndDateColor,
            WpRealEndDateThickness: res.bars_thickness[2]?.toString() || InitDashboardSettings.WpRealEndDateThickness,
            WpEstimatedEndDateColor: res.dashboard_colors[3] || InitDashboardSettings.WpEstimatedEndDateColor,
            WpEstimatedEndDateThickness: res.bars_thickness[3]?.toString() || InitDashboardSettings.WpEstimatedEndDateThickness,
          })
          setDashboardExists(true) // Mark dashboard as existing
        } else {
          // No dashboard exists yet, set form to use default initial values
          setInitialValues(InitDashboardSettings)
          setDashboardExists(false)
        }
      })
      .catch(err => {
        setInitialValues(InitDashboardSettings)
        setDashboardExists(false)
      })
  }, [])

  const formik = useFormik<DashboardSettings>({
    initialValues,
    enableReinitialize: true, // Reinitialize form with new initial values when they change
    onSubmit: (values) => {
      setLoading(true)

      const dashboardData = {
        dashboard_colors: [
          values.WbsRealEndDateColor,
          values.WbsEstimatedEndDateColor,
          values.WpRealEndDateColor,
          values.WpEstimatedEndDateColor,
        ],
        bars_thickness: [
          Number(values.WbsRealEndDateThickness),
          Number(values.WbsEstimatedEndDateThickness),
          Number(values.WpRealEndDateThickness),
          Number(values.WpEstimatedEndDateThickness),
        ],
      }

      // Decide whether to create or patch based on dashboard existence
      const action = dashboardExists
        ? patchGlobalDashboard(dashboardData) // Update if dashboard exists
        : createGlobalDashboard(dashboardData) // Create if dashboard does not exist

      action
        .then((res) => {
          showSuccess('Settings saved!').then(onHide)
          dispatch(updateColors([
            values.WbsRealEndDateColor,
            values.WbsEstimatedEndDateColor,
            values.WpRealEndDateColor,
            values.WpEstimatedEndDateColor,
          ]))
          dispatch(updateBarsthickness([
            Number(values.WbsRealEndDateThickness),
            Number(values.WbsEstimatedEndDateThickness),
            Number(values.WpRealEndDateThickness),
            Number(values.WpEstimatedEndDateThickness),
          ]))
        })
        .catch((err) => showError(err))
        .finally(() => setLoading(false))
    },
  })

  return (
    <>
      <Modal.Body>
        <ColorInputWithLabel
          error={formik.errors.WbsRealEndDateColor}
          label="Wbs Real End Date"
          formikProps={formik.getFieldProps('WbsRealEndDateColor')}
          touched={formik.touched.WbsRealEndDateColor}
          value={formik.values.WbsRealEndDateColor}
        />
        <Input
          labelText="Thickness"
          error={formik.errors.WbsRealEndDateThickness as string}
          formikProps={formik}
          name="WbsRealEndDateThickness"
          isTouched={!!formik.touched.WbsRealEndDateThickness}
          type="range"
          min={0.5}
          max={10.0}
          value={formik.values.WbsRealEndDateThickness}
          placeholder=""
        />
        <ColorInputWithLabel
          error={formik.errors.WbsEstimatedEndDateColor}
          label="Wbs Estimated End Date"
          formikProps={formik.getFieldProps('WbsEstimatedEndDateColor')}
          touched={formik.touched.WbsEstimatedEndDateColor}
          value={formik.values.WbsEstimatedEndDateColor}
        />
        <Input
          labelText="Thickness"
          error={formik.errors.WbsEstimatedEndDateThickness as string}
          formikProps={formik}
          name="WbsEstimatedEndDateThickness"
          isTouched={!!formik.touched.WbsEstimatedEndDateThickness}
          type="range"
          min={0.5}
          max={10.0}
          placeholder=""
          value={formik.values.WbsEstimatedEndDateThickness}
        />
        <ColorInputWithLabel
          error={formik.errors.WpRealEndDateColor}
          label="Wp Real End Date"
          formikProps={formik.getFieldProps('WpRealEndDateColor')}
          touched={formik.touched.WpRealEndDateColor}
          value={formik.values.WpRealEndDateColor}
        />
        <Input
          labelText="Thickness"
          error={formik.errors.WpRealEndDateThickness as string}
          formikProps={formik}
          name="WpRealEndDateThickness"
          isTouched={!!formik.touched.WpRealEndDateThickness}
          type="range"
          min={0.5}
          max={10.0}
          value={formik.values.WpRealEndDateThickness}
          placeholder=""
        />
        <ColorInputWithLabel
          error={formik.errors.WpEstimatedEndDateColor}
          label="Wp Estimated End Date"
          formikProps={formik.getFieldProps('WpEstimatedEndDateColor')}
          touched={formik.touched.WpEstimatedEndDateColor}
          value={formik.values.WpEstimatedEndDateColor}
        />
        <Input
          labelText="Thickness"
          error={formik.errors.WpEstimatedEndDateThickness as string}
          formikProps={formik}
          name="WpEstimatedEndDateThickness"
          isTouched={!!formik.touched.WpEstimatedEndDateThickness}
          type="range"
          min={0.5}
          max={10.0}
          value={formik.values.WpEstimatedEndDateThickness}
          placeholder=""
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => formik.handleSubmit()}
          disabled={loading || !formik.dirty || !formik.isValid}
          className="btn btn-primary"
        >
          {loading ? (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            'Save'
          )}
        </button>
      </Modal.Footer>
    </>
  )
}
