import moment from 'moment'
import {findSym} from '../helpers/func'
import {sweetAlert} from './funcs'
import {calculateCumulativeFromPeriodic} from '../helpers/optimizers'
import {Calendar} from '../modules/calendars/_models'
import {getForecast} from './forecast'
import {forecastFinishDate} from './forecast/fixed-date-fitting'
import { convertDateUnite, getFomattedDate,
  getDiffBetweenTwoDates,
  getFormattedDateFromMomentObj ,
  getDiffrentBettwenDate } from './data-transformarion/date-utils'
import { removePercent,convertPercentagesToCosts, convertCostsToPercentages, getPercentage } from './data-transformarion/percentage-utils'
import { IBudget, getAverageOfSlicedTable, getFormattedBudget } from './data-transformarion/budget-utils'



// ===================== function to calculate all the values for a project and returns it in an object =====================
export const getProjectDataSecondStep = (
  data: any,
  plannedValues: { original: { cumulative: string | any[]; periodic: { [x: string]: any } } },
  earnedValues: { original: { cumulative: { [x: string]: number }; periodic: { [x: string]: number } } },
  actualCosts: { original: { cumulative: { [x: string]: number }; periodic: { [x: string]: number } } },
  calendar?: Calendar,
  sector?: string,
  showAlert: boolean | null = true
) => {
  return new Promise(async (resolve, reject) => {
    type DurationUnitType = 'year' | 'month' | 'day'
    type DateFormatType = 'mm/dd/yyyy' | 'dd/mm/yyyy'

    // ===================== get the length of of longest array  =====================
    const getMaxArrayLength = (...arrays: any) => Math.max(...arrays.map((el: any) => el.length))

    // =====================  end  =====================

    // ===================== Get the data tables from the API =====================
    const cumulativePlannedValues: number[] = Object.values(data.custom_curve[0].values)
    const cumulativeEarnedValues: number[] = Object.values(data.custom_curve[1].values)
    const cumulativeActualCosts: number[] = Object.values(data.custom_curve[2].values)

    // =====================  end  =====================

    /**
 * Calculates the periodic value by subtracting the previous cumulative value from the current cumulative value.
 * This function is used typically to derive individual period values from a sequence of cumulative totals.
 * @param value The current cumulative value in the sequence.
 * @param index The index of the current cumulative value in the sequence.
 * @param cumulativeValues The array of all cumulative values.
 * @returns If it is the first element (index 0), returns the value itself; otherwise, returns the difference from the previous value.
 */
    const getPeriodicFromCumulativeValues = (value: any, index: any, cumulativeValues: any) => {
      if (index === 0) return value
      return value - cumulativeValues[index - 1]
    }

    // =====================  end  =====================

// Calculate the periodic planned values by converting cumulative planned values into individual periods.
// This uses the 'getPeriodicFromCumulativeValues' function to derive each period's value.

    const periodicPlannedValues = cumulativePlannedValues.map(
      (value, index, cumulativePlannedValues) =>
        getPeriodicFromCumulativeValues(value, index, cumulativePlannedValues)
    )
    // =====================  end  =====================

// Calculate the periodic earned values by converting cumulative earned values into individual period values.
// This transformation utilizes the 'getPeriodicFromCumulativeValues' function to compute the difference
// between each pair of consecutive cumulative values, providing the earned value for each period.

    const periodicEarnedValues = cumulativeEarnedValues.map(
      (value, index, cumulativeEarnedValues) =>
        getPeriodicFromCumulativeValues(value, index, cumulativeEarnedValues)
    )
    // =====================  end  =====================

  // Calculate the periodic actual costs by deriving values from cumulative actual costs.
// This transformation uses the 'getPeriodicFromCumulativeValues' function to calculate the difference between consecutive values.

    const periodicActualCosts = cumulativeActualCosts.map((value, index, cumulativeActualCosts) =>
      getPeriodicFromCumulativeValues(value, index, cumulativeActualCosts)
    )
    // =====================  end  =====================

   // Convert cumulative earned values (EV) from cost amounts to percentages of the total budget.
  // This operation uses the 'convertCostsToPercentages' function, which calculates each value as a percentage of the total budget at completion.

    const cumulativeEarnedValuesAsPercentages = convertCostsToPercentages(
      cumulativeEarnedValues, // Array of cumulative earned cost values.
      data.budget_at_completion.amount  // Total budget amount against which percentages are calculated.
    )
    // =====================  end  =====================


    // check if passed date units is < 2
    const projectLength = getDiffrentBettwenDate(
      data.data_date.$date,
      data.start_date.$date,
      data.period_count.type
    )
    if (projectLength < 2)
      if (showAlert === true) {
        //TODO:Refactor
        await sweetAlert({
          title: 'Forecast Warning',
          text: 'Need at least 3 periods to generate forecast!',
          icon: 'warning',
        })
      }

    // =====================  end  =====================
    /**
 * Extracts and formats various input parameters from a given data object.
 * The function handles date conversions, calculates differences between dates, and sets project-related metrics.
 * @param data The data object containing project-related information and timestamps.
 * @returns An object containing formatted and calculated project metrics.
 */

    const getInputs = (data: any) => {
      const inputs: any = {
        projectDurationUnit: null,
        inputDataDate: getFomattedDate(data.data_date.$date),
        enteredEndDate: null,
        donePercentage:
          cumulativeEarnedValuesAsPercentages[cumulativeEarnedValuesAsPercentages.length - 1],
        isCalculatingRemainingTodo: false,
        nbOfTimeUnitPassed: null,
        mu: null,
        sig: null,
      }

      switch (data.period_count.type) {
        case 'yearly':
        case 'monthly':
          inputs.projectDurationUnit = 'month'
          inputs.enteredEndDate = getFomattedDate(data.end_date.$date, 7)
          break
        default:
          inputs.projectDurationUnit = 'day'
          inputs.enteredEndDate = getFomattedDate(data.end_date.$date)
      }

      inputs.nbOfTimeUnitPassed = getDiffBetweenTwoDates(
        data.start_date.$date,
        data.data_date.$date,
        inputs.projectDurationUnit
      )

      return inputs
    }
    let inputs = getInputs(data)

    // =====================  calculate Earned Schedule  =====================

     /**  calculate earned schedule takes the periodic planned values and the periodic
    earned values and and the nbOfTimeUnitPassed returns the earned schedule it looks
    for each index inferior to nbOfTimeUnitPassed and if earned value is equal to planned
    value it returns the index else it still search pvi as pvi<EV<PVI+1 and returns the index of PVI+1
    * @param plannedValues Array of planned values over the project timeline.
    * @param earnedValues Array of earned values over the project timeline.
    * @param dataDate The current time unit (e.g., month) for which to calculate the earned schedule.
    * @returns The earned schedule index, indicating the point in time corresponding to the earned value.
    */
  

    // my own version of ES
    function calculateEarnedSchedule(plannedValues: any, earnedValues: any, dataDate: any) {
      const numMonths = plannedValues.length

      // Special case: dataDate is 0
      if (dataDate === 0) {
        return +(earnedValues[0] / plannedValues[0]).toString()
      }

      const foundIndex = plannedValues.findIndex((item: any) => item === earnedValues[dataDate])
      if (foundIndex !== -1) return foundIndex + 1

      // Special case: on schedule
      let index = 0
      if (earnedValues[dataDate]?.toFixed(2) === plannedValues[dataDate]?.toFixed(2))
        return +dataDate + 1
      else {
        //look for pvi and pvi+1
        for (let pviIndex = 0; pviIndex < numMonths; pviIndex++) {
          if (
            plannedValues[pviIndex] <= earnedValues[dataDate] &&
            earnedValues[dataDate] < plannedValues[pviIndex + 1]
          ) {
            index = pviIndex
            break
          }
        }
        if (plannedValues[index + 1] === plannedValues[index]) return index

        return (
          index +
          1 +
          (earnedValues[dataDate] - plannedValues[index]) /
            (plannedValues[index + 1] - plannedValues[index])
        )
      }
    }
    // =====================  end  =====================

    // =====================  generate a table of earned schedule for each month  =====================

/**
 * Generates a table of earned schedule (ES) values for each month up to the given number of time units passed.
 * @param cumulativePlannedValues Array of cumulative planned values.
 * @param cumulativeEarnedValues Array of cumulative earned values.
 * @param nbOfTimeUnitPassed The number of time units (e.g., months) that have passed.
 * @returns An array of earned schedule values for each month.
 */

    const EarnedScheduleTable = (
      cumulativePlannedValues: any,
      cumulativeEarnedValues: any,
      nbOfTimeUnitPassed: any
    ) => {
      let ES = []
      for (let i = 0; i < nbOfTimeUnitPassed; i++) {
        ES.push(calculateEarnedSchedule(cumulativePlannedValues, cumulativeEarnedValues, i))
      }
      return ES
    }
    const Estable = EarnedScheduleTable(
      cumulativePlannedValues,
      cumulativeEarnedValues,
      inputs.nbOfTimeUnitPassed + 1
    )

    const calculateSPIt = (Estable: any) => {
      let SPIt = []
      for (let i = 0; i < Estable.length; i++) {
        SPIt.push((Estable[i] < 0 ? 1 : Estable[i]) / (i + 1))
      }
      return SPIt
    }

    const SPIt = calculateSPIt(Estable)

    let EarnedValue = await getForecast(
      inputs,
      data,
      cumulativeEarnedValuesAsPercentages,
      SPIt,
      Estable,
      calendar,
      sector
    )
    const earnedValuesForcasts = {
      cumulative: convertPercentagesToCosts(
        removePercent(EarnedValue.tableData[1]),
        data.budget_at_completion.amount
      ),
      periodic: convertPercentagesToCosts(
        removePercent(EarnedValue.tableData[2]),
        data.budget_at_completion.amount
      ),
    }

    // =====================  end  =====================

    // ===================== get the future part of the forecast for EV  =====================
/**
 * Determines the forecasted future values for earned values based on their index.
 * @param el The current value in the earned values array.
 * @param index The index of the current value in the earned values array.
 * @param isPeriodic Boolean indicating whether the value is periodic (true) or cumulative (false).
 * @returns The forecasted values, or null if the index is less than the number of time units passed.
 */

    const getFutureForcastsValues = (el: number, index: number, isPeriodic: boolean = false) => {
      return index < inputs.nbOfTimeUnitPassed
        ? null
        : index === inputs.nbOfTimeUnitPassed
        ? isPeriodic
          ? periodicEarnedValues[index]
          : cumulativeEarnedValues[index]
        : +el.toString()
    }

    const earnedValuesFutureForcasts = {
      cumulative: earnedValuesForcasts.cumulative.map((el: number, index: number) =>
        getFutureForcastsValues(el, index, false)
      ),
      periodic: earnedValuesForcasts.periodic.map((el: number, index: number) =>
        getFutureForcastsValues(el, index, true)
      ),
    }

    // =====================  end  =====================

    // ===================== get the mixed values  for EV (original + Forecast)  =====================

/**
 * Retrieves the mixed values for earned value (EV), combining original and forecast values.
 * @param el The current value in the forecasted earned values array.
 * @param index The index of the current value.
 * @param values The array of original cumulative or periodic earned values.
 * @returns The mixed value for the given index, or an empty string if the value is undefined, null, or an empty string.
 */


    const getMixedValues = (el: number | string, index: number, values: any[]) => {
      if (index <= inputs.nbOfTimeUnitPassed) {
        const val = values[index]

            // Return an empty string if the value is undefined, null, or an empty string.

        return (typeof val === 'undefined' || val === null || (typeof val === 'string' && val === '')) ? '' : +val.toString()
      }
  // For future values, return the element cast to a number.

      return +(el as number).toString()
    }
  // Generate mixed earned values, combining original and forecast values.

    const earnedValuesMixed = {
      cumulative: earnedValuesForcasts.cumulative.map((el: number | string, index: number) =>
        getMixedValues(el, index, cumulativeEarnedValues)
      ),
      periodic: earnedValuesForcasts.periodic.map((el: number | string, index: number) =>
        getMixedValues(el, index, periodicEarnedValues)
      ),
    }
  // Recalculate periodic earned values from the mixed cumulative values.

    earnedValuesMixed.periodic = earnedValuesMixed.cumulative.map((element: any, index: number) =>
      Math.max(getPeriodicFromCumulativeValues(element, index, earnedValuesMixed.cumulative), 0)
    )
  // Update the forecasted periodic earned values to match the mixed periodic earned values.

    earnedValuesFutureForcasts.periodic = earnedValuesFutureForcasts.cumulative.map(
      (element: any, index: number) => (!element ? element : earnedValuesMixed.periodic[index])
    )

    // =====================  end  =====================

    // =====================  Datelabels object  =====================
    /**
     * Formats the data date into a string label based on the project's duration unit.
     * @param dataDate The date object representing the data date.
     * @param durationUnit The unit of time for formatting the date (e.g., 'day', 'month').
     * @returns A formatted date string based on the specified duration unit.
     */

    const dataDateLabel = getFormattedDateFromMomentObj(
      moment(data.data_date.$date), // Convert the data date from the provided data object to a Moment.js date object.
      inputs.projectDurationUnit  // Use the project's duration unit to format the date.
    )

    // =====================  end  =====================

    // =====================  get the object for delay tolerance start and end date =====================

    /**
     * Calculates the delay tolerance end date by adding the specified delay tolerance to the end date timestamp.
     * @param endDateTimestamp The UNIX timestamp representing the project end date.
     * @param delayTolerance An object containing the count of the delay and the unit of time.
     * @returns A formatted date string representing the end date adjusted by the delay tolerance.
     */


    const getDelayToleranceEndDate = async (endDateTimestamp: number, delayTolerance: any) => {
      try {
        const m = moment(endDateTimestamp)
        if (!m.isValid()) {
          throw new Error('Invalid end date timestamp')
        }

        const unit: DurationUnitType = data.period_count.type === 'daily' ? 'day' : 'month'
        m.add(delayTolerance.count, `${unit}s`)

        return getFormattedDateFromMomentObj(m, unit)
      } catch (error) {
        return Promise.reject(error)
      }
    }
    const delayTolerance = {
      startDateLabel: getFormattedDateFromMomentObj(
        moment(data.end_date.$date),
        inputs.projectDurationUnit
      ),
      endDateLabel: await getDelayToleranceEndDate(data.end_date.$date, data.delay_tolerance),
    }

    // =====================  end  =====================

    // =====================  get the object for budget reserve =====================
    const budgetReserve = {
      budgetAtCompletion: data.budget_at_completion.amount.toString(),
      reserve: (data.budget_at_completion.amount + data.reserve.amount).toString(),
    }

    // =====================  end  =====================

    // =====================  calculate periodic and culative CPI  =====================
    const calculateCpi = (earnedValues: any, actualCosts: any, floatFormater: number) => {
      const CpiTable: number[] = []

      const minLength = Math.min(earnedValues.length, actualCosts.length)
      for (let i = 0; i < minLength; i++) {
        let cpi = earnedValues[i] / actualCosts[i]
        if (!earnedValues[i] && !actualCosts[i]) cpi = 1
        CpiTable.push(cpi)
      }
      return CpiTable
    }

    const cumulativeCpi = calculateCpi(
      cumulativeEarnedValues,
      cumulativeActualCosts,
      data.float_formatter
    )
    const periodicCpi = calculateCpi(
      periodicEarnedValues,
      periodicActualCosts,
      data.float_formatter
    )
    // =====================  end  =====================

    // ===================== SPI =======================

    const calculateSpiCum = () => {
      return cumulativeEarnedValues.map((item: any, index) => {
        if (!item) return 0
        return (
          item /
          ((cumulativePlannedValues as number[])[index] ||
            (cumulativePlannedValues.at(-1) as number))
        )
      })
    }

    const calculateSpiPeriodic = () => {
      return periodicEarnedValues.map((item: any, index) => {
        if (!item || !periodicPlannedValues[index]) return 0
        return item / periodicPlannedValues[index]
      })
    }

    const SPICum = calculateSpiCum()
    const SPIPeriodic = calculateSpiPeriodic()

    // =====================  end  =====================

    // =====================  calculate EAC  =====================

    const calculateBuecEac = (cpi: number[], bac: number) => {
      const actualBuec = data.forecast_settings?.bottom_up_ec?.find(
        (item: any) => item.label === dataDateLabel
      )
      if (!actualBuec) return undefined
      const eac = cumulativeActualCosts.at(-1) + actualBuec.value

      return Array(cpi.length).fill(eac)
    }

    const calculateBacEvEa = (cpi: number[], bac: number) => {
      return cpi.map(
        (item, index) => +cumulativeActualCosts[index] + (bac - +cumulativeEarnedValues[index])
      )
    }

    const calculateBacEvSpi = (cpi: number[], bac: number) => {
      return cpi.map(
        (item, index) =>
          +cumulativeActualCosts[index] +
          (bac - +cumulativeEarnedValues[index]) / (cpi[cpi.length - 1] * SPICum[SPICum.length - 1])
      )
    }

    const calculateForcedBac = (cpi: number[], bac: number) => {
      return cpi.map((item, index) => bac)
    }

    const calculateCustomEac = (cpi: number[], bac: number) => {
      return Array(cpi.length).fill(data.forecast_settings?.custom_eac)
    }
    const calculateEac = (cpi: number[], bac: number, floatFormater: number) => {
      if (data.forecast_settings?.eac_formula === 1)
        return calculateBuecEac(cpi, bac) || cpi.map((value) => (bac / value).toString())

      if (data.forecast_settings?.eac_formula === 2) return calculateBacEvEa(cpi, bac)

      if (data.forecast_settings?.eac_formula === 3) return calculateBacEvSpi(cpi, bac)

      if (data.forecast_settings?.eac_formula === 4) {
        if ((cumulativeActualCosts.at(-1) || 0) >= bac) {
          sweetAlert({
            icon: 'error',
            title: 'Error',
            text: 'Actual cost is bigger than budget at completion, please review the forecast method.\n Falling back to BAC/CPI',
          })
          return cpi.map((value) => (bac / value).toString())
        }
        return calculateForcedBac(cpi, bac)
      }

      if (data.forecast_settings?.eac_formula === 5 && data.forecast_settings?.custom_eac) {
        if ((cumulativeActualCosts.at(-1) || 0) >= data.forecast_settings?.custom_eac) {
          sweetAlert({
            icon: 'error',
            title: 'Error',
            text: 'Actual cost is bigger than custom EAC, please review the forecast method.\n Falling back to BAC/CPI',
          })
          return cpi.map((value) => (bac / value).toString())
        }
        return calculateCustomEac(cpi, bac) || cpi.map((value) => (bac / value).toString())
      }

      return cpi.map((value) => (bac / value).toString())
    }

    const EAC = calculateEac(cumulativeCpi, data.budget_at_completion.amount, data.float_formatter)

    // =====================  end  =====================


    // ===================== get the future part of the forecast for EAC =====================

    const tcpi =
      (data.budget_at_completion.amount -
        cumulativeEarnedValues[cumulativeEarnedValues.length - 1]) /
      (EAC.at(-1) - cumulativeActualCosts[cumulativeActualCosts.length - 1])

    const acutalCostFutureForecast: {cumulative: any; periodic: any} = {
      cumulative: undefined,
      periodic: earnedValuesFutureForcasts.periodic.map((el: any, index: number) =>
        el !== null && el !== undefined && index === inputs.nbOfTimeUnitPassed
          ? periodicActualCosts.at(inputs.nbOfTimeUnitPassed)
          : el !== null && el !== undefined && index !== inputs.nbOfTimeUnitPassed
          ? +el / tcpi
          : null
      ),
    }

    const actualCostMixed: {cumulative: any; periodic: any} = {
      cumulative: undefined,
      periodic: acutalCostFutureForecast.periodic.map((el: number | string, index: number) =>
        getMixedValues(+el, index, periodicActualCosts)
      ),
    }

    actualCostMixed.cumulative = calculateCumulativeFromPeriodic(actualCostMixed.periodic)

    acutalCostFutureForecast.cumulative = acutalCostFutureForecast.periodic.map(
      (item: any, index: number) =>
        item !== null && item !== undefined ? actualCostMixed.cumulative[index] : null
    )

    // =====================  end  =====================

    // =====================  get the date labels =====================

    const getLabels = (
      startTimestamp: number,
      length: number,
      durationUnit: DurationUnitType,
      _dateFormat: DateFormatType = 'dd/mm/yyyy'
    ) =>
      Array.from({length}).map((_el, idx) =>
        getFormattedDateFromMomentObj(
          moment(startTimestamp).add(idx, `${durationUnit}s`),
          durationUnit
        )
      )

    const labels = getLabels(
      data.start_date.$date,

      getMaxArrayLength(
        ...Object.values(earnedValuesMixed),
        ...Object.values(actualCostMixed),
        cumulativePlannedValues
      ),
      inputs.projectDurationUnit,
      data.date_format as DateFormatType
    )

    // =====================  end  =====================

    const calculateCVCum = (
      cumulativeEarnedValues: any,
      cumulativeActualCosts: any,
      floatFormater: any
    ) => {
      let CVCum = []
      for (let i = 0; i < cumulativeEarnedValues.length; i++) {
        CVCum.push(cumulativeEarnedValues[i] - cumulativeActualCosts[i])
      }
      return CVCum
    }

    const CVCum = calculateCVCum(
      cumulativeEarnedValues,
      cumulativeActualCosts,
      data.float_formatter
    )

    const calculateCVPeriod = (periodicEarnedValues: any, periodicActualCosts: any) => {
      let CVPeriod = []
      for (let i = 0; i < periodicEarnedValues.length; i++) {
        CVPeriod.push(periodicEarnedValues[i] - periodicActualCosts[i])
      }
      return CVPeriod
    }

    const CVPeriod = calculateCVPeriod(periodicEarnedValues, periodicActualCosts)

    const calculateSVtCum = (Estable: any) => {
      let SVtCum = []
      for (let i = 0; i < Estable.length; i++) {
        SVtCum.push(Estable[i] - (i + 1))
      }
      return SVtCum
    }

    const SVtCum = calculateSVtCum(Estable)
    const calculateSVtPeriod = (SVtCum: any) => {
      let SVtPeriod = []
      for (let i = 0; i < SVtCum.length; i++) {
        SVtPeriod.push(i === 0 ? SVtCum[i] : SVtCum[i] - SVtCum[i - 1])
      }
      return SVtPeriod
    }

    const SVtPeriod = calculateSVtPeriod(SVtCum)

    // =====================  end  =====================

    // =====================  Calculate Variance EAC =====================
    //calculate variance EAC by looping through EAC and subsctructing the value by the BAC

    const calculateVarianceEAC = (EAC: any, BAC: any, floatFormater: number) => {
      let VarianceEAC = []
      for (let i = 0; i < EAC.length; i++) {
        VarianceEAC.push(parseFloat((EAC[i] - BAC).toString()))
      }
      return VarianceEAC
    }

    const VarianceEAC = calculateVarianceEAC(
      EAC,
      data.budget_at_completion.amount,
      data.float_formatter
    )

    //  =====================  end  =====================

    // =====================  Calculate Management reserve remaining =====================

    //calculate management reserve remaining by looping through the table of Variance EAC and substracting the management reserve by the value of the table

    const calculateManagementReserveRemaining = (
      VarianceEAC: any,
      management_reserve: any,
      floatFormater: number
    ) => {
      let ManagementReserveRemaining = []
      for (let i = 0; i < VarianceEAC.length; i++) {
        ManagementReserveRemaining.push(
          parseFloat((management_reserve - VarianceEAC[i]).toString())
        )
      }
      return ManagementReserveRemaining
    }

    const ManagementReserveRemaining = calculateManagementReserveRemaining(
      VarianceEAC,
      data.reserve.amount,
      data.float_formatter
    )

    //  =====================  end  =====================

    // =====================  Calculate Estimate to complete =====================
    //calculate Estimate to complete by looping through the table of  EAC and substracting the EAC value by the earnedvalues original table

    const calculateEstimateToComplete = (
      EAC: any,
      cumulativeEarnedValues: any,
      floatFormater: number
    ) => {
      let EstimateToComplete = []
      for (let i = 0; i < EAC.length; i++) {
        EstimateToComplete.push(parseFloat((EAC[i] - cumulativeActualCosts[i]).toString()))
      }
      return EstimateToComplete
    }

    const EstimateToComplete = calculateEstimateToComplete(
      EAC,
      cumulativeEarnedValues,
      data.float_formatter
    )

    // =====================  end  =====================

    // =====================  Calculate estimated end date =====================

    const estimatedEndDate = labels[labels.length - 1]
    // =====================  end  =====================

    // =====================  Calculate Variance end date  =====================

    const calculateVarianceEndDate = (estimatedEndDate: any, end_date: any, timeUnit: any) => {
      const date1 = new Date(estimatedEndDate)
      const date2 = new Date(end_date)
      const diffTime = Math.abs(date2.getTime() - date1.getTime())
      const diffDays = diffTime / (1000 * 60 * 60 * 24)
      const diffMonths = diffDays / 30
      const diffYears = diffMonths / 12
      if (timeUnit === 'day') return diffDays
      if (timeUnit === 'month') return diffMonths
      if (timeUnit === 'year') return diffYears
    }

    const VarianceEndDate = calculateVarianceEndDate(
      estimatedEndDate,
      inputs.enteredEndDate,
      inputs.projectDurationUnit
    )

    // =====================  end  =====================

    // =====================  Calculate delay tolerance remaining  =====================

    //! too tired to finish , this should be the last variable on the table to calculate  ,finish it if you can
    //it just calculate the delay tolerance remaining by substracting the estimated end date by the delay tolerance end date
    const calculateDelayToleranceRemaining = (
      estimatedEndDate: any,
      delayTolerance: any,
      floatFormater: number
    ) => {
      const endDate = new Date(delayTolerance.endDateLabel)
      const estimatedEndDateLabel = new Date(estimatedEndDate)
      let DelayToleranceRemaining = endDate.getTime() - estimatedEndDateLabel.getTime()
      if (DelayToleranceRemaining < 0) {
        DelayToleranceRemaining = 0
      } else if (DelayToleranceRemaining > data.delay_tolerance.count) {
        DelayToleranceRemaining = data.delay_tolerance.count
      } else {
        DelayToleranceRemaining = parseFloat(DelayToleranceRemaining.toString())
      }
      return DelayToleranceRemaining
    }
    const DelayToleranceRemaining = calculateDelayToleranceRemaining(
      estimatedEndDate,
      delayTolerance,
      data.float_formatter
    )

    // =====================  end  =====================


    // =====================  Objects for CPI  =====================

    const cpi = {
      cumulative: cumulativeCpi,
      periodic: periodicCpi,
    }

    // =====================  end  =====================

    // ===================== Calculate FTE =====================

    const calculateFTE = async () => {
      if (!data.total_hours || !data.full_capacity) return undefined
      const hourlyRate = data.budget_at_completion.amount / data.total_hours
      const plannedFTE = periodicPlannedValues.map((item) => item / hourlyRate / data.full_capacity)
      const realFTE = periodicEarnedValues.map((item) => item / hourlyRate / data.full_capacity)
      const forecastFTE = earnedValuesFutureForcasts.periodic.map((item: any) =>
        item ? item / hourlyRate / data.full_capacity : null
      )
      let fixedEndDateCumulative = await forecastFinishDate(
        inputs,
        data,
        cumulativeEarnedValuesAsPercentages,
        SPIt,
        Estable,
        calendar
      )

      const periodicFixedEndDate = fixedEndDateCumulative.tableData[2]
        .slice(cumulativeEarnedValuesAsPercentages.length - 1)
        .map((item: number) => (+item * data.budget_at_completion.amount) / 100)

      const FTEToComplete = periodicFixedEndDate.map(
        (item) => (item < 0 ? 0 : item) / hourlyRate / data.full_capacity
      )
      return {plannedFTE, realFTE, forecastFTE, FTEToComplete}
    }
    const FTE = await calculateFTE()

    // ===================== End Calculate FTE =================

    // =====================  Calculate Cards Data  =====================

    const CardsData = {
      // =====================  Calculate Actual Project Data Card   =====================
      ActualProjectData: {
        PV: {
          Cumulative:
            getFormattedBudget({
              ...data?.budget_at_completion,
              amount:
                plannedValues.original.cumulative[inputs.nbOfTimeUnitPassed] ||
                plannedValues.original.cumulative.at(-1),
            } as IBudget) +
            '(' +
            getPercentage(
              plannedValues.original.cumulative[inputs.nbOfTimeUnitPassed] ||
                plannedValues.original.cumulative.at(-1),
              budgetReserve.budgetAtCompletion
            ).toFixed(data.float_formatter) +
            '%)',
          Periodic:
            getFormattedBudget({
              ...data?.budget_at_completion,
              amount: plannedValues.original.periodic[inputs.nbOfTimeUnitPassed] || 0,
            } as IBudget) +
            '(' +
            getPercentage(
              plannedValues.original.periodic[inputs.nbOfTimeUnitPassed] || 0,
              budgetReserve.budgetAtCompletion
            ).toFixed(data.float_formatter) +
            '%)',
          Average:
            getFormattedBudget({
              ...data?.budget_at_completion,
              amount: getAverageOfSlicedTable(
                plannedValues.original.periodic,
                inputs.nbOfTimeUnitPassed + 1
              ).toFixed(data.float_formatter),
            } as IBudget) +
            '(' +
            getPercentage(
              parseFloat(
                getAverageOfSlicedTable(
                  plannedValues.original.periodic,
                  inputs.nbOfTimeUnitPassed + 1
                ).toFixed(data.float_formatter)
              ),
              budgetReserve.budgetAtCompletion
            ).toFixed(data.float_formatter) +
            '%)',
        },
        EV: {
          Cumulative:
            getFormattedBudget({
              ...data?.budget_at_completion,
              amount: earnedValues.original.cumulative[inputs.nbOfTimeUnitPassed],
            } as IBudget) +
            '(' +
            getPercentage(
              earnedValues.original.cumulative[inputs.nbOfTimeUnitPassed],
              budgetReserve.budgetAtCompletion
            ).toFixed(data.float_formatter) +
            '%)',
          Periodic:
            getFormattedBudget({
              ...data?.budget_at_completion,
              amount: earnedValues.original.periodic[inputs.nbOfTimeUnitPassed],
            } as IBudget) +
            '(' +
            getPercentage(
              earnedValues.original.periodic[inputs.nbOfTimeUnitPassed],
              budgetReserve.budgetAtCompletion
            ).toFixed(data.float_formatter) +
            '%)',
          Average:
            getFormattedBudget({
              ...data?.budget_at_completion,
              amount: getAverageOfSlicedTable(
                earnedValues.original.periodic,
                inputs.nbOfTimeUnitPassed + 1
              ).toFixed(data.float_formatter),
            } as IBudget) +
            '(' +
            getPercentage(
              parseFloat(
                getAverageOfSlicedTable(
                  earnedValues.original.periodic,
                  inputs.nbOfTimeUnitPassed + 1
                ).toFixed(data.float_formatter)
              ),
              budgetReserve.budgetAtCompletion
            ).toFixed(data.float_formatter) +
            '%)',
        },
        AC: {
          Cumulative:
            getFormattedBudget({
              ...data?.budget_at_completion,
              amount: actualCosts.original.cumulative[inputs.nbOfTimeUnitPassed],
            } as IBudget) +
            '(' +
            getPercentage(
              actualCosts.original.cumulative[inputs.nbOfTimeUnitPassed],
              budgetReserve.budgetAtCompletion
            ).toFixed(data.float_formatter) +
            '%)',
          Periodic:
            getFormattedBudget({
              ...data?.budget_at_completion,
              amount: actualCosts.original.periodic[inputs.nbOfTimeUnitPassed],
            } as IBudget) +
            '(' +
            getPercentage(
              actualCosts.original.periodic[inputs.nbOfTimeUnitPassed],
              budgetReserve.budgetAtCompletion
            ).toFixed(data.float_formatter) +
            '%)',
          Average:
            getFormattedBudget({
              ...data?.budget_at_completion,
              amount: getAverageOfSlicedTable(
                actualCosts.original.periodic,
                inputs.nbOfTimeUnitPassed + 1
              ).toFixed(data.float_formatter),
            } as IBudget) +
            '(' +
            getPercentage(
              parseFloat(
                getAverageOfSlicedTable(
                  actualCosts.original.periodic,
                  inputs.nbOfTimeUnitPassed + 1
                ).toString()
              ),
              budgetReserve.budgetAtCompletion
            ).toFixed(data.float_formatter) +
            '%)',
        },
      },

      // =====================  end  =====================

      // =====================  Calculate Cost Perfomance Card   =====================
      CostPerformance: {
        CPI: {
          Cumulative: cpi.cumulative[inputs.nbOfTimeUnitPassed].toFixed(data.float_formatter),
          Periodic: cpi.periodic[inputs.nbOfTimeUnitPassed].toFixed(data.float_formatter),
          Average: getAverageOfSlicedTable(cpi.periodic, cpi.periodic.length).toFixed(
            data.float_formatter
          ),
        },
        CV: {
          Cumulative: getFormattedBudget({
            ...data?.budget_at_completion,
            amount: CVCum[inputs.nbOfTimeUnitPassed],
          } as IBudget),
          Periodic: getFormattedBudget({
            ...data?.budget_at_completion,
            amount: CVPeriod[inputs.nbOfTimeUnitPassed],
          } as IBudget),
          Average: getFormattedBudget({
            ...data?.budget_at_completion,
            amount: getAverageOfSlicedTable(CVPeriod, inputs.nbOfTimeUnitPassed + 1),
          } as IBudget),
        },
        EAC: {
          Value: getFormattedBudget({
            ...data?.budget_at_completion,
            amount: parseFloat(EAC[inputs.nbOfTimeUnitPassed]).toFixed(data.float_formatter),
          } as IBudget),
          Difference: getFormattedBudget({
            ...data?.budget_at_completion,
            amount: (
              parseFloat(EAC[inputs.nbOfTimeUnitPassed]) -
              parseFloat(data.budget_at_completion.amount)
            ).toFixed(data.float_formatter),
          } as IBudget),
          sign:
            parseFloat(EAC[inputs.nbOfTimeUnitPassed]) -
              parseFloat(data.budget_at_completion.amount) >
            0
              ? 'positive'
              : parseFloat(EAC[inputs.nbOfTimeUnitPassed]) -
                  parseFloat(data.budget_at_completion.amount) ===
                0
              ? 'null'
              : 'negative',
        },
        ETC: {
          value: getFormattedBudget({
            ...data?.budget_at_completion,
            amount: EstimateToComplete[inputs.nbOfTimeUnitPassed].toFixed(data.float_formatter),
          } as IBudget),
        },
      },
      // =====================  end  =====================

      // =====================  Calculate Schedule Perfomance Card   =====================
      SchedulePerformance: {
        ES:
          Estable[Estable.length - 1].toFixed(data.float_formatter) +
          ' ' +
          convertDateUnite(data.period_count.type),
        SPIT: SPIt[inputs.nbOfTimeUnitPassed].toFixed(data.float_formatter),
        SVT: {
          cumulative:
            SVtCum[inputs.nbOfTimeUnitPassed].toFixed(data.float_formatter) +
            ' ' +
            convertDateUnite(data.period_count.type),
          periodic:
            SVtPeriod[inputs.nbOfTimeUnitPassed].toFixed(data.float_formatter) +
            ' ' +
            convertDateUnite(data.period_count.type),
          average:
            getAverageOfSlicedTable(SVtPeriod, inputs.nbOfTimeUnitPassed + 1).toFixed(
              data.float_formatter
            ) +
            ' ' +
            convertDateUnite(data.period_count.type),
        },
        EED: {
          Value:
            projectLength >= 0 &&
            getFormattedDateFromMomentObj(
              EarnedValue.tableData[0].at(-1),
              data.period_count.type === 'daily' ? 'day' : 'month'
            ),
          Difference:
            projectLength >= 0 &&
            getDiffrentBettwenDate(
              moment(data.start_date.$date)
                .add(
                  EarnedValue.tableData[1].length - 1,
                  data.period_count.type === 'monthly' ? 'month' : 'days'
                )
                .toDate()
                .getTime(),
              data.end_date.$date,
              data.period_count.type
            ) +
              ' ' +
              convertDateUnite(data.period_count.type),
          sign:
            projectLength >= 0 &&
            getDiffrentBettwenDate(
              moment(data.start_date.$date)
                .add(
                  EarnedValue.tableData[1].length - 1,
                  data.period_count.type === 'monthly' ? 'month' : 'days'
                )
                .toDate()
                .getTime(),
              data.end_date.$date,
              data.period_count.type
            ),
        },
        ERD:
          projectLength >= 0 &&
          (EarnedValue.nbOfRemainingMonths < 0 ? 0 : EarnedValue.nbOfRemainingMonths) +
            ' ' +
            convertDateUnite(data.period_count.type),
      },

      // =====================  end  =====================
    }
    // =====================  schedule variance data  =====================//

    //! this function isn't working yet , to finish , i was working on it to pass it to the canslestickchart component
    const calculateScheduleVarianceData = (SVtPeriod: any, labels: any) => {
      const scheduleVarianceData = []
      for (let i = 0; i < SVtPeriod.length - 1; i++) {
        scheduleVarianceData.push({
          x: labels[i],
          y: SVtPeriod[SVtPeriod.length - 1],
        })
      }
      return scheduleVarianceData
    }
    const scheduleVarianceData = calculateScheduleVarianceData(SVtCum, labels)
    const calculateScheduleVarianceDataCandle = (SVtPeriod: any, labels: any) => {
      const scheduleVarianceDataCandle = []
      for (let i = 0; i < SVtPeriod.length - 1; i++) {
        scheduleVarianceDataCandle.push({
          x: labels[i],
          y: [SVtPeriod[i], SVtPeriod[i], SVtPeriod[i + 1], SVtPeriod[i + 1]],
        })
      }
      return scheduleVarianceDataCandle
    }
    const scheduleVarianceDataCandle = calculateScheduleVarianceDataCandle(SVtCum, labels)
    const calculateCVData = (CVPeriod: any, labels: any) => {
      const CVCumData = []
      for (let i = 0; i < CVPeriod.length - 1; i++) {
        CVCumData.push({
          x: labels[i],
          y: CVPeriod[CVPeriod.length - 1],
        })
      }
      return CVCumData
    }
    const CVPeriodData = calculateCVData(CVCum, labels)
    const calculateCVCumDataCandle = (CVPeriod: any, labels: any) => {
      const CVCumDataCandle = []
      for (let i = 0; i < CVPeriod.length - 1; i++) {
        CVCumDataCandle.push({
          x: labels[i],
          y: [CVPeriod[i], CVPeriod[i], CVPeriod[i + 1], CVPeriod[i + 1]],
        })
      }
      return CVCumDataCandle
    }
    const CVCumDataCandle = calculateCVCumDataCandle(CVCum, labels)

    // =====================  end  =====================

    resolve({
      labels,
      plannedValues: plannedValues,
      earnedValues: earnedValues,
      actualCosts: actualCosts,
      cpi: cpi,
      EAC: EAC,
      SPICum,
      SPIPeriodic,
      SPIt: SPIt,
      CVCum: CVCum,
      CVPeriod: CVPeriod,
      VarianceEAC: VarianceEAC,
      EstimateToComplete: EstimateToComplete,
      ManagementReserveRemaining: ManagementReserveRemaining,
      dataDateLabel: dataDateLabel,
      ES: Estable,
      SVtCum: SVtCum,
      SVtPeriod: SVtPeriod,
      estimatedEndDate: estimatedEndDate,
      VarianceEdnDate: VarianceEndDate,
      delayTolerance,
      DelayToleranceRemaining: DelayToleranceRemaining,
      budgetReserve,
      reserve: data.reserve.amount,
      nbOfTimeUnitPassed: inputs.nbOfTimeUnitPassed,
      floatFormater: data.float_formatter,
      CardsData: CardsData,
      scheduleVarianceData: scheduleVarianceData,
      scheduleVarianceDataCandle: scheduleVarianceDataCandle,
      CVPeriodData: CVPeriodData,
      CVCumDataCandle: CVCumDataCandle,
      FTE,
    })
  })
}
export { getDiffrentBettwenDate }

