/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from 'dayjs'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useProject } from '../../modules/projects/core/ProjectContext'
import { getFormattedBudget, getFormattedDate } from '../../utils/funcs'

type Props = {
  className: string
  description: string
  icon: boolean
  stats?: number
  labelColor: string
  textColor: string
  dataDate: Date
}
const Overview = ({ className, description, icon, stats, labelColor, textColor, dataDate }: Props) => {
  const { originalVersion, project } = useProject()

  let startDate: any = new Date(originalVersion?.start_date.$date as any)
  let endDate: any = new Date(originalVersion?.end_date.$date as any)
  const timeDiff = Math.abs(endDate.getTime() - startDate.getTime())
  const monthsDiff = Math.ceil(timeDiff / (1000 * 3600 * 24 * 30.44)) // Approximate months

  return (
    <div className={`card card-flush w-100 ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title row w-100'>
          <div className='card-title ml-auto p-2'>
            <div>
              <label htmlFor='' style={{ color: '#979FB7', fontSize: '12px' }}>
                Project name
              </label>
              <div className='card-title pt-1'>
                <span
                  className='card-title  fw-bold text-dark me-2 lh-1 ls-n2 col-2 d-inline-block text-truncate w-100'
                  style={{ fontSize: '24px' }}
                >
                  {project?.name}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='card-body d-flex'>
        <div className='d-flex justify-content-start w-100' style={{ gap: '3%' }}>
          <div className='mr-auto p-2'>
            <img
              className='w-130px h-128px'
              src={toAbsoluteUrl('/media/svg/card-logos/projectImage.svg')}
              alt='Project'
            />
          </div>
          <div className='card-title  p-2 '>
            <span className='card-title  fw-bold text-dark me-2 lh-1 ls-n2 fs-5'>Description</span>
            <p className='lead pt-2' style={{ fontSize: '12px', color: '#979FB7' }}>
              {project?.description}
            </p>
          </div>
        </div>
      </div>
      <div className='card-body d-flex flex-wrap justify-content-between'>
        <div className='w-auto'>
            <div style={{ color: '#979FB7', fontSize: '12px' }}>Data Date</div>
            <div className='d-flex align-items-center'>
              <div className='fs-2 fw-bolder'>
                {((dataDate.getMonth() + 1) < 10 ? '0' + (dataDate.getMonth() + 1) : (dataDate.getMonth() + 1)) +
                  '/' + dataDate.getFullYear()}
              </div>
            </div>
          <div>
            <label htmlFor='' style={{ color: '#979FB7', fontSize: '12px' }}>
              Last updated
            </label>
            <div className='card-title pt-1'>
              <span
                className='card-title  fw-bold text-dark me-2 lh-1 ls-n2 col-2 d-inline-block text-truncate w-100'
                style={{ fontSize: '24px' }}
              >
                <span
                  style={{
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                  }}
                >
                  {getFormattedDate(project?.updated_at?.$date || 0)}
                </span>
              </span>
            </div>
          </div>
        </div>

        <div className='w-auto'>
          <div>
            <label htmlFor='' style={{ color: '#979FB7', fontSize: '12px' }}>
              Start Date
            </label>
            <div className='card-title pt-1'>
              <span
                className='card-title  fw-bold text-dark me-2 lh-1 ls-n2 col-2 d-inline-block text-truncate w-100'
                style={{ fontSize: '24px' }}
              >
                <span
                  style={{
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                  }}
                >
                  {startDate.toLocaleDateString('en-US', {
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </span>
              </span>
            </div>
          </div>
          <div>
            <label htmlFor='' style={{ color: '#979FB7', fontSize: '12px' }}>
              Planned duration
            </label>
            <div className='card-title pt-1'>
              <span
                className='card-title  fw-bold text-dark me-2 lh-1 ls-n2 col-2 d-inline-block text-truncate w-100'
                style={{ fontSize: '24px' }}
              >
                <span
                  style={{
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                  }}
                >
                  {`${monthsDiff}  months`}
                </span>
              </span>
            </div>
          </div>
        </div>

        <div className='w-auto'>
          <div>
            <label htmlFor='' style={{ color: '#979FB7', fontSize: '12px' }}>
              Finish date
            </label>
            <div className='card-title pt-1'>
              <span
                className='card-title  fw-bold text-dark me-2 lh-1 ls-n2 col-2 d-inline-block text-truncate w-100'
                style={{ fontSize: '24px' }}
              >
                <span
                  style={{
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                  }}
                >
                  {endDate.toLocaleDateString('en-US', {
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </span>
              </span>
            </div>
          </div>
          <div>
            <label htmlFor='' style={{ color: '#979FB7', fontSize: '12px' }}>
              Delay tolerance
            </label>
            <div className='card-title pt-1'>
              <span
                className='card-title  fw-bold text-dark me-2 lh-1 ls-n2'
                style={{ fontSize: '24px' }}
              >
                <span
                  style={{
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                  }}
                >
                  {originalVersion?.delay_tolerance?.count + ' '}
                </span>
                Months
              </span>
            </div>
          </div>
        </div>

        <div className='w-auto'>
          <div>
            <label
              className='text-nowrap
'
              htmlFor=''
              style={{ color: '#979FB7', fontSize: '12px' }}
            >
              Budget at completion
            </label>
            <div className='card-title pt-1'>
              <span
                className='card-title  fw-bold text-dark me-2 lh-1 ls-n2 col-2 d-inline-block text-truncate w-100'
                style={{ fontSize: '24px' }}
              >
                <span>
                  {getFormattedBudget({
                    currency: originalVersion?.budget_at_completion.currency?.toString(),
                    unit: originalVersion?.budget_at_completion.unit,
                    floatFormater: originalVersion?.float_formatter,
                    amount: originalVersion?.budget_at_completion.amount,
                  } as any)}
                </span>
              </span>
            </div>
          </div>
          <div>
            <label htmlFor='' style={{ color: '#979FB7', fontSize: '12px' }}>
              Reserve
            </label>
            <div className='card-title pt-1'>
              <span
                className='card-title  fw-bold text-dark me-2 lh-1 ls-n2'
                style={{ fontSize: '24px' }}
              >
                <span
                  style={{
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                  }}
                >
                {getFormattedBudget({
                  currency: originalVersion?.budget_at_completion.currency?.toString(),
                  unit: originalVersion?.budget_at_completion.unit,
                  floatFormater: originalVersion?.float_formatter,
                  amount: originalVersion?.reserve?.amount,
                } as any)}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export { Overview }
