import moment from 'moment'
import {sweetAlert} from './funcs'
import {calculateCumulativeFromPeriodic} from '../helpers/optimizers'
import {Calendar} from '../modules/calendars/_models'
import {getForecast} from './forecast'
import { getFomattedDate,
  getDiffBetweenTwoDates,
  getFormattedDateFromMomentObj ,
  getDiffrentBettwenDate, convertDateUnite } from './data-transformarion/date-utils'
import { removePercent,convertPercentagesToCosts, convertCostsToPercentages, getPercentage } from './data-transformarion/percentage-utils'

/**
 * Converts a value to a string with a fixed number of decimal places.
 * @param val The value to be converted.
 * @param nb The number of decimal places to fix (default is 2).
 * @returns A string representation of the value with the fixed number of decimal places.
 */

const getFixedVal = (val: any, nb: number = 2) => {
  if (typeof val !== 'number') {
    return val
  }
  return val.toString()
}

// ===================== function to calculate all the values for a project and returns it in an object =====================
export const getProjectDataFirstStep = (
  data: any,
  calendar?: Calendar,
  sector?: string,
  showAlert: boolean | null = true
) => {
  return new Promise(async (resolve, reject) => {
    type DurationUnitType = 'year' | 'month' | 'day'
    type DateFormatType = 'mm/dd/yyyy' | 'dd/mm/yyyy'

    // ===================== get the length of of longest array  =====================
    const getMaxArrayLength = (...arrays: any) => Math.max(...arrays.map((el: any) => el.length))

    // =====================  end  =====================

    // ===================== Get the data tables from the API =====================
    const cumulativePlannedValues: number[] = Object.values(data.custom_curve[0].values)
    const cumulativeEarnedValues: number[] = Object.values(data.custom_curve[1].values)
    const cumulativeActualCosts: number[] = Object.values(data.custom_curve[2].values)

    // =====================  end  =====================

    /**
 * Calculates the periodic value by subtracting the previous cumulative value from the current cumulative value.
 * This function is used typically to derive individual period values from a sequence of cumulative totals.
 * @param value The current cumulative value in the sequence.
 * @param index The index of the current cumulative value in the sequence.
 * @param cumulativeValues The array of all cumulative values.
 * @returns If it is the first element (index 0), returns the value itself; otherwise, returns the difference from the previous value.
 */
    const getPeriodicFromCumulativeValues = (value: any, index: any, cumulativeValues: any) => {
      if (index === 0) return value
      return value - cumulativeValues[index - 1]
    }

    // =====================  end  =====================

// Calculate the periodic planned values by converting cumulative planned values into individual periods.
// This uses the 'getPeriodicFromCumulativeValues' function to derive each period's value.

    const periodicPlannedValues = cumulativePlannedValues.map(
      (value, index, cumulativePlannedValues) =>
        getPeriodicFromCumulativeValues(value, index, cumulativePlannedValues)
    )
    // =====================  end  =====================

// Calculate the periodic earned values by converting cumulative earned values into individual period values.
// This transformation utilizes the 'getPeriodicFromCumulativeValues' function to compute the difference
// between each pair of consecutive cumulative values, providing the earned value for each period.

    const periodicEarnedValues = cumulativeEarnedValues.map(
      (value, index, cumulativeEarnedValues) =>
        getPeriodicFromCumulativeValues(value, index, cumulativeEarnedValues)
    )
    // =====================  end  =====================

  // Calculate the periodic actual costs by deriving values from cumulative actual costs.
// This transformation uses the 'getPeriodicFromCumulativeValues' function to calculate the difference between consecutive values.

    const periodicActualCosts = cumulativeActualCosts.map((value, index, cumulativeActualCosts) =>
      getPeriodicFromCumulativeValues(value, index, cumulativeActualCosts)
    )
    // =====================  end  =====================

   // Convert cumulative earned values (EV) from cost amounts to percentages of the total budget.
  // This operation uses the 'convertCostsToPercentages' function, which calculates each value as a percentage of the total budget at completion.

    const cumulativeEarnedValuesAsPercentages = convertCostsToPercentages(
      cumulativeEarnedValues, // Array of cumulative earned cost values.
      data.budget_at_completion.amount  // Total budget amount against which percentages are calculated.
    )
    // =====================  end  =====================

    // check if passed date units is < 2
    const projectLength = getDiffrentBettwenDate(
      data.data_date.$date,
      data.start_date.$date,
      data.period_count.type
    )
    if (projectLength < 2)
      if (showAlert === true) {
        //TODO:Refactor
        await sweetAlert({
          title: 'Forecast Warning',
          text: 'Need at least 3 periods to generate forecast!',
          icon: 'warning',
        })
      }

    // =====================  end  =====================
    /**
 * Extracts and formats various input parameters from a given data object.
 * The function handles date conversions, calculates differences between dates, and sets project-related metrics.
 * @param data The data object containing project-related information and timestamps.
 * @returns An object containing formatted and calculated project metrics.
 */

    const getInputs = (data: any) => {
      const inputs: any = {
        projectDurationUnit: null,
        inputDataDate: getFomattedDate(data.data_date.$date),
        enteredEndDate: null,
        donePercentage:
          cumulativeEarnedValuesAsPercentages[cumulativeEarnedValuesAsPercentages.length - 1],
        isCalculatingRemainingTodo: false,
        nbOfTimeUnitPassed: null,
        mu: null,
        sig: null,
      }

      switch (data.period_count.type) {
        case 'yearly':
        case 'monthly':
          inputs.projectDurationUnit = 'month'
          inputs.enteredEndDate = getFomattedDate(data.end_date.$date, 7)
          break
        default:
          inputs.projectDurationUnit = 'day'
          inputs.enteredEndDate = getFomattedDate(data.end_date.$date)
      }

      inputs.nbOfTimeUnitPassed = getDiffBetweenTwoDates(
        data.start_date.$date,
        data.data_date.$date,
        inputs.projectDurationUnit
      )

      return inputs
    }
    let inputs = getInputs(data)

    // =====================  calculate Earned Schedule  =====================

     /**  calculate earned schedule takes the periodic planned values and the periodic
    earned values and and the nbOfTimeUnitPassed returns the earned schedule it looks
    for each index inferior to nbOfTimeUnitPassed and if earned value is equal to planned
    value it returns the index else it still search pvi as pvi<EV<PVI+1 and returns the index of PVI+1
    * @param plannedValues Array of planned values over the project timeline.
    * @param earnedValues Array of earned values over the project timeline.
    * @param dataDate The current time unit (e.g., month) for which to calculate the earned schedule.
    * @returns The earned schedule index, indicating the point in time corresponding to the earned value.
    */
  

    // my own version of ES
    function calculateEarnedSchedule(plannedValues: any, earnedValues: any, dataDate: any) {
      const numMonths = plannedValues.length

      // Special case: dataDate is 0
      if (dataDate === 0) {
        return +(earnedValues[0] / plannedValues[0]).toString()
      }

      const foundIndex = plannedValues.findIndex((item: any) => item === earnedValues[dataDate])
      if (foundIndex !== -1) return foundIndex + 1

      // Special case: on schedule
      let index = 0
      if (earnedValues[dataDate]?.toFixed(2) === plannedValues[dataDate]?.toFixed(2))
        return +dataDate + 1
      else {
        //look for pvi and pvi+1
        for (let pviIndex = 0; pviIndex < numMonths; pviIndex++) {
          if (
            plannedValues[pviIndex] <= earnedValues[dataDate] &&
            earnedValues[dataDate] < plannedValues[pviIndex + 1]
          ) {
            index = pviIndex
            break
          }
        }
        if (plannedValues[index + 1] === plannedValues[index]) return index

        return (
          index +
          1 +
          (earnedValues[dataDate] - plannedValues[index]) /
            (plannedValues[index + 1] - plannedValues[index])
        )
      }
    }
    // =====================  end  =====================

    // =====================  generate a table of earned schedule for each month  =====================

/**
 * Generates a table of earned schedule (ES) values for each month up to the given number of time units passed.
 * @param cumulativePlannedValues Array of cumulative planned values.
 * @param cumulativeEarnedValues Array of cumulative earned values.
 * @param nbOfTimeUnitPassed The number of time units (e.g., months) that have passed.
 * @returns An array of earned schedule values for each month.
 */

    const EarnedScheduleTable = (
      cumulativePlannedValues: any,
      cumulativeEarnedValues: any,
      nbOfTimeUnitPassed: any
    ) => {
      let ES = []
      for (let i = 0; i < nbOfTimeUnitPassed; i++) {
        ES.push(calculateEarnedSchedule(cumulativePlannedValues, cumulativeEarnedValues, i))
      }
      return ES
    }
    const Estable = EarnedScheduleTable(
      cumulativePlannedValues,
      cumulativeEarnedValues,
      inputs.nbOfTimeUnitPassed + 1
    )

    const calculateSPIt = (Estable: any) => {
      let SPIt = []
      for (let i = 0; i < Estable.length; i++) {
        SPIt.push((Estable[i] < 0 ? 1 : Estable[i]) / (i + 1))
      }
      return SPIt
    }

    const SPIt = calculateSPIt(Estable)

    let EarnedValue = await getForecast(
      inputs,
      data,
      cumulativeEarnedValuesAsPercentages,
      SPIt,
      Estable,
      calendar,
      sector
    )
    const earnedValuesForcasts = {
      cumulative: convertPercentagesToCosts(
        removePercent(EarnedValue.tableData[1]),
        data.budget_at_completion.amount
      ),
      periodic: convertPercentagesToCosts(
        removePercent(EarnedValue.tableData[2]),
        data.budget_at_completion.amount
      ),
    }

    // =====================  end  =====================

    // ===================== get the future part of the forecast for EV  =====================
/**
 * Determines the forecasted future values for earned values based on their index.
 * @param el The current value in the earned values array.
 * @param index The index of the current value in the earned values array.
 * @param isPeriodic Boolean indicating whether the value is periodic (true) or cumulative (false).
 * @returns The forecasted values, or null if the index is less than the number of time units passed.
 */

    const getFutureForcastsValues = (el: number, index: number, isPeriodic: boolean = false) => {
      return index < inputs.nbOfTimeUnitPassed
        ? null
        : index === inputs.nbOfTimeUnitPassed
        ? isPeriodic
          ? periodicEarnedValues[index]
          : cumulativeEarnedValues[index]
        : +el.toString()
    }

    const earnedValuesFutureForcasts = {
      cumulative: earnedValuesForcasts.cumulative.map((el: number, index: number) =>
        getFutureForcastsValues(el, index, false)
      ),
      periodic: earnedValuesForcasts.periodic.map((el: number, index: number) =>
        getFutureForcastsValues(el, index, true)
      ),
    }

    // =====================  end  =====================

    // ===================== get the mixed values  for EV (original + Forecast)  =====================

/**
 * Retrieves the mixed values for earned value (EV), combining original and forecast values.
 * @param el The current value in the forecasted earned values array.
 * @param index The index of the current value.
 * @param values The array of original cumulative or periodic earned values.
 * @returns The mixed value for the given index, or an empty string if the value is undefined, null, or an empty string.
 */


    const getMixedValues = (el: number | string, index: number, values: any[]) => {
      if (index <= inputs.nbOfTimeUnitPassed) {
        const val = values[index]

            // Return an empty string if the value is undefined, null, or an empty string.

        return (typeof val === 'undefined' || val === null || (typeof val === 'string' && val === '')) ? '' : +val.toString()
      }
  // For future values, return the element cast to a number.

      return +(el as number).toString()
    }
  // Generate mixed earned values, combining original and forecast values.

    const earnedValuesMixed = {
      cumulative: earnedValuesForcasts.cumulative.map((el: number | string, index: number) =>
        getMixedValues(el, index, cumulativeEarnedValues)
      ),
      periodic: earnedValuesForcasts.periodic.map((el: number | string, index: number) =>
        getMixedValues(el, index, periodicEarnedValues)
      ),
    }
  // Recalculate periodic earned values from the mixed cumulative values.

    earnedValuesMixed.periodic = earnedValuesMixed.cumulative.map((element: any, index: number) =>
      Math.max(getPeriodicFromCumulativeValues(element, index, earnedValuesMixed.cumulative), 0)
    )
  // Update the forecasted periodic earned values to match the mixed periodic earned values.

    earnedValuesFutureForcasts.periodic = earnedValuesFutureForcasts.cumulative.map(
      (element: any, index: number) => (!element ? element : earnedValuesMixed.periodic[index])
    )

    // =====================  end  =====================

    // =====================  Datelabels object  =====================
    /**
     * Formats the data date into a string label based on the project's duration unit.
     * @param dataDate The date object representing the data date.
     * @param durationUnit The unit of time for formatting the date (e.g., 'day', 'month').
     * @returns A formatted date string based on the specified duration unit.
     */

    const dataDateLabel = getFormattedDateFromMomentObj(
      moment(data.data_date.$date), // Convert the data date from the provided data object to a Moment.js date object.
      inputs.projectDurationUnit  // Use the project's duration unit to format the date.
    )

    // =====================  end  =====================




    // =====================  calculate periodic and culative CPI  =====================
    const calculateCpi = (earnedValues: any, actualCosts: any, floatFormater: number) => {
      const CpiTable: number[] = []

      const minLength = Math.min(earnedValues.length, actualCosts.length)
      for (let i = 0; i < minLength; i++) {
        let cpi = earnedValues[i] / actualCosts[i]
        if (!earnedValues[i] && !actualCosts[i]) cpi = 1
        CpiTable.push(cpi)
      }
      return CpiTable
    }

    const cumulativeCpi = calculateCpi(
      cumulativeEarnedValues,
      cumulativeActualCosts,
      data.float_formatter
    )

    // =====================  end  =====================

    // ===================== SPI =======================

    const calculateSpiCum = () => {
      return cumulativeEarnedValues.map((item: any, index) => {
        if (!item) return 0
        return (
          item /
          ((cumulativePlannedValues as number[])[index] ||
            (cumulativePlannedValues.at(-1) as number))
        )
      })
    }


    const SPICum = calculateSpiCum()

    // =====================  end  =====================

    // =====================  calculate EAC  =====================

    const calculateBuecEac = (cpi: number[], bac: number) => {
      const actualBuec = data.forecast_settings?.bottom_up_ec?.find(
        (item: any) => item.label === dataDateLabel
      )
      if (!actualBuec) return undefined
      const eac = cumulativeActualCosts.at(-1) + actualBuec.value

      return Array(cpi.length).fill(eac)
    }

    const calculateBacEvEa = (cpi: number[], bac: number) => {
      return cpi.map(
        (item, index) => +cumulativeActualCosts[index] + (bac - +cumulativeEarnedValues[index])
      )
    }

    const calculateBacEvSpi = (cpi: number[], bac: number) => {
      return cpi.map(
        (item, index) =>
          +cumulativeActualCosts[index] +
          (bac - +cumulativeEarnedValues[index]) / (cpi[cpi.length - 1] * SPICum[SPICum.length - 1])
      )
    }

    const calculateForcedBac = (cpi: number[], bac: number) => {
      return cpi.map((item, index) => bac)
    }

    const calculateCustomEac = (cpi: number[], bac: number) => {
      return Array(cpi.length).fill(data.forecast_settings?.custom_eac)
    }
    const calculateEac = (cpi: number[], bac: number, floatFormater: number) => {
      if (data.forecast_settings?.eac_formula === 1)
        return calculateBuecEac(cpi, bac) || cpi.map((value) => (bac / value).toString())

      if (data.forecast_settings?.eac_formula === 2) return calculateBacEvEa(cpi, bac)

      if (data.forecast_settings?.eac_formula === 3) return calculateBacEvSpi(cpi, bac)

      if (data.forecast_settings?.eac_formula === 4) {
        if ((cumulativeActualCosts.at(-1) || 0) >= bac) {
          sweetAlert({
            icon: 'error',
            title: 'Error',
            text: 'Actual cost is bigger than budget at completion, please review the forecast method.\n Falling back to BAC/CPI',
          })
          return cpi.map((value) => (bac / value).toString())
        }
        return calculateForcedBac(cpi, bac)
      }

      if (data.forecast_settings?.eac_formula === 5 && data.forecast_settings?.custom_eac) {
        if ((cumulativeActualCosts.at(-1) || 0) >= data.forecast_settings?.custom_eac) {
          sweetAlert({
            icon: 'error',
            title: 'Error',
            text: 'Actual cost is bigger than custom EAC, please review the forecast method.\n Falling back to BAC/CPI',
          })
          return cpi.map((value) => (bac / value).toString())
        }
        return calculateCustomEac(cpi, bac) || cpi.map((value) => (bac / value).toString())
      }

      return cpi.map((value) => (bac / value).toString())
    }

    const EAC = calculateEac(cumulativeCpi, data.budget_at_completion.amount, data.float_formatter)

    // =====================  end  =====================


    // ===================== get the future part of the forecast for EAC =====================

    const tcpi =
      (data.budget_at_completion.amount -
        cumulativeEarnedValues[cumulativeEarnedValues.length - 1]) /
      (EAC.at(-1) - cumulativeActualCosts[cumulativeActualCosts.length - 1])

    const acutalCostFutureForecast: {cumulative: any; periodic: any} = {
      cumulative: undefined,
      periodic: earnedValuesFutureForcasts.periodic.map((el: any, index: number) =>
        el !== null && el !== undefined && index === inputs.nbOfTimeUnitPassed
          ? periodicActualCosts.at(inputs.nbOfTimeUnitPassed)
          : el !== null && el !== undefined && index !== inputs.nbOfTimeUnitPassed
          ? +el / tcpi
          : null
      ),
    }

    const actualCostMixed: {cumulative: any; periodic: any} = {
      cumulative: undefined,
      periodic: acutalCostFutureForecast.periodic.map((el: number | string, index: number) =>
        getMixedValues(+el, index, periodicActualCosts)
      ),
    }

    actualCostMixed.cumulative = calculateCumulativeFromPeriodic(actualCostMixed.periodic)

    acutalCostFutureForecast.cumulative = acutalCostFutureForecast.periodic.map(
      (item: any, index: number) =>
        item !== null && item !== undefined ? actualCostMixed.cumulative[index] : null
    )

    // =====================  end  =====================

    // =====================  get the date labels =====================

    const getLabels = (
      startTimestamp: number,
      length: number,
      durationUnit: DurationUnitType,
      _dateFormat: DateFormatType = 'dd/mm/yyyy'
    ) =>
      Array.from({length}).map((_el, idx) =>
        getFormattedDateFromMomentObj(
          moment(startTimestamp).add(idx, `${durationUnit}s`),
          durationUnit
        )
      )

    const labels = getLabels(
      data.start_date.$date,

      getMaxArrayLength(
        ...Object.values(earnedValuesMixed),
        ...Object.values(actualCostMixed),
        cumulativePlannedValues
      ),
      inputs.projectDurationUnit,
      data.date_format as DateFormatType
    )

    // =====================  end  =====================


    // =====================  end  =====================



    // =====================  Objects for the three main tables  =====================

    //!to clean this part
    const plannedValues = {
      original: {
        cumulative: cumulativePlannedValues.map((el) => getFixedVal(el)),
        periodic: periodicPlannedValues.map((el) => getFixedVal(el)),
      },
    }
    const earnedValues = {
      original: {
        cumulative: cumulativeEarnedValues.map((el) => getFixedVal(el)),
        periodic: periodicEarnedValues.map((el) => getFixedVal(el)),
      },
      // ! don't forget to uncomment the code below
      forecast: {
        cumulative: earnedValuesFutureForcasts.cumulative.map((el: any) => getFixedVal(el)),
        periodic: earnedValuesFutureForcasts.periodic.map((el: any) => getFixedVal(el)),
      },
      mixed: {
        cumulative: earnedValuesMixed.cumulative.map((el: any) => getFixedVal(el)), // ! NOT USED FOR NOW
        periodic: earnedValuesMixed.periodic.map((el: any) => getFixedVal(el)),
      },
      // forecast: {
      //   cumulative: cumulativeEarnedValues.map((el) => getFixedVal(el)),
      //   periodic: periodicEarnedValues.map((el) => getFixedVal(el)),
      // },
      // mixed: {
      //   cumulative: cumulativeEarnedValues.map((el) => getFixedVal(el)),
      //   periodic: periodicEarnedValues.map((el) => getFixedVal(el)),
      // },
    }

    const actualCosts = {
      original: {
        cumulative: cumulativeActualCosts.map((el) => getFixedVal(el)),
        periodic: periodicActualCosts.map((el) => getFixedVal(el)),
      },
      forecast: {
        cumulative: acutalCostFutureForecast.cumulative, // ! MISSING
        periodic: acutalCostFutureForecast.periodic, // ! MISSING
      },
      mixed: {
        cumulative: actualCostMixed.cumulative, // ! MISSING
        periodic: actualCostMixed.periodic, // ! MISSING
      },
    }

    // =====================  end  =====================



    // =====================  Calculate Cards Data  =====================

    const CardsData = {


      // =====================  Calculate Schedule Perfomance Card   =====================
      SchedulePerformance: {
        EED: {
          Value:
            projectLength >= 0 &&
            getFormattedDateFromMomentObj(
              EarnedValue.tableData[0].at(-1),
              data.period_count.type === 'daily' ? 'day' : 'month'
            ),
          Difference:
            projectLength >= 0 &&
            getDiffrentBettwenDate(
              moment(data.start_date.$date)
                .add(
                  EarnedValue.tableData[1].length - 1,
                  data.period_count.type === 'monthly' ? 'month' : 'days'
                )
                .toDate()
                .getTime(),
              data.end_date.$date,
              data.period_count.type
            ) +
              ' ' +
              convertDateUnite(data.period_count.type),
          sign:
            projectLength >= 0 &&
            getDiffrentBettwenDate(
              moment(data.start_date.$date)
                .add(
                  EarnedValue.tableData[1].length - 1,
                  data.period_count.type === 'monthly' ? 'month' : 'days'
                )
                .toDate()
                .getTime(),
              data.end_date.$date,
              data.period_count.type
            ),
        },
        ERD:
          projectLength >= 0 &&
          (EarnedValue.nbOfRemainingMonths < 0 ? 0 : EarnedValue.nbOfRemainingMonths) +
            ' ' +
            convertDateUnite(data.period_count.type),
      },

      // =====================  end  =====================
    }

    resolve({
      labels,
      plannedValues: plannedValues,
      earnedValues: earnedValues,
      actualCosts: actualCosts,
      CardsData: CardsData,
    })
  })
}


