import React, { useState, useEffect } from 'react';
import '../wpStyles/TableStyle.scss';
import * as Yup from 'yup'
import moment from "moment";
import { DatePicker } from 'antd'
import '../../projects/components/data/Data.scss'
import { DateFormatType, sweetAlert } from '../../../utils/funcs';
import '../../projects/components/settings/Date.scss'
import { getLabels, normalizeDateUnit } from '../../../helpers/DateFormatter';
import { getCustomCurve } from '../../projects/components/overview/Project';
import { Calendar } from '../../calendars/_models';
import { useProject } from '../../projects/core/ProjectContext';
import { useDispatch } from 'react-redux';
import { useCalendars } from '../../calendars/_queries';
import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import { greaterThanOrEqual } from '../../projects/core/ProjectHelpers';
import { updateLabel, updateLoaded, updateShow, updateTotal } from '../../wbs/treeLoader';
import { combineVersionsV2 } from '../../wbs/components/TreeView/_helpers';
import { createVersion } from '../../projects/core/_requests';
import { setVersionData } from '../../wbs/treeVersionData';
import { patchWbs } from '../../wbs/_requests';
import { addWbsToStore, updateIsWpChanged, updateWpChangedId } from '../../wbs/treedata';
import dayjs, { Dayjs } from 'dayjs';
import clsx from 'clsx';
import CustomSelect from '../../../components/CustomSelect';

interface CustomCurve {
  name: string;
  color: string;
  values: number[];
}

interface DelayTolerance {
  count: number;
  type: string;
}

interface Reserve {
  amount: number;
  unit: string;
}

interface BudgetAtCompletion {
  currency: string;
  unit: string;
  amount: number;
}

interface PeriodCount {
  type: string;
  count: number;
}

interface DataType {
  project?: any
  _id?: any
  name?: string
  output?: any
  data_date: { $date: number };
  end_date: { $date: number };
  start_date: { $date: number };
  custom_curve: CustomCurve[];
  delay_tolerance: DelayTolerance;
  associated_calendar: Calendar | null;
  reserve: Reserve;
  budget_at_completion: BudgetAtCompletion;
  period_count: PeriodCount;
  date_format: string;
  forecast_settings?: any
}

interface Props {
  wpId: string;
  wpName: string;
  afterCreate: (data: any) => void;
}


const WpCreateData: React.FC<Props> = ({ wpId, wpName, afterCreate }) => {
  const { project } = useProject()
  const { project: projectData, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync } = useProject()
  const float_formatter = project?.float_formatter ? project?.float_formatter : project?.default_version.float_formatter ? project?.default_version.float_formatter : 2;
  const date_format = project?.date_format ? project?.date_format : project?.default_version.date_format ? project?.default_version.date_format : 'MM/DD/YYYY';
  const date_unit = project?.date_unit ?
    project?.date_unit : project?.default_version.delay_tolerance?.type ? project?.default_version.delay_tolerance?.type : 'monthly';
  const currency = project?.currency ? project?.currency : project?.default_version.budget_at_completion.currency ? project?.default_version.budget_at_completion.currency : 'EUR';
  const currency_unit = project?.currency_unit ? project?.currency_unit : project?.default_version.budget_at_completion.unit ? project?.default_version.budget_at_completion.unit : 'thousand';
  const data: DataType = {
    data_date: { $date: new Date().getTime() },
    end_date: { $date: new Date().getTime() },
    start_date: { $date: new Date().getTime() },
    custom_curve: [
      { name: 'cumulativePlannedValue', color: 'black', values: [] },
      { name: 'cumulativeEarnedValue', color: 'white', values: [] },
      { name: 'cumulativeActualCost', color: 'red', values: [] },
    ],
    delay_tolerance: {
      count: 0,
      type: date_unit
    },
    associated_calendar: null,
    reserve: { amount: 0, unit: date_unit },
    budget_at_completion: {
      currency: currency,
      unit: currency_unit,
      amount: 0,
    },
    period_count: {
      type: date_unit,
      count: 1,
    },
    date_format: date_format,
    project: { oid$: projectData?._id.$oid }
  };
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch();
  const { data: calendars, isLoading } = useCalendars({ page: 1, per_page: 0 })
  //diff between end date and start date and based on data value for start date and end date 
  const [dateDiff, setDateDiff] = useState<number>(getDiffrentBettwenDate(data.end_date.$date, data.start_date.$date, data.period_count.type) + 1)


  //diff between data date and start date
  const [dataDateDiff, setDataDateDiff] = useState<number>(getDiffrentBettwenDate(data.data_date.$date, data.start_date.$date, data.period_count.type) + 1)


  const formik = useFormik({
    initialValues: {
      data_date: new Date(data.data_date.$date),
      end_date: new Date(data.end_date.$date),
      start_date: new Date(data.start_date.$date),
      plannedValue: data.custom_curve[0].values || [],
      earnedValue: data.custom_curve[1].values || [],
      actualCost: data.custom_curve[2].values || [],
      delay_tolerance: data?.delay_tolerance?.count || 0,
      associated_calendar: data?.associated_calendar,
      reserve: data?.reserve?.amount || 0,
    },
    validationSchema: Yup.object().shape({
      data_date: Yup.date(),
      start_date: Yup.date().required('Start date is required'),
      end_date: Yup.date()
        .required('End date is required')
        .test('end_date', 'End date must be greater than start date', (value): boolean => {
          if (value)
            return value > formik.values.start_date
          return false
        }),
      plannedValue: Yup.array()
        .test('number', 'Planned values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('cum', 'Planned values must be cumulative', (value, context) => {
          return value?.every(greaterThanOrEqual) || false
        })
        .test('non-zero', 'Planned values must be greater than 0', (value, context) => {
          return value?.every((el) => el > 0) || false
        }),
      actualCost: Yup.array()
        .test('number', 'Actual cost values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('evTest', 'Actual cost values must be cumulative', (value, context) => {
          return value?.every(greaterThanOrEqual) || false
        }),
      earnedValue: Yup.array()
        .test('number', 'Earned values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('evTest', 'Earned values must be cumulative', (value, context) => {
          return value?.every(greaterThanOrEqual) || false
        }),
      delay_tolerance: Yup.number()
        .required('Delay Tolerance is required')
        .min(0, "Delay Tolerance can't be negative"),
      reserve: Yup.number().required('Budget is required').min(0, "Budget can't be negative"),
    }),
    enableReinitialize: false,
    onSubmit: async (values) => {

    },
  })




  const changeReserve = (changeType: string) => {
    if (changeType === '+') {
      formik.setFieldValue('reserve', formik.values.reserve + 1000)
    } else {
      formik.setFieldValue(
        'reserve',
        formik.values.reserve - 1000 < 0 ? 0 : formik.values.reserve - 1000
      )
    }
  }

  const changeTolerance = (changeType: string) => {
    if (changeType === '+') {
      formik.setFieldValue('delay_tolerance', formik.values.delay_tolerance + 1)
    } else {
      formik.setFieldValue(
        'delay_tolerance',
        formik.values.delay_tolerance - 1 < 0 ? 0 : formik.values.delay_tolerance - 1
      )
    }
  }
  //this is for saving it to the current version
  const handleSave = async () => {
    if (!formik.isValid || !formik.dirty) return
    setLoading(true)

    let newVersion = { ...data, _id: { $oid: '' } }
    newVersion.name = wpName
    newVersion.data_date = { $date: formik.values.data_date.getTime() }
    newVersion.end_date = { $date: formik.values.end_date.getTime() }
    newVersion.start_date = { $date: formik.values.start_date.getTime() }
    newVersion.custom_curve = [
      {
        color: 'black',
        name: 'cumulativePlannedValue',
        values: formik.values.plannedValue.map((el: number) => +el).slice(0, dateDiff),
      },
      {
        color: 'white',
        name: 'cumulativeEarnedValue',
        values: formik.values.earnedValue.map((el: number) => +el).slice(0, dataDateDiff),
      },
      {
        color: 'red',
        name: 'cumulativeActualCost',
        values: formik.values.actualCost.map((el: number) => +el).slice(0, dataDateDiff),
      },
    ]
    newVersion.budget_at_completion = {
      ...newVersion.budget_at_completion,
      amount: getCustomCurve('cumulativePlannedValue', newVersion)[getCustomCurve('cumulativePlannedValue', newVersion).length - 1]
    };

    newVersion.delay_tolerance = {
      count: +formik.values.delay_tolerance,
      type: data?.delay_tolerance?.type || 'daily',
    }

    newVersion.reserve = {
      amount: +formik.values.reserve,
      unit: currency_unit,
    }

    newVersion.associated_calendar = formik.values.associated_calendar

    try {

      dispatch(updateShow(true));
      setLoading(true)
      dispatch(updateLabel('Calculating WP Data'));
      dispatch(updateTotal(1));
      dispatch(updateLoaded(0));

      const calculatedVersionData = await combineVersionsV2(
        newVersion?.name ? newVersion?.name : '',
        [newVersion],
        true,
        setWbsDataDateFirstStepAsync,
        setWbsDataDateSecondStepAsync,
        formik.values.data_date,
      );
      console.log("calculatedVersionData ==>")
      console.log(calculatedVersionData)
      newVersion = calculatedVersionData ? { ...calculatedVersionData } : { ...newVersion, output: {} }
      const { _id, project, ...payload } = newVersion
      let version
      if (projectData?._id.$oid) {
        version = await createVersion(
          {
            project_version: {
              ...payload,
              name: wpName,
              data_date: formik.values.data_date,
              start_date: formik.values.start_date,
              end_date: formik.values.end_date,
              custom_curve: newVersion.custom_curve,
              associated_calendar: formik.values.associated_calendar?._id.$oid || null,
              forecast_settings: {
                ...payload.forecast_settings,
                custom_end_date: payload.forecast_settings?.custom_end_date
                  ? new Date(payload.forecast_settings.custom_end_date.$date)
                  : null,
              },
              float_formatter: float_formatter,
            },
            timestamp_period: 1
          },
          projectData?._id.$oid
        )
      }
      dispatch(setVersionData({ id: version?._id.$oid, data: version }))
      patchWbs(wpId, { data: version?._id.$oid }).then((res) => {
        dispatch(addWbsToStore(res));
      }
      )

      console.log("version ==>")
      console.log(version)
      afterCreate(version)
      sweetAlert({
        text: 'Your changes have been saved successfully',
        title: 'Success',
        icon: 'success',
      }).then(() => {
        dispatch(updateWpChangedId(wpId))
        dispatch(updateIsWpChanged(true));
      })
    } catch (error: any) {
      setLoading(false)
      sweetAlert({
        title: 'Error',
        text: error?.message || 'Something went wrong',
        icon: 'error',
      })
      console.error(error)
    } finally {
      setLoading(false)
      dispatch(updateShow(false))
    }

  }


  useEffect(() => {
    const newDateDiff = getDiffrentBettwenDate(
      formik.values.end_date.getTime(),
      formik.values.start_date.getTime(),
      data?.period_count.type
    ) + 1;

    const newDataDateDiff = getDiffrentBettwenDate(
      formik.values.data_date.getTime(),
      formik.values.start_date.getTime(),
      data?.period_count.type
    ) + 1;

    // Update dateDiff and dataDateDiff
    setDateDiff(newDateDiff);
    setDataDateDiff(newDataDateDiff);

    // Ensure plannedValue is an array and adjust its length
    const updatedPlannedValue = [...(formik.values.plannedValue || [])];
    const lastPlannedValue = updatedPlannedValue[updatedPlannedValue.length - 1] || 0;
    if (updatedPlannedValue.length < newDateDiff) {
      updatedPlannedValue.push(...new Array(newDateDiff - updatedPlannedValue.length).fill(lastPlannedValue));
    } else if (updatedPlannedValue.length > newDateDiff) {
      updatedPlannedValue.length = newDateDiff; // Truncate the array
    }
    formik.setFieldValue('plannedValue', updatedPlannedValue);

    // Ensure earnedValue is an array and adjust its length
    const updatedEarnedValue = [...(formik.values.earnedValue || [])]; // Always ensure array
    const lastEarnedValue = updatedEarnedValue[updatedEarnedValue.length - 1] || 0;
    if (updatedEarnedValue.length < newDataDateDiff) {
      updatedEarnedValue.push(...new Array(newDataDateDiff - updatedEarnedValue.length).fill(lastEarnedValue));
    } else if (updatedEarnedValue.length > newDataDateDiff) {
      updatedEarnedValue.length = newDataDateDiff;
    }
    formik.setFieldValue('earnedValue', updatedEarnedValue);

    // Ensure actualCost is an array and adjust its length
    const updatedActualCost = [...(formik.values.actualCost || [])]; // Always ensure array
    const lastActualCost = updatedActualCost[updatedActualCost.length - 1] || 0;
    if (updatedActualCost.length < newDataDateDiff) {
      updatedActualCost.push(...new Array(newDataDateDiff - updatedActualCost.length).fill(lastActualCost));
    } else if (updatedActualCost.length > newDataDateDiff) {
      updatedActualCost.length = newDataDateDiff;
    }
    formik.setFieldValue('actualCost', updatedActualCost);

  }, [formik.values.start_date, formik.values.end_date, formik.values.data_date]);


  const normalizedDateUnit = normalizeDateUnit(data?.period_count?.type)
  const labels = getLabels(
    formik.values.start_date.getTime(),
    dateDiff + 1,
    normalizedDateUnit,
    data?.date_format as DateFormatType
  )



  return (
    <div>
      <div style={{ overflowX: 'auto', maxWidth: '900px' }}>
        <div className="container">
          <>
            <div className='d-flex flex-wrap flex-stack pb-7'>
              <div>
                <h4>Fill Project Data for {wpName}</h4>
              </div>
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Data Date</span>
              </label>
              <DatePicker
                cellRender={(current: Dayjs, info: any) => {
                  const startDate = dayjs(formik.values.start_date);
                  const dataDate = dayjs(formik.values.data_date);

                  if (current.isBefore(startDate)) {
                    return <div className="not-started">{date_unit === 'monthly' ? 'NS' : 'Not Started'}</div>;
                  }

                  return (
                    <div
                      className={clsx(
                        current.isBefore(dataDate) || current.isSame(dataDate) ? 'highlighted' : ''
                      )}
                    >
                      {info.originNode}
                    </div>
                  );
                }}
                picker={date_unit === 'daily' ? 'date' : 'month'}
                value={dayjs(formik.values.data_date)}
                popupClassName={'dark-mode-datepicker'}
                className='form-control form-control-solid my-datepicker fw-bold'
                onChange={async (newDate) => {
                  formik.setFieldTouched('start_date', true);
                  if (newDate) {
                    formik.setFieldValue('data_date', newDate.toDate());
                  }
                }}
                placeholder={''}
              />
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Start Date</span>
              </label>
              <DatePicker
                picker={date_unit === 'daily' ? 'date' : 'month'}
                value={dayjs(formik.values.start_date)}
                popupClassName={'dark-mode-datepicker'}
                className='form-control form-control-solid my-datepicker fw-bold'
                onChange={(newDate) => {
                  formik.setFieldTouched('start_date', true)
                  if (newDate) {
                    const selectedStartDate = newDate.toDate();
                    formik.setFieldValue('start_date', selectedStartDate)

                    // Check if the selected end date is smaller than the current start date
                    if (newDate.isAfter(dayjs(formik.values.end_date))) {
                      // If so, update the start date to match the end date
                      formik.setFieldValue('end_date', selectedStartDate);
                    }
                  }
                }}
                placeholder={''}
              />
            </div>
            {formik.touched.start_date && formik?.errors?.start_date && (
              <div className='row mb-10'>
                <h4 className='text-danger'>
                  <>{formik.errors.start_date}</>
                </h4>
              </div>
            )}

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>End Date</span>
              </label>
              <DatePicker
                picker={date_unit === 'daily' ? 'date' : 'month'}
                value={dayjs(formik.values.end_date)}
                popupClassName={'dark-mode-datepicker'}
                className='form-control form-control-solid my-datepicker fw-bold'
                onChange={(newDate) => {
                  formik.setFieldTouched('end_date', true)
                  if (newDate) {
                    const selectedEndDate = newDate.toDate();

                    // Update the end date
                    formik.setFieldValue('end_date', selectedEndDate);

                    // Check if the selected end date is smaller than the current start date
                    if (newDate.isBefore(dayjs(formik.values.start_date))) {
                      // If so, update the start date to match the end date
                      formik.setFieldValue('start_date', selectedEndDate);
                    }
                  }
                }}
                placeholder={''}
              />
            </div>
            {formik.touched.end_date && formik?.errors?.end_date && (
              <div className='row mb-10'>
                <h4 className='text-danger'>
                  <>{formik.errors.end_date}</>
                </h4>
              </div>
            )}


            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Associated Calendar </span>
              </label>

              <CustomSelect
                name='calendar'
                value={
                  formik.values.associated_calendar
                    ? {
                      value: formik.values.associated_calendar,
                      label: formik.values.associated_calendar.name,
                    }
                    : {
                      value: {},
                      label: isLoading ? 'Loading...' : 'No Calendar',
                      isOptionDisabled: isLoading,
                    }
                }
                isSearchable
                maxHeight='200px'
                onChange={(item: { label: string; value: Calendar }) => {
                  formik.setFieldValue('associated_calendar', item.value)
                }}
                options={[
                  { label: 'No Calendar', value: undefined },
                  ...(calendars?.items?.map((item) => ({ label: item.name, value: item })) || []),
                ]}
              />
            </div>


            <div className="row">
              <div className="col-md-6">
                <div className='row mb-8'>
                  <div className='col-xl-3'>
                    <div className='fs-6 fw-semibold mt-2 mb-3'>Manage Delay Tolerance</div>
                  </div>
                  <div className='col-xl-9'>
                    <div className='position-relative w-md-300px'>
                      <button
                        type='button'
                        className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0'
                        data-kt-dialer-control='decrease'
                        onClick={() => changeTolerance('-')}
                      >
                        <span className='svg-icon svg-icon-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              opacity='0.3'
                              x='2'
                              y='2'
                              width='20'
                              height='20'
                              rx='5'
                              fill='currentColor'
                            />
                            <rect x='6.0104' y='10.9247' width='12' height='2' rx='1' fill='currentColor' />
                          </svg>
                        </span>
                      </button>
                      <input
                        style={{
                          WebkitAppearance: 'none',
                          margin: 0

                        }}
                        type='number'
                        className='form-control form-control-solid border-0 ps-12'
                        data-kt-dialer-control='input'
                        placeholder='Amount'
                        name='delay_tolerance'
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => {
                          if (isNaN(e.target.value)) return
                          formik.handleChange(e)
                        }}
                        value={formik.values.delay_tolerance}
                      />
                      <button
                        type='button'
                        className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0'
                        data-kt-dialer-control='increase'
                        onClick={() => changeTolerance('+')}
                      >
                        <span className='svg-icon svg-icon-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              opacity='0.3'
                              x='2'
                              y='2'
                              width='20'
                              height='20'
                              rx='5'
                              fill='currentColor'
                            />
                            <rect
                              x='10.8891'
                              y='17.8033'
                              width='12'
                              height='2'
                              rx='1'
                              transform='rotate(-90 10.8891 17.8033)'
                              fill='currentColor'
                            />
                            <rect x='6.01041' y='10.9247' width='12' height='2' rx='1' fill='currentColor' />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='row mb-5'>
                  {formik.errors.delay_tolerance && (
                    <h4 className='text-danger'>{formik.errors.delay_tolerance.toString()}</h4>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className='row mb-8'>
                  <div className='col-xl-3'>
                    <div className='fs-6 fw-semibold mt-2 mb-3'>Manage Reserve</div>
                  </div>
                  <div className='col-xl-9'>
                    <div
                      className='position-relative w-md-300px'
                      data-kt-dialer='true'
                      data-kt-dialer-min='1000'
                      data-kt-dialer-max='50000'
                      data-kt-dialer-step='1000'
                      data-kt-dialer-prefix='$'
                      data-kt-dialer-decimals='2'
                    >
                      <button
                        type='button'
                        className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0'
                        data-kt-dialer-control='decrease'
                        onClick={() => changeReserve('-')}
                      >
                        <span className='svg-icon svg-icon-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              opacity='0.3'
                              x='2'
                              y='2'
                              width='20'
                              height='20'
                              rx='5'
                              fill='currentColor'
                            />
                            <rect
                              x='6.0104'
                              y='10.9247'
                              width='12'
                              height='2'
                              rx='1'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                      </button>
                      <input
                        type='text'
                        className='form-control form-control-solid border-0 ps-12'
                        data-kt-dialer-control='input'
                        placeholder='Amount'
                        name='reserve'
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => {
                          if (isNaN(e.target.value)) return
                          formik.handleChange(e)
                        }}
                        value={formik.values.reserve}
                      />
                      <button
                        type='button'
                        className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0'
                        data-kt-dialer-control='increase'
                        onClick={() => changeReserve('+')}
                      >
                        <span className='svg-icon svg-icon-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              opacity='0.3'
                              x='2'
                              y='2'
                              width='20'
                              height='20'
                              rx='5'
                              fill='currentColor'
                            />
                            <rect
                              x='10.8891'
                              y='17.8033'
                              width='12'
                              height='2'
                              rx='1'
                              transform='rotate(-90 10.8891 17.8033)'
                              fill='currentColor'
                            />
                            <rect x='6.01041' y='10.9247' width='12' height='2' rx='1' fill='currentColor' />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <FormikProvider value={formik}>
              {0 < dateDiff && (
                <>
                  <h3 className=''>Planned Data</h3>
                  <div className='table-responsive'>
                    <div className='d-flex flex-wrap flex-stack align-items-end my-2'></div>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bolder text-muted'>
                          <th className='min-w-150px'></th>
                          {Array(dateDiff)
                            .fill(0)
                            .map((item: any, index: number) => (
                              <th key={index} className='min-w-120px'>
                                {labels[index]}
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <a className='text-primary fw-bolder text-hover-primary fs-6'>
                              Planned Value
                            </a>
                          </td>
                          <FieldArray
                            name='earnedValue'
                            render={(arrayHelpers) => (
                              <>
                                {formik.values.plannedValue &&
                                  Array(dateDiff)
                                    .fill(0)
                                    .map((value: any, index: number) => (
                                      <td className='position-relative' key={index}>
                                        <Field
                                          placeholder='0'
                                          name={`plannedValue.${index}`}
                                          className="text-dark fw-bold d-block fs-7 text-danger 'border-bottom border-primary"
                                        />
                                      </td>
                                    ))}
                              </>
                            )}
                          />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {formik.errors.plannedValue && formik.touched.plannedValue && (
                    <h4 className='text-danger'>{formik.errors.plannedValue.toString()}</h4>
                  )}
                </>
              )}

              {0 < dataDateDiff && (
                <>
                  <h3 className=''>Actual Project Data</h3>
                  <div className='d-flex flex-wrap flex-stack align-items-end my-2'></div>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bolder text-muted'>
                          <th className='min-w-150px'></th>
                          {Array(dataDateDiff)
                            .fill(0)
                            .map((item: any, index: number) => (
                              <th key={index} className='min-w-120px'>
                                {labels[index]}
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <a className='text-primary fw-bolder text-hover-primary fs-6'>
                              Earned Value
                            </a>
                          </td>
                          <FieldArray
                            name='earnedValue'
                            render={(arrayHelpers) => (
                              <>
                                {formik.values.earnedValue &&
                                  Array(dataDateDiff)
                                    .fill(0)
                                    .map((value: any, index: number) => (
                                      <td className='position-relative' key={index}>
                                        <Field
                                          placeholder='0'
                                          name={`earnedValue.${index}`}
                                          className="text-dark fw-bold d-block fs-7 text-danger 'border-bottom border-primary"
                                        />
                                      </td>
                                    ))}
                              </>
                            )}
                          />
                        </tr>
                        <tr>
                          <td>
                            <a className='text-primary fw-bolder text-hover-primary fs-6'>
                              Actual Cost
                            </a>
                          </td>
                          <FieldArray
                            name='actualCost'
                            render={(arrayHelpers) => (
                              <>
                                {formik.values.earnedValue &&
                                  Array(dataDateDiff)
                                    .fill(0)
                                    .map((value: any, index: number) => (
                                      <td className='position-relative' key={index}>
                                        <Field
                                          placeholder='0'
                                          name={`actualCost.${index}`}
                                          className="text-dark fw-bold d-block fs-7 text-danger 'border-bottom border-primary"
                                        />
                                      </td>
                                    ))}
                              </>
                            )}
                          />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {formik.errors.earnedValue && formik.touched.earnedValue && (
                    <h4 className='text-danger'>{formik.errors.earnedValue.toString()}</h4>
                  )}
                  {formik.errors.actualCost && formik.touched.actualCost && (
                    <h4 className='text-danger'>{formik.errors.actualCost.toString()}</h4>
                  )}
                </>
              )}
            </FormikProvider>


            <div className='d-flex justify-content-end' >

              <button
                onClick={() => handleSave()}
                disabled={
                  !formik.dirty ||
                  !formik.isValid
                }
                className='btn btn-primary'
              >
                <span className='indicator-label'>Save Changes</span>
              </button>

            </div>
          </>
        </div>
      </div>
    </div>
  );
};

const getDiffrentBettwenDate = (endDate: number, startDate: number, unit: string) => {
  const date1 = moment(startDate);
  const date2 = moment(endDate);
  let diff;

  switch (unit) {
    case 'monthly':
      diff = date2.diff(date1, 'months');
      break;
    case 'daily':
      diff = Math.round(date2.diff(date1, 'days', true));
      break;
    case 'yearly':
      diff = date2.diff(date1, 'years');
      break;
    default:
      return 0;
  }

  return diff < 0 ? -1 : diff;
};



export default WpCreateData;

