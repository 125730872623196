import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { getFormattedBudget, IBudget, sweetAlert } from '../../../../utils/funcs'
import { ProjectObject } from '../../core/_models'
import { useProjectVersionById } from '../../core/_queries'
import { patchVersion } from '../../core/_requests'
import { getPeriodicFromCumulative } from './prepareDataTable'
import * as Yup from 'yup'
import { getProjectData } from '../../../../utils/project-data'
import { greaterThanOrEqual } from '../../core/ProjectHelpers'
import { useProject } from '../../core/ProjectContext'
import ImportData from './ImportData'
import { KTSVG } from '../../../../../_metronic/helpers/components/KTSVG'
import { useDispatch } from 'react-redux'
import { updateLabel, updateLoaded, updateShow, updateTotal } from '../../../wbs/treeLoader'
import { combineVersionsV2 } from '../../../wbs/components/TreeView/_helpers'
import { setVersionData } from '../../../wbs/treeVersionData'
import { updateIsWpChanged, updateWpChangedId } from '../../../wbs/treedata'

interface Props {
  data: any
  labels: string[]
  isEditable?: boolean;
  wpId?: string
}
export default function PlannedValueTable({ data, labels, isEditable = true, wpId }: Props) {
  const {
    setWbsDataDateFirstStepAsync,
    setWbsDataDateSecondStepAsync,
    setOriginalVersion,
    setVersion,
    setLoading: setProjectLoading,
    project,
    originalVersion
  } = useProject()
  const { refetch } = useProjectVersionById(data._id.$oid, (version) => { }, false)
  const [pvEdit, setPvEdit] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [oldplayedValues, setsOldplayedValues] = useState([])
  const [ShowModalimport, setShowModalimport] = useState(false)
  const dispatch = useDispatch();


  const formik = useFormik({
    initialValues: {
      pvCumulative:
        data?.output?.plannedValues?.original?.cumulative ||
        (data as ProjectObject)?.custom_curve
          ?.find((item) => item.name === 'cumulativePlannedValue')
          ?.values?.filter((value) => !isNaN(value) && value !== '') ||
        [],
      oldPvCumulative:
        data?.output?.plannedValues?.original?.cumulative ||
        (data as ProjectObject)?.custom_curve
          ?.find((item) => item.name === 'oldCumulativePlannedValue')
          ?.values?.filter((value) => !isNaN(value) && value !== '') ||
        [],
    },
    validationSchema: Yup.object().shape({
      pvCumulative: Yup.array()
        .test('number', 'Planned Values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('pvTest', 'Planned values must be cumulative', (value, context) => {
          return value?.every(greaterThanOrEqual) || false
        }),
    }),
    onSubmit: async (values) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          if (!formik.dirty) return setPvEdit(false)
          const newCurve = JSON.parse(JSON.stringify(data?.custom_curve));
          newCurve[0].values = values.pvCumulative?.map((value: number) => +value);
          const newBudgetAtCompletion = { ...data.budget_at_completion };

          if (
            values.pvCumulative[values.pvCumulative.length - 1] !==
            data?.budget_at_completion?.amount
          )
            newBudgetAtCompletion.amount = +values.pvCumulative[values.pvCumulative.length - 1];

          let calculatedVersionData: any = { output: {} };
          if (data?.data_date.$date > data?.start_date.$date) {
            dispatch(updateShow(true));
            setLoading(true)
            setProjectLoading(true)
            dispatch(updateLabel('Calculating Data'));
            dispatch(updateTotal(1));
            dispatch(updateLoaded(0));

            calculatedVersionData = await combineVersionsV2(
              data?.name ? data?.name : 'WBS',
              [{ ...data, custom_curve: newCurve }],
              true,
              setWbsDataDateFirstStepAsync,
              setWbsDataDateSecondStepAsync,
            );
            console.log("calculatedVersionData ==>")
            console.log(calculatedVersionData)
            setProjectLoading(false)
            setLoading(false)
            dispatch(updateShow(false));
          }
          delete newBudgetAtCompletion._cls;
          patchVersion(
            {
              budget_at_completion: newBudgetAtCompletion,
              custom_curve: newCurve,
              output: calculatedVersionData ? calculatedVersionData.output : data.output,
            },
            data._id?.$oid
          )
            .then((res) => {
              dispatch(setVersionData({ id: res._id.$oid, data: res }));
              if (originalVersion?._id.$oid === res._id.$oid) {
                setOriginalVersion(res)
                setVersion(res)
              }
              sweetAlert({
                title: 'Success',
                text: 'Planned Value has been updated successfully',
                icon: 'success',
              }).then(() => {
                if (wpId) {
                  dispatch(updateWpChangedId(wpId))
                  dispatch(updateIsWpChanged(true));
                }
              })
              setPvEdit(false)
              formik.resetForm()
              refetch()
            })
            .catch((err) => {
              sweetAlert({
                title: 'Error',
                text: err.message || 'Planned Value has not been updated',
                icon: 'error',
              })
            })
        } catch (error: any) {
          sweetAlert({
            title: 'Error',
            text: error.message || 'Planned Value has not been updated',
            icon: 'error',
          })
        } finally {
          setLoading(false)
        }
      }, 0)
    },
    enableReinitialize: true,
  })
  useEffect(() => {
    console.log(formik?.values)
  }, [formik.values])

  useEffect(() => {
    // Find the index where values stop being the same
    const index = formik?.values?.pvCumulative.findIndex(
      (value: any, index: any) => value !== formik?.values?.oldPvCumulative[index]
    )

    // Slice the array from that index to the end
    const newValues = formik?.values?.oldPvCumulative.slice(index)

    setsOldplayedValues(newValues)
  }, [formik?.values])

  useEffect(() => {
    console.log({ oldplayedValues })
  }, [oldplayedValues])

  const handleCancel = () => {
    formik.resetForm();
    setPvEdit(false);
  };


  return (
    <>
      <FormikProvider value={formik}>
        {isEditable && (
          <button
            type='button'
            className='btn btn-sm btn-bg-light btn-active-color-primary my-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            onClick={() => {
              setShowModalimport(true)
            }}
          >
            <span className='svg-icon svg-icon-2 svg-icon-primary'>
              <KTSVG
                path='/media/icons/duotune/arrows/arr045.svg'
                className='svg-icon-3 ms-2 me-0'
              />
            </span>
            Import data
          </button>
        )}
        <div className='d-flex flex-wrap flex-stack align-items-end'>
          <h3 className=''>Planned Value</h3>
          <div>
            {isEditable && (
              <button
                onClick={() => {
                  if (pvEdit) {
                    formik.submitForm()
                  } else setPvEdit((prev) => !prev)
                }}
                type='button'
                className=' btn btn-sm btn-primary'
                disabled={(pvEdit && (!formik.isValid || !formik.dirty)) || loading}
              >
                {loading ? (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    <span className='spinner-border spinner-border-sm align-middle'></span>
                  </span>
                ) : pvEdit ? (
                  'Save'
                ) : (
                  'Edit'
                )}
              </button>
            )}
            {pvEdit && (
              <button
                onClick={handleCancel}
                type='button'
                className='btn btn-sm btn-secondary ms-2'
              >
                Cancel
              </button>
            )}
          </div>
        </div>
        <div className='card card-xxl-stretch mb-5 mb-xl-10'>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-150px'></th>
                    {formik.values.pvCumulative.map((item: any, index: number) => (
                      <th
                        key={index}
                        className='min-w-120px'
                        style={{ maxWidth: '13ch', minWidth: '13ch' }}
                      >
                        {labels[index]}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <a className='text-primary fw-bolder text-hover-primary fs-6'>Cumulative</a>
                    </td>
                    <FieldArray
                      name='pvCumulative'
                      render={(arrayHelpers) => (
                        <>
                          {formik.values.pvCumulative &&
                            formik.values.pvCumulative.map((value: any, index: number) => (
                              <td className='position-relative' key={index}>
                                {pvEdit && isEditable ? (
                                  <Field
                                    name={`pvCumulative.${index}`}
                                    className="text-dark fw-bold d-block fs-7 text-danger 'border-bottom border-primary"
                                  />
                                ) : (
                                  <span className='fw-bold d-block fs-7 text-dark'>
                                    {getFormattedBudget({
                                      ...data?.budget_at_completion,
                                      amount: value,
                                      floatFormater: data.float_formatter,
                                    } as IBudget)}
                                  </span>
                                )}
                              </td>
                            ))}
                        </>
                      )}
                    />
                  </tr>
                  <tr>
                    <td>
                      <a className='text-primary fw-bolder text-hover-primary fs-6'>Periodic</a>
                    </td>
                    {data?.output?.plannedValues?.original?.periodic
                      ? data?.output?.plannedValues?.original.periodic.map(
                        (item: any, index: number) => (
                          <td key={index}>
                            <input
                              className='text-dark fw-bold d-block fs-7'
                              value={getFormattedBudget({
                                ...data?.budget_at_completion,
                                amount: item,
                                floatFormater: data.float_formatter,
                              } as IBudget)}
                              disabled
                            />
                          </td>
                        )
                      )
                      : getPeriodicFromCumulative(
                        formik.values.pvCumulative.filter(
                          (value: any) => !isNaN(value) && value !== ''
                        )
                      ).map((item: any, index: number) => (
                        <td key={index}>
                          <input
                            className='text-dark fw-bold d-block fs-7'
                            value={getFormattedBudget({
                              ...data?.budget_at_completion,
                              amount: item,
                              floatFormater: data.float_formatter,
                            } as IBudget)}
                            disabled
                          />
                        </td>
                      ))}
                  </tr>
                </tbody>
              </table>
            </div>
            {formik.errors.pvCumulative && (
              <h4 className='text-danger'>{formik.errors.pvCumulative.toString()}</h4>
            )}
          </div>
        </div>
      </FormikProvider>

      <ImportData
        show={ShowModalimport}
        handleClose={() => {
          setShowModalimport(false)
        }}
        data={data}
      />
    </>
  )
}
