import {Modal} from 'react-bootstrap'
import GlobalDashboardSettingsForm from './GlobalDashboardSettingsForm'

type Props = {
  show: boolean
  onHide: () => void
}

export default function GlobalDashboardSettingsModal({onHide, show}: Props) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Dashboard Settings</Modal.Title>
      </Modal.Header>
      <GlobalDashboardSettingsForm onHide={onHide} />
    </Modal>
  )
}
