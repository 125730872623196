import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Wbs } from './_models';
import { getWbs } from './_requests';
import { FolderNode } from './components/hierarchy/HierarchyView';
import { Dispatch } from 'redux';
import { DataNode } from 'antd/es/tree';

interface TreeData {
  value: FolderNode | null;
  knownWbs: Record<string, Wbs>;
  knownWbsData: Record<string, any>;
  collectedLeafData: any[];
  wbsCalculatedData: any[];
  wpsCalculatedData: any[];
  isTreeLoaded: boolean;
  isWpChanged: boolean;
  wpChangedId: string;
}

const initialTreeData: TreeData = {
  value: null,
  knownWbs: {},
  knownWbsData: {},
  collectedLeafData: [],
  wbsCalculatedData: [],
  wpsCalculatedData: [],
  isTreeLoaded: false,
  isWpChanged: false,
  wpChangedId: '',
};

const buildFolderTree = async (folder: Wbs, dispatch: Dispatch, knownWbs: Record<string, Wbs>): Promise<FolderNode> => {
  const children = await Promise.all(
    folder.subwbs.map(async (item) => {
      let response: Wbs;
      if (knownWbs[item.$oid]) {
        response = knownWbs[item.$oid];
      } else {
        response = await getWbs(item.$oid);
        dispatch(addWbsToStore(response));
      }
      return await buildFolderTree(response, dispatch, knownWbs);
    })
  );
  return {
    label: folder.name,
    children,
    id: folder._id.$oid,
  };
};


const wbstreedata = createSlice({
  name: 'wbstreedata',
  initialState: initialTreeData,
  reducers: {
    updatedata: (state: TreeData, action: PayloadAction<FolderNode>) => {
      state.value = action.payload;
    },
    updateCollectedLeafData: (state: TreeData, action: PayloadAction<any[]>) => {
      state.collectedLeafData = action.payload;
    },
    addWbsToStore: (state: TreeData, action: PayloadAction<Wbs>) => {
      state.knownWbs[action.payload._id.$oid] = action.payload;
    },
    addWbsDataToStore: (state: TreeData, action: PayloadAction<any>) => {
      state.knownWbsData[action.payload._id.$oid] = action.payload;
    },
    clearTreeData: (state: TreeData) => {
      state.value = null;
    },
    updateIsTreeLoaded: (state: TreeData, action: PayloadAction<boolean>) => {
      state.isTreeLoaded = action.payload;
    },
    updateIsWpChanged: (state: TreeData, action: PayloadAction<boolean>) => {
      state.isWpChanged = action.payload;
    },
    updateWpChangedId: (state: TreeData, action: PayloadAction<string>) => {
      state.wpChangedId = action.payload;
    }, 
    updateWbsCalculatedData: (state: TreeData, action: PayloadAction<any[]>) => {
      state.wbsCalculatedData = action.payload;
    },
    updateWpsCalculatedData: (state: TreeData, action: PayloadAction<any[]>) => {
      const newWpsData = action.payload;

      newWpsData.forEach((newData) => {
        const existingIndex = state.wpsCalculatedData.findIndex(
          (data) => data._id.$oid === newData._id.$oid
        );

        if (existingIndex !== -1) {
          // If it exists, update the existing entry
          state.wpsCalculatedData[existingIndex] = newData;
        } else {
          // If it doesn't exist, add the new entry
          state.wpsCalculatedData.push(newData);
        }
      });
    },
  },
});

export const { 
  updatedata, 
  addWbsToStore, 
  addWbsDataToStore, 
  clearTreeData, 
  updateCollectedLeafData, 
  updateIsTreeLoaded, 
  updateIsWpChanged,
  updateWpChangedId,
  updateWbsCalculatedData,
  updateWpsCalculatedData,
} = wbstreedata.actions;

export default {
  updatedata: wbstreedata.reducer,
};
