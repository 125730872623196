import moment from "moment";
import {format} from 'date-fns'



export type DateFormatType = 'mm/dd/yyyy' | 'dd/mm/yyyy'
export type DurationUnitType = 'year' | 'month' | 'day'



/**
 * Formats a given timestamp into an ISO string and slices it to a specified length.
 * This function is typically used to convert timestamps into more readable date formats.
 * @param timestamp The timestamp to be formatted.
 * @param endISOIndex The index at which to end the slice of the ISO string (default is 10, which typically includes the date).
 * @returns A string representation of the date portion of the ISO string derived from the timestamp.
 */
export const getFomattedDate = (timestamp: number, endISOIndex: number = 10) =>
  new Date(timestamp).toISOString().slice(0, endISOIndex)

// =====================  end  =====================

/**
 * Formats a date timestamp into a string representation.
 * @param dateTimestamp The timestamp of the date.
 * @param dateFormat The format of the date string (default is 'dd/mm/yyyy').
 * @returns A formatted string representing the date.
 */
export const getFormattedDate = (
    dateTimestamp: number,
    dateFormat: DateFormatType = 'dd/mm/yyyy'
  ): string => {
    // Format the date according to the specified date format
    return format(
      new Date(dateTimestamp), // Create a Date object from the timestamp
      dateFormat === 'mm/dd/yyyy' ? 'MM/dd/yyyy' : 'dd/MM/yyyy' // Adjust format based on the input format
    );
  }

 
  // =====================  end  =====================
  
  
  /**
   * Converts a date unit string into a human-readable format.
   * @param delayUnit The date unit string to be converted.
   * @returns A human-readable string representing the date unit.
   */
  export const convertDateUnite = (delayUnit: string): string => {
    switch (delayUnit) {
      case 'monthly':
        return 'Months';
      case 'daily':
        return 'Days';
      case 'yearly':
        return 'Years';
      default:
        return ''; // Return an empty string for unrecognized units
    }
  }
  
    /**
 * Formats a date object according to the specified duration unit.
 * @param m The date object to be formatted.
 * @param durationUnit Specifies the format granularity ('day' for day-level granularity or default to month-level granularity).
 * @returns A string representing the formatted date.
 */
  export  const getFormattedDateFromMomentObj = (m: any, durationUnit: DurationUnitType) =>
      m.format(durationUnit === 'day' ? 'D MMM YYYY' : 'MMM YYYY')

    // =====================  end  =====================
  
  

  export const getDiffrentBettwenDate = (endDate: number, startDate: number, unit: string) => {
    const date1 = moment(startDate)
    const date2 = moment(endDate)
    switch (unit) {
      case 'monthly':
        return date2.diff(date1, 'months')
      case 'daily':
        return Math.round(date2.diff(date1, 'days', true))
      case 'yearly':
        return date2.diff(date1, 'years')
      default:
        return 0
    }
  }

  export const getMonthDifference =(startTimestamp:any, endTimestamp:any)=> {
    const start = new Date(startTimestamp);
    const end = new Date(endTimestamp);
  
    let months = (end.getFullYear() - start.getFullYear()) * 12;
    months -= start.getMonth();
    months += end.getMonth();
  
  
    return months;
  }
  
  /**
   * Checks if two dates are in the same month and year.
   * @param dataDate The first date or timestamp to compare.
   * @param latestRebaseLineDate The second date or timestamp to compare.
   * @returns A boolean indicating whether the two dates are in the same month and year.
   */
  //compare datadate with the last rebase line (to desable forcast )
  export const sameMonthAndYear = (dataDate : number | Date,latestRebaseLineDate : number | Date) : boolean=>{
    const d1 = new Date(dataDate);
    const d2 = new Date(latestRebaseLineDate);
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth();
  }


  /**
 * Calculates the rounded difference between two timestamps in a specified duration unit.
 * This function compute the difference and rounds the result to the nearest whole number.
 * @param startTimestamp The timestamp representing the start date.
 * @param endTimestamp The timestamp representing the end date.
 * @param durationUnit The unit of time in which to measure the difference (e.g., 'second', 'minute', 'hour', 'day').
 * @returns The rounded difference between the two dates in the specified unit of time.
 */
  export const getDiffBetweenTwoDates = (
    startTimestamp: number,
    endTimestamp: number,
    durationUnit: DurationUnitType
  ) =>
    Math.round(
      moment(new Date(endTimestamp)).diff(new Date(startTimestamp), `${durationUnit}s`, true)
    )

  // =====================  end  =====================
  
