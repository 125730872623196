import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils';

interface DashboardData {
  global_dashboard_colors: string[];
  global_bars_thickness: number[];
}

const initialDashboardData: DashboardData = {
  global_dashboard_colors: [
    getCSSVariableValue('--kt-primary'),
    getCSSVariableValue('--kt-success'),
    getCSSVariableValue('--kt-warning'),
    getCSSVariableValue('--kt-info')
  ],

  global_bars_thickness: [10, 10, 10, 10]
};




const globalDashboardData = createSlice({
  name: 'globalDashboardData',
  initialState: initialDashboardData,
  reducers: {
    updateColors: (state: DashboardData, action: PayloadAction<string[]>) => {
      state.global_dashboard_colors = action.payload;
    },
    updateBarsthickness: (state: DashboardData, action: PayloadAction<number[]>) => {
      state.global_bars_thickness = action.payload;
    },



  },
});

export const { 
  updateColors, 
  updateBarsthickness, 
} = globalDashboardData.actions;

export default {
  globalDashboardData: globalDashboardData.reducer,
};
