import { FormikProps } from 'formik';
import { ForecastFormula, Sectors, enabledFormulasFree, enabledFormulasPro } from '../../core/_models';
import { forecastFormulaOptions } from './_utils';
import clsx from 'clsx';
import { DatePicker } from 'antd';
import { useProject } from '../../core/ProjectContext';
import dayjs from 'dayjs';
import InputWithLabel from '../../../../components/inputWithLabel/InputWithLabel';
import { useEffect, useState } from 'react';
import { getRecommendations } from '../../core/_requests';
import { useAuth } from '../../../auth';
import PaywallModal from '../../../../components/paywall';
import { useNavigate } from 'react-router-dom';

type Props = { formik: FormikProps<any>; sector?: string };

export default function ForecastFormulaSelection({ formik, sector }: Props) {
  const [recommendedForecast, setRecommendedForecast] = useState<ForecastFormula | undefined>();
  const [showPopup, setShowPopup] = useState(false);
  const [disableForecast, setDisableForecast] = useState(false);
  const { currentPlanLevel } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    currentPlanLevel === 1 ? setDisableForecast(true) : setDisableForecast(false);
  }, [currentPlanLevel]);

  useEffect(() => {
    if (formik.values.forecastFormula === ForecastFormula['Auto'] && !!sector) {
      getRecommendations().then((res) => {
        setRecommendedForecast(res.find((item) => item.sector === sector)?.forecast_formula);
      });
    } else setRecommendedForecast(undefined);
  }, [formik.values.forecastFormula, sector]);

  const handleClick = (e: any) => {
    if (disableForecast) {
      e.preventDefault();
      setShowPopup(true);
    }
  };

  const { displayVersion } = useProject();
  const rebaseLineMonth = () => {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    if (displayVersion?.rebase_line) {
      return displayVersion?.rebase_line?.find((item) => {
        const date = new Date(item?.id);
        const year2 = date.getFullYear();
        const month2 = date.getMonth();
        return year === year2 && month === month2;
      });
    }
  };

  const getEnabledFormulas = () => {
    if (currentPlanLevel === 1) return enabledFormulasFree;
    if (currentPlanLevel === 2) return enabledFormulasPro;
    return Object.values(ForecastFormula);
  };

  const isFormulaDisabled = (formula: ForecastFormula) => {
    const enabledFormulas = getEnabledFormulas();
    return !enabledFormulas.includes(formula);
  };

  return (
    <div className='mb-8'>
      <div className='row'>
        <div className='fs-6 fw-semibold mt-2 mb-3'>EV Forecast based on</div>
      </div>
      {forecastFormulaOptions.map((option) => (
        <div key={option.label} className='row'>
          <label
            onClick={(e) => {
              if (
                option.value === ForecastFormula['EV Fitting correlated to similar projects'] &&
                (!sector || !Sectors.find((item) => item.label === sector))
              )
                return;

              if (isFormulaDisabled(option.value)) {
                handleClick(e);
              } else {
                formik.setFieldValue('forecastFormula', option.value);
              }
            }}
            className='d-flex align-items-center col-12 col-lg-3'
          >
            <input
              type='radio'
              disabled={
                (rebaseLineMonth() && true) ||
                (option.value === 7 && (!sector || !Sectors.find((item) => item.label === sector))) ||
                isFormulaDisabled(option.value)
              }
              readOnly
              checked={formik.values.forecastFormula === option.value}
              className='form-check-input'
            />
            <span
              className={clsx(
                'btn btn-sm btn-color-muted btn-active btn-active-primary text-start',
                { 'text-muted': disableForecast && isFormulaDisabled(option.value) }
              )}
            >
              {option.value === ForecastFormula['Auto']
                ? 'Automatic selection for better accuracy'
                : option.label}
            </span>
            {option.value === recommendedForecast && (
              <i className='bi bi-check-lg fs-2 text-success'></i>
            )}
          </label>
          {!!sector &&
            option.value === ForecastFormula['EV Fitting correlated to similar projects'] &&
            !Sectors.find((item) => item.label === sector) && (
              <div className='col-9 d-flex align-items-center text-muted'>
                We do not recognize your sector. We hopefully will add more sectors in the future.
              </div>
            )}

          {option.value === ForecastFormula['Max FTE'] && (
            <>
              <div className='col-9 row'>
                <div className='col-xl-2'>
                  <div
                    className={clsx('fs-6 fw-semibold mt-2 mb-3', {
                      'text-muted': formik.values.forecastFormula !== ForecastFormula['Max FTE'],
                    })}
                  >
                    Max FTE
                  </div>
                </div>
                <div className='col-xl-10 fv-row mb-3'>
                  <div className='input-group'>
                    <span className='input-group-text'>FTE</span>
                    <input
                      type='text'
                      placeholder=''
                      disabled={
                        formik.values.forecastFormula !== ForecastFormula['Max FTE'] || disableForecast
                      }
                      className={clsx('form-control', {
                        'is-invalid': !!formik.errors.max_capacity && formik.touched.max_capacity,
                      })}
                      {...formik.getFieldProps('max_capacity')}
                      onClick={handleClick}
                    />
                  </div>
                  {formik.errors.max_capacity && formik.touched.max_capacity && (
                    <span className='text-danger'>
                      <>{formik.errors.max_capacity}</>
                    </span>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      ))}
      {formik.errors.forecastFormula && (
        <span className='text-danger'>
          <>{formik.errors.forecastFormula}</>
        </span>
      )}
      <PaywallModal
        show={showPopup}
        onHide={() => setShowPopup(false)}
        title="Upgrade your plan"
        bodyText="To use Forecasting, you need to upgrade your subscription"
      />
    </div>

  );
}
