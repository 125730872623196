import React, { useState, useEffect } from 'react';
import '../wpStyles/TableStyle.scss';
import { useLocation } from 'react-router-dom'
import '../../projects/components/data/Data.scss'
import { DateFormatType } from '../../../utils/funcs';
import '../../projects/components/settings/Date.scss'
import { getLabels, getMaxArrayLength, normalizeDateUnit } from '../../../helpers/DateFormatter';
import EarnedValueTable from '../../projects/components/data/EarnedValueTable';
import PlannedValueTable from '../../projects/components/data/PlannedValueTable';
import ActualCostTable from '../../projects/components/data/ActualCostTable';
import DateInputs from '../../projects/components/data/DateInputs';
import CompleteData from '../../projects/components/data/CompleteData';
import { getCustomCurve } from '../../projects/components/overview/Project';
import WorstCastBaseline from '../../projects/components/data/WorstCastBaseline';
import { getVersionById } from '../../projects/core/_requests';
import { getDiffrentBettwenDate } from '../../../utils/project-data';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { setVersionData } from '../../wbs/treeVersionData';

interface WpDataProps {
  wpData: any;
  wpId?: string;
  handleUpdateWpDataDate?: any
}



const WpData: React.FC<WpDataProps> = ({ wpData, handleUpdateWpDataDate, wpId }) => {


  const [data, setData] = useState<any>(wpData)

  return (
    <div>
      <div style={{ overflowX: 'auto', maxWidth: '900px' }}>
        <div className="container">
          <DateInputs wpId={wpId} data={data} setProjectversion={setData} handleUpdateWpDataDate={handleUpdateWpDataDate} />
        </div>
      </div>
    </div>
  );
};

export default WpData;

