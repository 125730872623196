import { Wbs } from '../../_models';

type Props = {
  folder: Wbs;
};


export default function ChartTreeFolderItem({ folder }: Props) {

  return (
    <div  className='d-inline-flex justify-content-start align-items-center'>
    <span className='fw-bold me-2'>{folder.name}</span>
    </div>
  );
}